import { createTheme as createMuiTheme, unstable_createMuiStrictModeTheme } from '@mui/material/styles';

import { brightWhite, hpRedDarkened, hpRedLightened, trueBlack, hpRedPrimary } from './colors';
import { sharedOverrides } from './shared';

// THEME COLORS: LIGHT
const featherLightGray = '#f2f2f2';
const mediumLightGray = '#d9d9d9';
const lightAppleGreen = '#749116';
const peach = '#DF6C2C';
const darkPeach = '#B25623';
const blueSea = '#1975A6';
const raspberry = '#cd0000';
const darkRaspberry = '#A40000';
const lightRaspberry = '#e85555';
const lightPeach = '#ffa06d';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    checkMark: string;
    transparent: string;
  }
  interface PaletteOptions {
    checkMark?: string;
    transparent?: string;
  }
}
// declare module '@mui/material/styles/createMuiTheme' {
//   interface Theme {
//     transparent: string;
//     primaryAppbarBackgroundColor: string;
//     errorNotice: string;
//   }
//   interface DeprecatedThemeOptions {
//     transparent?: string;
//     primaryAppbarBackgroundColor?: string;
//     errorNotice?: string;
//   }
// }

const createTheme = process.env.NODE_ENV === 'production' ? createMuiTheme : unstable_createMuiStrictModeTheme;

export const HP_LIGHT_THEME = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: hpRedPrimary,
      light: hpRedLightened,
      dark: hpRedDarkened,
      contrastText: brightWhite,
    },
    secondary: {
      main: featherLightGray,
      light: brightWhite,
      dark: mediumLightGray,
      contrastText: trueBlack,
    },
    error: {
      main: raspberry,
      light: lightRaspberry,
      dark: darkRaspberry,
    },
    warning: {
      main: peach,
      light: lightPeach,
      dark: darkPeach,
    },
    info: {
      main: blueSea,
    },
    success: {
      main: lightAppleGreen,
    },
    background: {
      default: featherLightGray,
      paper: brightWhite,
    },
  },

  // App wide
  // typography: HENSEL_PHELPS_TYPOGRAPHY,
  // primaryAppbarBackgroundColor: '#202020',

  // SVGs
  //errorNotice: errorNotice,

  // Overrides
  components: Object.assign({
    sharedOverrides,

    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          color: trueBlack,
        },
        root: {
          backgroundColor: mediumLightGray,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: hpRedPrimary + '!important',
          },
          '&.Mui-completed': {
            color: lightAppleGreen + '!important',
          },
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        icon: {
          '&:hover': {
            color: trueBlack,
          },
          padding: 4,
          marginLeft: 8,
        },
        iconActive: {
          color: hpRedPrimary,
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          color: trueBlack,
        },
        contentWrapper: {
          justifyContent: 'center',
        },
        data: { fontWeight: 'bold' },
        fixedHeader: { padding: '8px 16px' },
      },
    },
    MUITableCell: {
      styleOverrides: {
        root: {
          color: trueBlack,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          color: trueBlack,
        },
      },
    },
    MUIDataTableHeadRow: {
      styleOverrides: {
        root: { borderTop: '2px solid #a1a1a1', borderBottom: '2px solid #a1a1a1' },
      },
    },
    MUIDataTableFilterList: {
      styleOverrides: {
        chip: { backgroundColor: '#fff', border: '1px solid #e4e4e4', marginBottom: 16 },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: { padding: '4px 8px' },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': { cursor: 'pointer' },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: trueBlack,
        },
      },
    },
  }),
});
