import { Project } from 'types/generated/graphql';
import { graphqlDateToJsDate } from 'utils/general';

import { gql } from '@apollo/client';

// TODO: look into whether it would be feasible to have a custom date scalar
// defined in our graphql, and tell Apollo how to serialize/deserialize it.
export const typeDefs = gql`
  extend type Project {
    startJsDate: Date @requires(fields: "startDate")
    endJsDate: Date @requires(fields: "endDate")
    districtsDisplay: String @requires(fields: "districts")
    verticalMarketsDisplay: String @requires(fields: "verticalMarkets")
  }
`;

// Resolvers
export const resolvers = {
  Project: {
    startJsDate: (project: Project) => {
      return graphqlDateToJsDate(project.startDate);
    },
    endJsDate: (project: Project) => {
      return graphqlDateToJsDate(project.endDate);
    },
    districtsDisplay: (project: Project) => {
      return project.districts.sort().join(', ');
    },
    verticalMarketsDisplay: (project: Project) => {
      return project.verticalMarkets.sort().join(', ');
    },
  },
};

export const PROJECT_LIST_RESULT = gql`
  query ProjectListResult(
    $skip: Int
    $first: Int
    $order: ProjectListOrderInput
    $search: String
    $projectIds: [ID!]
    $districts: [String!]
    $statuses: [String!]
  ) {
    projectListResult(
      skip: $skip
      first: $first
      order: $order
      search: $search
      projectIds: $projectIds
      districts: $districts
      statuses: $statuses
    ) {
      items {
        id
        name
        number
        description
        districts
        districtsDisplay @client
        status
        verticalMarkets
        verticalMarketsDisplay @client
        contractType
        startDate
        endDate
        startJsDate @client
        endJsDate @client
        roles {
          id
          startDate
          endDate
          startJsDate @client
          endJsDate @client
          roleName
          roleAbbreviation @client
          roleFullDisplayName @client
          tdy
          focusArea
          notes
          notesFlag
          project {
            id
            name
          }
          nextAssignment {
            id
            startDate
            endDate
            roleName
            project {
              id
              name
            }
          }
          person {
            id
            name {
              lastCommaFirst
              firstName
              lastName
              middleName
            }
            roles {
              id
              startDate
              endDate
              roleName
              project {
                id
                name
              }
            }
            prDistrict
            prJobTitle
            prJobTitleAbbreviation @client
            prJobTitleFullDisplayName @client
            personTenure {
              id
              tenureYears
            }
            personTenureYear
            possiblePromotionTitle
            possiblePromotionDate
            notesFlag
            sharedWithDistricts
            anticipatedSeparationDate
            isCraft
            earliestDateAvailable
          }
        }
      }
    }
  }
`;

export const PROJECT_LIST_RESULT_LIGHT = gql`
  query ProjectListResultLight(
    $skip: Int
    $first: Int
    $order: ProjectListOrderInput
    $search: String
    $projectIds: [ID!]
    $districts: [String!]
    $statuses: [String!]
  ) {
    projectListResult(
      skip: $skip
      first: $first
      order: $order
      search: $search
      projectIds: $projectIds
      districts: $districts
      statuses: $statuses
    ) {
      items {
        id
        name
        number
      }
    }
  }
`;

export const GET_SINGLE_PROJECT = gql`
  query GetSingleProject($id: ID!) {
    project(id: $id) {
      id
      name
      shortName
      number
      description
      districts
      status
      verticalMarkets
      contractType
      procurementDate
      awardedDate
      startDate
      endDate
      location {
        cityAndStateDisplay
        city
        stateOrProvince
        streetAndNumber
        zipOrPostalCode
        streetDetail
      }
      roles {
        id
        startDate
        endDate
        roleName
        roleAbbreviation @client
        roleFullDisplayName @client
        notes
        focusArea
        person {
          id
          name {
            lastCommaFirst
            firstName
            lastName
            middleName
          }
          employeeNumber
          employeePrNumber
          isCraft
        }
      }
    }
  }
`;

export const UPSERT_PROJECT = gql`
  mutation UpsertProject($input: ProjectUpsertInput!) {
    upsertProject(input: $input) {
      id
      name
      shortName
      number
      location {
        streetAndNumber
        city
        stateOrProvince
        zipOrPostalCode
        streetDetail
      }
      districts
      verticalMarkets
      status
      contractType
      procurementDate
      awardedDate
      startDate
      endDate
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`;

export const PROJECT_SUBSCRIPTION = gql`
  subscription UpdatedProject($projectId: ID!) {
    project(id: $projectId) {
      id
      name
      number
      description
      districts
      status
      verticalMarkets
      contractType
      procurementDate
      awardedDate
      startDate
      endDate
      roles {
        id
        roleName
      }
      location {
        city
        stateOrProvince
        streetAndNumber
        zipOrPostalCode
      }
    }
  }
`;

export const DELETED_PROJECT_SUBSCRIPTION = gql`
  subscription DeletedProject {
    deletedProject {
      id
    }
  }
`;
