import { brightWhite, hpRedPrimary, hpYellowPrimary, trueBlack } from 'constants/themes/colors';
import { useTheme } from 'providers/AppThemeProvider/AppThemeProvider';
import { FC } from 'react';
import { useCSVDownloader } from 'react-papaparse';

type ProjectDetailsCsvExportProps = {
  fileName: string;
  bom: boolean;
  data: any;
  label: string;
  className?: string;
};

const ProjectDetailsCsvExportButton: FC<ProjectDetailsCsvExportProps> = ({
  fileName,
  bom = true,
  data,
  label,
  className,
  ...rest
}) => {
  const { CSVDownloader, Type } = useCSVDownloader();
  const { isDarkMode } = useTheme();

  return (
    <CSVDownloader
      style={{
        minWidth: '80px',
        width: '140px',
        height: '33px',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        borderRadius: 16,
        backgroundColor: isDarkMode ? hpYellowPrimary : hpRedPrimary,
        color: isDarkMode ? trueBlack : brightWhite,
        border: 'none',
        cursor: 'pointer',
        textTransform: 'uppercase',
      }}
      type={Type.Button}
      filename={fileName}
      bom={bom}
      data={data}
      {...rest}
    >
      {label}
    </CSVDownloader>
  );
};

export default ProjectDetailsCsvExportButton;
