import hpLogoBlack from 'assets/images/logos/hpLogoBlack.png';
import hpLogoLight from 'assets/images/logos/hpLogoLight.png';
import { DISTRICTS } from 'constants/districts';
import { PRIMARY_APP_BAR_HEIGHT_PX } from 'constants/themes/dimensions';
import useCurrentUser from 'hooks/useCurrentUser';
import useRoles from 'hooks/useRoles';
import { Fragment, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { selectedDistrictsState, selectedEmployeeTypeState } from 'recoil/selectedDistrict/atom';

import ClearIcon from '@mui/icons-material/Clear';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  AppBar,
  Avatar,
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import ThemeToggle from 'components/shared/ThemeToggle';
import { useGlobalSearchParams } from 'components/SearchParamsProvider/SearchParamsProvider';
import { APP_ENVIRONMENTS, ENV } from '../../../constants';
import SideNavigationMenu from '../SideNavigationMenu';
import TestByRoleDialog from '../TestByRoleDialog';
import { backgroundBlocks, hpYellowPrimary } from 'constants/themes/colors';
import { useTheme } from 'providers/AppThemeProvider/AppThemeProvider';

const appBarStyle: SxProps<Theme> = (theme: Theme) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('md')]: { paddingTop: theme.spacing(0) },
  height: `${PRIMARY_APP_BAR_HEIGHT_PX}px`,
});

const regionSelectStyle: SxProps<Theme> = {
  width: 300,
  borderRadius: '8px',
};

//TODO: Uncomment when craft feature is ready to be pushed to prod
// const employeeTypeSelectStyle: SxProps<Theme> = (theme: Theme) => ({
//   backgroundColor: theme.palette.secondary.main,
//   marginRight: theme.spacing(2),
//   width: 150,
// });

const chipStyle: SxProps<Theme> = {
  borderRadius: '16px !important',
};

const hpLogoImgStyle: SxProps<Theme> = {
  width: 200,
  display: 'block',
  float: 'left',
  padding: '12px',
};

const profilePictureStyle: SxProps<Theme> = {
  width: 48,
  height: 48,
  display: 'block',
  float: 'left',
};

const iconButtonStyle: SxProps<Theme> = {
  float: 'left',
};

const regionTextStyle: SxProps<Theme> = {
  paddingRight: '10px',
};

const gridContainerStyle: SxProps<Theme> = {
  minHeight: '80px',
};

const people360Text: SxProps<Theme> = {
  color: hpYellowPrimary,
  fontWeight: 'bold',
  marginLeft: '20px',
  fontSize: '1.1rem',
};

const PrimaryAppbar = () => {
  const currentUser = useCurrentUser();
  const { isDarkMode } = useTheme();

  //TODO: add isOnlyCraft to useRoles() when craft feature is ready to be pushed to prod
  const { isEnterpriseAdmin, isStockholder, isDM, isCraft } = useRoles();
  const [downgradedRolesValue] = useState(window.sessionStorage.getItem('downgradedRoles'));
  const [isTestByRoleDialogOpen, setIsTestByRoleDialogOpen] = useState(false);
  const [hpLogoSrc, setHpLogoSrc] = useState(hpLogoBlack);

  const [selectedDistricts, setSelectedDistricts] = useRecoilState(selectedDistrictsState);
  const [selectedEmployeeType, setSelectedEmployeeType] = useRecoilState(selectedEmployeeTypeState);
  const [searchParams, setSearchParams] = useGlobalSearchParams();
  const regionsSearchParams = searchParams.getAll('region');
  const employeeTypeSearchParams = searchParams.get('employeeType');
  useEffect(() => {
    if (regionsSearchParams.length) {
      const filteredRegionSearchParams = regionsSearchParams.filter((district) => district);
      if (
        filteredRegionSearchParams.length !== selectedDistricts?.length ||
        filteredRegionSearchParams.some((region) => !selectedDistricts?.includes(region))
      ) {
        setSelectedDistricts(filteredRegionSearchParams);
      }
    }
    if (employeeTypeSearchParams && employeeTypeSearchParams !== selectedEmployeeType) {
      setSelectedEmployeeType(employeeTypeSearchParams);
    }
    if (isDarkMode) {
      setHpLogoSrc(hpLogoLight);
    } else {
      setHpLogoSrc(hpLogoBlack);
    }
  }, [
    regionsSearchParams,
    selectedDistricts,
    setSelectedDistricts,
    employeeTypeSearchParams,
    selectedEmployeeType,
    setSelectedEmployeeType,
    isDarkMode,
  ]);

  const handleSetSelectedDistricts = (newSelectedDistricts: string[]) => {
    setSelectedDistricts(newSelectedDistricts);
    setSearchParams((previousParams) => {
      previousParams.delete('region');
      if (newSelectedDistricts.length) {
        newSelectedDistricts.forEach((region) => previousParams.append('region', region));
      } else {
        previousParams.append('region', '');
      }
      return previousParams;
    });
  };

  const handleSetSelectedEmployeeType = (newSelectedEmployeeType: string) => {
    setSelectedEmployeeType(newSelectedEmployeeType);
    setSearchParams((previousParams) => {
      previousParams.delete('employeeType');
      previousParams.append('employeeType', newSelectedEmployeeType);
      return previousParams;
    });
  };

  const homeDistrict = currentUser?.homeDistrict?.toString();
  if (selectedDistricts === null && homeDistrict) {
    handleSetSelectedDistricts([homeDistrict]);
  }

  const craftEmployeeType =
    currentUser?.roles?.includes('craft') && currentUser?.roles.length === 1 ? 'Craft' : 'Salary';
  if (selectedEmployeeType === null && craftEmployeeType) {
    handleSetSelectedEmployeeType(craftEmployeeType);
  }

  const onSelectedDistrictsChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    handleSetSelectedDistricts(typeof value === 'string' ? [value] : value);
  };

  //TODO: Uncomment when craft feature is ready to be pushed to prod
  // const onSelectedEmployeeTypeChange = (event: SelectChangeEvent<string>) => {
  //   const value = event.target.value;
  //   handleSetSelectedEmployeeType(value);
  // };

  const deleteSelectedDistricts = (index: number) => {
    const selectedDistrictsCopy = [...(selectedDistricts as unknown as string[])];
    if (index > -1) {
      selectedDistrictsCopy.sort();
      selectedDistrictsCopy.splice(index, 1);
    }
    handleSetSelectedDistricts([...selectedDistrictsCopy]);
    return;
  };

  const enableDowngradeRolesDialog = () => {
    setIsTestByRoleDialogOpen(true);
  };

  const renderSelectedDistrictsChips = (value: unknown) => {
    const selectedDistricts = value as string[];
    const chipValues = selectedDistricts.length
      ? selectedDistricts.length < 4
        ? [...selectedDistricts]
        : [`${selectedDistricts.length} regions selected`]
      : ['All Regions'];
    chipValues.sort();
    return (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
        {chipValues.map((district, index) => (
          <Chip
            sx={chipStyle}
            key={index}
            label={district}
            onDelete={() => deleteSelectedDistricts(index)}
            deleteIcon={<ClearIcon fontSize="small" onMouseDown={(event) => event.stopPropagation()} />}
          />
        ))}
      </Box>
    );
  };

  return (
    <Fragment>
      <AppBar sx={appBarStyle}>
        <Toolbar>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={gridContainerStyle}>
            <Grid item>
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                  <SideNavigationMenu />
                </Grid>
                <Grid item>
                  <Box component="img" sx={hpLogoImgStyle} src={hpLogoSrc} alt="Hensel Phelps Logo"></Box>
                </Grid>
                <Grid item>
                  <Typography sx={people360Text}>People 360</Typography>
                </Grid>
              </Grid>
            </Grid>
            {(ENV === APP_ENVIRONMENTS.local || ENV === APP_ENVIRONMENTS.dev) && (
              <Grid item>
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item>
                    <IconButton sx={iconButtonStyle} size="large" onClick={enableDowngradeRolesDialog}>
                      <DeveloperModeIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography color="textPrimary">
                      You are testing using downgraded role(s) in this tab: {downgradedRolesValue}
                    </Typography>
                  </Grid>
                </Grid>
                <TestByRoleDialog
                  isOpen={isTestByRoleDialogOpen}
                  setIsOpen={setIsTestByRoleDialogOpen}
                  downgradedRoles={downgradedRolesValue?.length ? downgradedRolesValue?.split(',') : []}
                />
              </Grid>
            )}
            <Grid item>
              <Grid container direction="row" alignItems="center" spacing={1}>
                {/* TODO: Uncomment when craft feature is ready to be pushed to prod */}
                {/* {!isOnlyCraft && (
                  <Select
                    value={selectedEmployeeType ?? 'Craft'}
                    onChange={onSelectedEmployeeTypeChange}
                    variant="outlined"
                    sx={employeeTypeSelectStyle}
                    defaultValue={'Salary'}
                  >
                    {EMPLOYEE_TYPE.map((type) => (
                      <MenuItem key={`employeeTypeFilterOption${type}`} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                )} */}
                {(isEnterpriseAdmin || isStockholder || isDM || isCraft) && (
                  <Grid item>
                    <Stack direction="row">
                      <Grid container direction="row" alignItems="center" spacing={1}>
                        <Typography sx={regionTextStyle} color="textPrimary">
                          Regions:
                        </Typography>
                        <Select
                          multiple
                          value={selectedDistricts ?? []}
                          onChange={onSelectedDistrictsChange}
                          variant="outlined"
                          sx={regionSelectStyle}
                          style={{ backgroundColor: isDarkMode ? 'inherit' : backgroundBlocks }}
                          renderValue={renderSelectedDistrictsChips}
                          displayEmpty
                          size="small"
                        >
                          {DISTRICTS.map((district) => (
                            <MenuItem key={`districtFilterOption${district.number}`} value={district.number}>
                              <ListItemIcon>
                                <Checkbox checked={selectedDistricts?.includes(district.number)} />
                              </ListItemIcon>
                              <ListItemText primary={`${district.number} - ${district.name}`} />
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Stack>
                  </Grid>
                )}
                <Grid item>
                  <a
                    href="https://henselphelps.service-now.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Help & Support"
                  >
                    <IconButton sx={iconButtonStyle} size="large">
                      <HelpOutlineIcon />
                    </IconButton>
                  </a>
                </Grid>
                <Grid item>
                  <ThemeToggle />
                </Grid>
                {currentUser?.profilePicture !== null && (
                  <Grid item sx={profilePictureStyle}>
                    <Avatar src={currentUser?.profilePicture} alt="Hensel Phelps User Avatar" />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

export default PrimaryAppbar;
