import { PersonnelGridRowData } from 'components/PersonnelGrid/PersonnelGrid';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import TabPanel from 'components/shared/TabPanel';
import { CONSULTANT_GRID_ID } from 'constants/syncfusionComponentIds';

import useRoles from 'hooks/useRoles';
import { FC, Fragment, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { selectedDistrictsState, selectedEmployeeTypeState } from 'recoil/selectedDistrict/atom';
import { SortOrderOption, usePersonListResultForGanttQuery } from 'types/generated/graphql';

import { Box, Card, Grid, Tab, Tabs, Typography, SxProps, Theme } from '@mui/material';
import ConsultantsGrid from 'components/ConsultantsGrid';

const boxRootStyle: SxProps<Theme> = {
  borderBottom: 1,
  borderColor: 'divider',
  marginBottom: '10px',
};

const LOCAL_STORAGE_TAB_KEY = 'consultantTab';

export const Consultants: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isAuthenticated, isOnlyCraft } = useRoles();
  const selectedDistricts = useRecoilValue(selectedDistrictsState);
  const employeeTypeDropdownValue = useRecoilValue(selectedEmployeeTypeState);
  const selectedEmployeeType = isOnlyCraft ? 'Craft' : employeeTypeDropdownValue;
  const noDistrictSelected = !selectedDistricts?.length;
  const selectedTabSearchParam = searchParams.get('tab');
  const selectedTabLocalStorage = window.localStorage.getItem(LOCAL_STORAGE_TAB_KEY);
  const [selectedTab, setSelectedTab] = useState(
    selectedTabSearchParam
      ? Number(selectedTabSearchParam)
      : selectedTabLocalStorage
      ? Number(selectedTabLocalStorage)
      : 0,
  );

  const handleTabChange = (_: any, newReportingTabSelected: number) => {
    setSelectedTab(newReportingTabSelected);
    setSearchParams((previousParams) => {
      // We need to wipe all URL params that are controlled within the tabs.
      ['role', 'startDate', 'endDate'].forEach((key) => previousParams.delete(key));
      previousParams.set('tab', newReportingTabSelected.toString());
      return previousParams;
    });
    window.localStorage.setItem(LOCAL_STORAGE_TAB_KEY, newReportingTabSelected.toString());
  };

  const personListResultForGanttQueryVariables = {
    isOnlyCraft: selectedEmployeeType === 'Craft',
    order: {
      direction: SortOrderOption.Asc,
      field: 'id',
    },
    search: '',
    isActive: ['Active', 'Awaiting Hire'],
    districts: selectedDistricts,
  };

  const { data, loading } = usePersonListResultForGanttQuery({
    variables: personListResultForGanttQueryVariables,
    fetchPolicy: 'no-cache',
    skip: noDistrictSelected,
  });

  const rawPersonnelGanttData = data?.personListResult.items ?? [];

  const personnelRoleData: PersonnelGridRowData[] = rawPersonnelGanttData
    .flatMap((personnel) => {
      if (!personnel.roles.length) {
        return [{ personnel }];
      }
      return personnel.roles.map((role) => ({
        personnel,
        role,
      }));
    })
    .filter((personnel) => personnel.personnel.prJobTitle === 'Consultant');

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        {noDistrictSelected && (
          <Typography>Please select at least one region from the filter at the top of the page.</Typography>
        )}
        {!noDistrictSelected && (loading || !isAuthenticated) && <LoadingSpinner />}
        {!noDistrictSelected && !loading && isAuthenticated && (
          <>
            <Card>
              <Fragment>
                <Box sx={boxRootStyle}>
                  <Tabs value={selectedTab} onChange={handleTabChange}>
                    <Tab label="All Consultants" />
                  </Tabs>
                </Box>
                <TabPanel value={selectedTab} index={0}>
                  {(loading || !isAuthenticated) && <LoadingSpinner />}
                  {!loading && isAuthenticated && (
                    <ConsultantsGrid gridId={CONSULTANT_GRID_ID} dataSource={personnelRoleData} />
                  )}
                </TabPanel>
              </Fragment>
            </Card>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Consultants;
