import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import HelpTooltip from 'components/shared/HelpTooltip';
import StyledDatePicker from 'components/shared/KeyboardDatePicker';
import { CONTRACT_TYPES } from 'constants/contractTypes';
import { DISTRICTS } from 'constants/districts';
import { ACTIVE_STATUS, ANTICIPATED_STATUS, ARCHIVED_STATUS } from 'constants/projectStatus';
import { USER_INPUT_SOURCE } from 'constants/tooltips';
import { VERTICAL_MARKETS } from 'constants/verticalMarkets';
import { GET_SINGLE_PROJECT } from 'graphql/projects';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { FC, Fragment, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import {
  GetSingleProjectQuery,
  // ProjectRoleUpsertInput,
  useUpsertProjectMutation,
  // useUpsertProjectRolesMutation,
} from 'types/generated/graphql';
import { convertUTCDateToLocalDate, generateTransactionKey } from 'utils/general';

import { Autocomplete, FormHelperText, Grid, InputLabel, SxProps, TextField, Theme } from '@mui/material';

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1),
  width: '100%',
});

const formHelperErrorTextStyle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error.main,
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
});

type EditProjectDialogProps = {
  project?: GetSingleProjectQuery['project'];
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
};

type ProjectFormValues = {
  name: string;
  number?: string;
  streetAndNumber?: string;
  streetDetail?: string;
  city?: string;
  stateOrProvince?: string;
  zipOrPostalCode?: string;
  description?: string;
  regions: string[];
  status: string;
  verticalMarkets: string[];
  contractTypes?: string;
  startDate: Date;
  endDate: Date;
};

const EditProjectDialog: FC<EditProjectDialogProps> = ({ project, isOpen, setIsOpen }) => {
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const { displayToast } = useToast();
  const [updateProject, { loading: isLoading }] = useUpsertProjectMutation({
    refetchQueries: [
      {
        query: GET_SINGLE_PROJECT,
        variables: { id: project?.id },
      },
    ],
    awaitRefetchQueries: true,
  });
  // const [updateProjectRoles] = useUpsertProjectRolesMutation();

  const defaultValues = {
    name: project?.name ?? '',
    number: project?.number ?? '',
    streetAndNumber: project?.location?.streetAndNumber ?? '',
    streetDetail: project?.location?.streetDetail ?? '',
    city: project?.location?.city ?? '',
    stateOrProvince: project?.location?.stateOrProvince ?? '',
    zipOrPostalCode: project?.location?.zipOrPostalCode ?? '',
    description: project?.description ?? '',
    regions: project?.districts?.map((district) => district),
    status: project?.status,
    verticalMarkets: project?.verticalMarkets?.map((verticalMarket) => verticalMarket),
    contractTypes: project?.contractType ?? undefined,
    startDate: project?.startDate,
    endDate: project?.endDate,
  };
  const { handleSubmit, control, reset, setValue } = useForm<ProjectFormValues>({ defaultValues });

  const [name, regions, status, verticalMarkets, contractTypes] = useWatch({
    control,
    name: ['name', 'regions', 'status', 'verticalMarkets', 'contractTypes'],
  });

  // const updateRolesOnProject = (
  //   updatedProjectData: any,
  //   project: GetSingleProjectQuery['project'] | undefined,
  // ): ProjectRoleUpsertInput[] => {
  //   const roles: ProjectRoleUpsertInput[] = [];

  //   project?.roles?.forEach((role: any) => {
  //     if (!role.person) {
  //       roles.push({
  //         id: role.id,
  //         projectId: updatedProjectData.id,
  //         roleName: role.roleName,
  //         startDate: DateTime.fromISO(updatedProjectData.startDate).toISODate(),
  //         endDate: DateTime.fromISO(updatedProjectData.endDate).toISODate(),
  //       });
  //     }
  //   });
  //   return roles;
  // };

  const onSubmit: SubmitHandler<ProjectFormValues> = (data: any) => {
    updateProject({
      variables: {
        input: {
          transactionKey,
          id: project?.id,
          name: data.name,
          districts: data.regions,
          verticalMarkets: data.verticalMarkets,
          status: data.status,
          startDate: DateTime.fromISO(data.startDate).toISODate(),
          endDate: DateTime.fromISO(data.endDate).toISODate(),
          description: data.description,
          contractType: data.contractTypes,
          location: {
            display: data.display ?? '',
            city: data.city ?? '',
            stateOrProvince: data.stateOrProvince ?? '',
            streetAndNumber: data.streetAndNumber ?? '',
            streetDetail: data.streetDetail ?? '',
            zipOrPostalCode: data.zipOrPostalCode ?? '',
          },
          number: data.number ?? '',
        },
      },
    })
      .then(() => {
        // const updatedProject = updatedProjectData.data?.upsertProject;
        // const updatedProjectRoles = updateRolesOnProject(updatedProject, project);
        // updateProjectRoles({ variables: { input: updatedProjectRoles } });
        displayToast('The project was updated successfully', 'success');
        setIsOpen(false);
      })
      .catch((error) => {
        console.error('Update project failed: ', error);
        displayToast(
          'Error: Something went wrong while trying to update the project. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };
  const handleClose = () => {
    setIsOpen(false);
    reset(defaultValues);
    setTransactionKey(generateTransactionKey());
  };

  useEffect(() => {
    name &&
    regions &&
    regions.length > 0 &&
    status &&
    verticalMarkets &&
    verticalMarkets.length > 0 &&
    contractTypes &&
    contractTypes.length > 0
      ? setCanSubmit(true)
      : setCanSubmit(false);
  }, [name, regions, status, verticalMarkets, contractTypes]);

  return (
    <StyledDialog
      isLoading={isLoading}
      title={`Edit ${project?.name}`}
      content={
        <Grid container sx={containerStyle} rowSpacing={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container rowSpacing={2} columnSpacing={{ sm: 2 }}>
              <Grid item xs={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Project Name" />
                      </InputLabel>
                      <TextField
                        onChange={onChange}
                        value={value}
                        error={value ? false : true}
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                      {!value && <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="number"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Project Number" />
                      </InputLabel>

                      <TextField onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="streetAndNumber"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Street Address" />
                      </InputLabel>

                      <TextField onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="streetDetail"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Street Address 2" />
                      </InputLabel>

                      <TextField onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="City" />
                      </InputLabel>

                      <TextField onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="stateOrProvince"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="State/Province" />
                      </InputLabel>

                      <TextField onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="zipOrPostalCode"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Zip/Postal Code" />
                      </InputLabel>

                      <TextField onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Description" />
                      </InputLabel>

                      <TextField onChange={onChange} value={value} variant="outlined" fullWidth size="small" />
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="regions"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Regions" />
                      </InputLabel>
                      <Fragment>
                        <Autocomplete
                          multiple
                          disableCloseOnSelect
                          limitTags={5}
                          size="small"
                          options={DISTRICTS.map((district) => {
                            return `${district.number} - ${district.name}`;
                          })}
                          getOptionLabel={(value) => value}
                          filterSelectedOptions
                          onChange={(_event, value) => {
                            const firstIndex = 0;
                            const selectedRegions = value.map((district) => district.split(' - ')[firstIndex]);
                            onChange(selectedRegions);
                            setValue('regions', selectedRegions);
                          }}
                          value={value && value.map((district) => district.split(' - ')[0])}
                          isOptionEqualToValue={(option: any, value: any) => {
                            const firstIndex = 0;
                            return option.split(' - ')[firstIndex] === value;
                          }}
                          renderInput={(params) => (
                            <TextField
                              error={!value || (value && value.length === 0)}
                              {...params}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                        {(!value || (value && value.length === 0)) && (
                          <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>
                        )}
                      </Fragment>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Status" />
                      </InputLabel>
                      <Fragment>
                        <Autocomplete
                          size="small"
                          options={[...ACTIVE_STATUS, ...ANTICIPATED_STATUS, ...ARCHIVED_STATUS]}
                          getOptionLabel={(value) => value}
                          filterSelectedOptions
                          onChange={(_event, value) => {
                            onChange(value);
                          }}
                          value={value}
                          isOptionEqualToValue={(option: any, value: any) => {
                            return option === value;
                          }}
                          renderInput={(params) => (
                            <TextField error={value ? false : true} {...params} variant="outlined" />
                          )}
                        />
                        {!value && <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>}
                      </Fragment>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="verticalMarkets"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Vertical Markets" />
                      </InputLabel>
                      <Fragment>
                        <Autocomplete
                          multiple
                          disableCloseOnSelect
                          size="small"
                          options={VERTICAL_MARKETS}
                          getOptionLabel={(value) => value}
                          filterSelectedOptions
                          onChange={(_event, value) => {
                            onChange(value);
                          }}
                          value={value}
                          isOptionEqualToValue={(option: any, value: any) => {
                            return option === value;
                          }}
                          renderInput={(params) => (
                            <TextField error={!value || (value && value.length === 0)} {...params} variant="outlined" />
                          )}
                        />
                        {(!value || (value && value.length === 0)) && (
                          <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>
                        )}
                      </Fragment>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="contractTypes"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Contract Type" />
                      </InputLabel>
                      <Fragment>
                        <Autocomplete
                          size="small"
                          options={CONTRACT_TYPES}
                          getOptionLabel={(value) => value}
                          filterSelectedOptions
                          onChange={(_event, value) => {
                            onChange(value);
                          }}
                          value={value}
                          isOptionEqualToValue={(option: any, value: any) => {
                            return option === value;
                          }}
                          renderInput={(params) => (
                            <TextField error={!value ? true : false} {...params} variant="outlined" />
                          )}
                        />
                        {!value && <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>}
                      </Fragment>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="Start Date" />
                      </InputLabel>
                      <StyledDatePicker
                        slotProps={{ textField: { size: 'small' } }}
                        value={convertUTCDateToLocalDate(value) || null}
                        onChange={onChange}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel>
                        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText="End Date" />
                      </InputLabel>
                      <StyledDatePicker
                        slotProps={{ textField: { size: 'small' } }}
                        value={convertUTCDateToLocalDate(value) || null}
                        onChange={onChange}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      }
      actions={
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <StyledButtonSecondary disabled={false} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={'Submit'}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!canSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default EditProjectDialog;
