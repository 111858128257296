import AddIcon from '@mui/icons-material/Add';
import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  IconButton,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import RolesSummaryDialog from 'components/shared/ProjectGantt/RolesSummaryDialog';
import { ROLES_BY_FULL_DISPLAY_NAME } from 'constants/roles';
import { ROLES_SUMMARY_DICTIONARY } from 'constants/rolesSummary';
import useToast from 'hooks/useToast';
import { FC, Fragment, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { FormValues, RoleImport } from 'types';
import { Person, Project, ProjectRoleUpsertInput, useUpsertProjectRolesMutation } from 'types/generated/graphql';
import { generateTransactionKey, serializeDate } from 'utils/general';

type AddProjectRoleDialogProps = {
  isOpen: boolean;
  setIsOpen?: (x: boolean) => void;
  projectListResult: Project[] | undefined;
  personnelList: Person[];
  selectedEmployeeType: string | null;
  projectSelection?: {
    id: string;
    name: string;
  } | null;
  onDataChanged?: { (): Promise<void> };
};

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1),
});

const formStyle: SxProps<Theme> = {
  width: '100%',
};

const iconButtonStyle: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: '#700006',
    color: 'white',
  },
  boxShadow: ' 0 2px 2px 0 rgba(0,0,0,0.14)',
});

const formHelperErrorTextStyle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error.main,
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  marginTop: '4px',
  marginRight: '14px',
  marginLeft: '14px',
});

const formRowStyle: SxProps<Theme> = {
  flexDirection: 'row',
  display: 'flex',
  marginBottom: '20px',
};

const AddProjectRoleDialog: FC<AddProjectRoleDialogProps> = ({
  isOpen,
  setIsOpen,
  projectListResult,
  personnelList,
  selectedEmployeeType,
  projectSelection,
  onDataChanged,
}) => {
  const { displayToast } = useToast();
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [rolesImported, setRolesImported] = useState<RoleImport[]>([
    {
      'Employee Id': '',
      'Role Position': '',
      'Role Begin Date': '',
      'Role End Date': '',
      Notes: '',
      'Focus Area': '',
      notesFlag: false,
      Quantity: '1',
    },
  ]);
  const [canContinueAfterRolesSummary, setCanContinueAfterRolesSummary] = useState<boolean>(false);
  const [addProjectRoles, { loading: isLoading }] = useUpsertProjectRolesMutation();
  const defaultValues = {
    project: projectSelection ?? { id: '', name: '' },
    rolesSummary: {
      roleQuantity: '1',
    },
  };
  const { handleSubmit, control, reset, setValue } = useForm<FormValues>({ defaultValues });
  const personnelListCopy = [...personnelList];

  const [project] = useWatch({
    control,
    name: ['projectSelection'],
  });

  const handleClose = () => {
    if (setIsOpen) {
      setIsOpen(false);
    }
    reset(defaultValues);
    setCanContinueAfterRolesSummary(false);
    setCanSubmit(false);
    setRolesImported([
      {
        'Employee Id': '',
        'Role Position': '',
        'Role Begin Date': '',
        'Role End Date': '',
        Notes: '',
        'Focus Area': '',
        notesFlag: false,
        Quantity: '1',
      },
    ]);
  };

  const handleRolesSummaryDialogInputChange = (
    value: string | boolean | null,
    required: boolean,
    index: number,
    key: keyof RoleImport,
  ) => {
    setCanContinueAfterRolesSummary(true);
    let rolesImportedCopy: any[] = [...rolesImported];
    rolesImportedCopy[index][key] =
      key === 'Employee Id'
        ? personnelList.find((personnel: Person) => {
            const personnelNameValueIndex = 0;
            const personnelValue = typeof value === 'string' ? value.split(' - ')[personnelNameValueIndex] : value;
            return personnel?.name?.lastCommaFirst === personnelValue;
          })?.id ?? null
        : value;
    setRolesImported(rolesImportedCopy);
    rolesImported?.forEach((role: RoleImport) => {
      Object.keys(role).forEach((roleKey: string) => {
        if (
          roleKey !== 'Employee Id' &&
          roleKey !== 'Quantity' &&
          roleKey !== 'Focus Area' &&
          roleKey !== 'Notes' &&
          roleKey !== 'notesFlag' &&
          !(role as RoleImport)[roleKey as keyof RoleImport]
        ) {
          setCanContinueAfterRolesSummary(false);
        }
      });

      if (canContinueAfterRolesSummary && key !== 'Employee Id') {
        required && value ? setCanContinueAfterRolesSummary(true) : setCanContinueAfterRolesSummary(false);
      }
    });
  };

  const handleRoleQuantity = (quantity: string, roleIndex: number) => {
    let rolesImportedCopy: RoleImport[] = rolesImported;
    rolesImportedCopy[roleIndex].Quantity = quantity;
    setRolesImported(rolesImportedCopy);
  };

  const addRoleToRolesImported = () => {
    setRolesImported([
      ...rolesImported,
      {
        'Employee Id': null,
        'Role Position': '',
        'Role Begin Date': '',
        'Role End Date': '',
        Notes: '',
        'Focus Area': '',
        Quantity: '1',
        notesFlag: false,
      },
    ]);
    setCanContinueAfterRolesSummary(false);
  };

  const deleteRoleFromRolesImported = (roleIndex: number) => {
    setRolesImported([...rolesImported.slice(0, roleIndex), ...rolesImported.slice(roleIndex + 1)]);
    const tempRolesImportedArray = [...rolesImported.slice(0, roleIndex), ...rolesImported.slice(roleIndex + 1)];
    if (tempRolesImportedArray.length < 1) {
      setCanContinueAfterRolesSummary(false);
    } else {
      setCanContinueAfterRolesSummary(true);

      tempRolesImportedArray?.forEach((role: RoleImport) => {
        Object.keys(role).forEach((roleKey: string) => {
          if (roleKey !== 'Employee Id' && !(role as RoleImport)[roleKey as keyof RoleImport]) {
            setCanContinueAfterRolesSummary(false);
          } else {
            setCanContinueAfterRolesSummary(true);
          }
        });

        ROLES_SUMMARY_DICTIONARY.forEach((roleSummary) => {
          if (roleSummary.required && !role[roleSummary.key as keyof RoleImport]) {
            setCanContinueAfterRolesSummary(false);
          } else {
            setCanContinueAfterRolesSummary(true);
          }
        });
      });
    }
  };

  const addProjectRoleDialogRefetch = async () => {
    if (onDataChanged) {
      await Promise.all([onDataChanged()]);
    }
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const projectId = projectListResult?.find((project) => project.id === data.projectSelection?.id)?.id ?? '';
    const roles: ProjectRoleUpsertInput[] = [];

    rolesImported?.forEach((role: RoleImport) => {
      for (var i = 0; i < Number(role.Quantity); i++) {
        const convertedAndFormattedRoleEndDate = new Date(role['Role End Date'].replace(/-/g, '/'));
        const convertedAndFormattedStartDate = new Date(role['Role Begin Date'].replace(/-/g, '/'));
        roles.push({
          transactionKey: generateTransactionKey(),
          projectId: projectId,
          personId: role['Employee Id'] !== '' ? role['Employee Id'] : null,
          endDate: serializeDate(convertedAndFormattedRoleEndDate),
          roleName: ROLES_BY_FULL_DISPLAY_NAME[role['Role Position']].roleName,
          startDate: serializeDate(convertedAndFormattedStartDate),
          focusArea: role['Focus Area'] ?? '',
          notes: role['Notes'] ?? '',
          notesFlag: role.notesFlag,
        });
      }
    });

    addProjectRoles({ variables: { input: roles } })
      .then(() => {
        addProjectRoleDialogRefetch();
        //TODO: Look into how to fix getting the project data back faster
        // ganttInstance?.resources.push(addedRole);
        //ganttInstance?.refresh();
        displayToast('The role was added successfully', 'success');
        handleClose();
      })
      .catch(() => {
        displayToast(
          'Error: Something went wrong while trying to add a role. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  useEffect(() => {
    project?.name && canContinueAfterRolesSummary ? setCanSubmit(true) : setCanSubmit(false);
  }, [project, canContinueAfterRolesSummary]);

  useEffect(() => {
    setValue('projectSelection', projectSelection ?? { id: '', name: '' });
  }, [projectSelection, setValue]);

  return (
    <StyledDialog
      title={'Add Project Roles'}
      isLoading={isLoading}
      content={
        <Grid container sx={containerStyle}>
          <Box component="form" sx={formStyle}>
            <Grid container spacing={12} sx={formRowStyle}>
              <Grid item xs={8}>
                <Typography>Projects</Typography>
                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Fragment>
                        <Autocomplete
                          id="contentType"
                          size="small"
                          fullWidth
                          options={
                            projectListResult?.map((project) => {
                              return { id: project.id, name: project.name };
                            }) ?? []
                          }
                          getOptionLabel={(value) => value.name}
                          filterSelectedOptions
                          onChange={(_event, newValue) => {
                            onChange(newValue);
                          }}
                          value={value}
                          isOptionEqualToValue={(
                            option: { id: string; name: string },
                            value: { id: string; name: string },
                          ) => {
                            return option.id === value.id;
                          }}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={props.id}>
                                {option.name}
                              </li>
                            );
                          }}
                          renderInput={(params) => <TextField error={!value} {...params} variant="outlined" />}
                        />
                        {!value && <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>}
                      </Fragment>
                    );
                  }}
                  name="projectSelection"
                  control={control}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container alignItems={'center'}>
                  <Grid item>
                    <Tooltip title={'Add Role'}>
                      <IconButton sx={{ root: iconButtonStyle }} size="small" onClick={() => addRoleToRolesImported()}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Typography>Add Role</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={formRowStyle}>
              {rolesImported.map((roleImported: any, index: number) => {
                return (
                  <RolesSummaryDialog
                    key={index}
                    control={control}
                    roleImported={roleImported}
                    handleRolesSummaryDialogInputChange={handleRolesSummaryDialogInputChange}
                    roleIndex={index}
                    personnelList={personnelListCopy}
                    roleImportedLength={rolesImported.length}
                    DeleteRoleFromRolesImported={deleteRoleFromRolesImported}
                    handleRoleQuantity={handleRoleQuantity}
                    selectedEmployeeType={selectedEmployeeType}
                  />
                );
              })}
            </Grid>
          </Box>
        </Grid>
      }
      actions={
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <StyledButtonSecondary disabled={false} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={'Submit'}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!canSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default AddProjectRoleDialog;
