import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-notifications/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-react-grids/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';

import HelpTooltip from 'components/shared/HelpTooltip';
import StyledDatePicker from 'components/shared/KeyboardDatePicker/KeyboardDatePicker';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { CONSULTANT_PERSONNEL_ID } from 'constants/consultant';
import { ROLES_BY_FULL_DISPLAY_NAME, ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE } from 'constants/roles';
import {
  ROOT_CONTAINER_PADDING_PX,
  TAB_PANEL_HEIGHT_PX,
  TOTAL_HEIGHT_OVERHEAD_FROM_APP_WRAPPING_PX,
} from 'constants/themes/dimensions';
import { EMPLOYEE_MASTER_SOURCE, USER_INPUT_SOURCE } from 'constants/tooltips';
import useCurrentUser from 'hooks/useCurrentUser';
import useDataFilter from 'hooks/useDataFilter';
import useRoles from 'hooks/useRoles';
import { DateTime } from 'luxon';
import { FC, Fragment, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedEmployeeTypeState } from 'recoil/selectedDistrict/atom';
import { PersonListResultForGanttQueryResult, ProjectListResultQueryResult } from 'types/generated/graphql';
import { convertUTCDateToLocalDate, createExportColumns, graphqlDateToDateTime } from 'utils/general';
import { roleFullDisplayNameSortComparer } from 'utils/roles';

import { Flag, OpenInNew } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Button,
  Grid,
  IconButton,
  Popper,
  Stack,
  styled,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Predicate, Query } from '@syncfusion/ej2-data';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  CommandColumn,
  ContextMenu,
  ExcelExport,
  ExcelExportProperties,
  Filter,
  GridColumnModel,
  GridComponent,
  GridModel,
  InfiniteScroll,
  Inject,
  Reorder,
  Resize,
  Search,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { hpYellowPrimary, trueBlack } from 'constants/themes/colors';
import CreateConsultantDialog from './CreateConsultantDialog';

const ROW_HEIGHT_PX = 20;
// These do not control the actual heights. If the heights change, these values should be updated.
const FILTER_HEIGHT_PX = 56;
const SYNCFUSION_TOOLBAR_HEIGHT_PX = 52;

const ANY_ROLE = 'Any';
const ACTION_MENU = 'Action Menu';

const cellIconButtonStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: 0,
  color: theme.palette.primary.main,
});

const gridContainerStyle: SxProps<Theme> = {
  width: '100%',
  zIndex: 100,
};

const cellTypographyStyle: SxProps<Theme> = {
  fontSize: '13px',
};

const cellIconStyle: SxProps<Theme> = {
  height: `${ROW_HEIGHT_PX - 2}px`,
  verticalAlign: 'middle',
};

const roleSelectStyle: SxProps<Theme> = () => ({
  width: '250px',
});

const dateSelectStyle: SxProps<Theme> = () => ({
  width: '170px',
});

const accordionRootStyle: SxProps<Theme> = {
  boxShadow: 'none',
};

const accordionDetailsStyle: SxProps<Theme> = {
  padding: '16px',
};

const accordionSummaryStackStyle: SxProps<Theme> = {
  borderBottom: `3px solid ${hpYellowPrimary}`,
};

const accordionSummaryStyle: SxProps<Theme> = {
  width: '160px',
  backgroundColor: hpYellowPrimary,
  borderRadius: '16px 16px 0 0',
  minHeight: '32px',
  height: '32px',
  '&.Mui-expanded': {
    minHeight: '32px',
    height: '32px',
  },
};

const searchFilterTextStyle: SxProps<Theme> = {
  fontSize: '13px',
  fontWeight: 'bold',
  color: trueBlack,
};

const expandIconStyles: SxProps<Theme> = {
  color: trueBlack,
};

const clearFiltersButtonStyle: SxProps<Theme> = {
  padding: '7px 15px',
};

const ACTION_RESET_VIEW = 'Reset View';
const ACTION_CLEAR_ALL_FILTERS = 'Clear All Filters';
const DATE_FORMAT = 'yyyy-MM-dd';
const CREATE_CONSULTANT = 'Create Consultant';

export type PersonnelFromListResult = Exclude<
  PersonListResultForGanttQueryResult['data'],
  undefined
>['personListResult']['items'][number];

export type ProjectFromListResult = Exclude<
  ProjectListResultQueryResult['data'],
  undefined
>['projectListResult']['items'][number];
type ProjectRoleFromProjectListResult = ProjectFromListResult['roles'][number];

export type PersonnelGridRowData = {
  personnel: PersonnelFromListResult;
  role?: ProjectRoleFromProjectListResult;
};

export type PersonnelGridProps = {
  gridId: string;
  dataSource: PersonnelGridRowData[];
};

const rowSpansRange = (rowData: PersonnelGridRowData, startDate: DateTime | null, endDate: DateTime | null) => {
  if (!startDate && !endDate) {
    return true;
  } else if (rowData.role) {
    const role = rowData.role;
    const roleStart = DateTime.fromJSDate(role.startJsDate);
    const roleEnd = DateTime.fromJSDate(role.endJsDate);
    if (startDate && endDate) {
      const roleStartsDuringRange = roleStart >= startDate && roleStart <= endDate;
      const roleEndsDuringRange = roleEnd >= startDate && roleEnd <= endDate;
      return roleStartsDuringRange || roleEndsDuringRange || (roleStart <= startDate && roleEnd >= endDate);
    } else if (endDate) {
      return roleStart <= endDate;
    } else if (startDate) {
      return roleEnd >= startDate;
    } else {
      throw new Error('Should be unreachable.');
    }
  } else {
    return false;
  }
};

export let gridInstancesById: { [id: string]: GridComponent } = {};

export const ConsultantsGrid: FC<PersonnelGridProps> = ({ gridId, dataSource }) => {
  const currentUser = useCurrentUser();
  const { isEnterpriseAdmin, isDM, isStockholder, isMarketing, isAuthenticated, isCraft, isOnlyCraft } = useRoles();
  const [isCreateConsultantDialogOpen, setIsCreateConsultantDialogOpen] = useState(false);
  const employeeTypeDropdownValue = useRecoilValue(selectedEmployeeTypeState);
  const selectedEmployeeType = isOnlyCraft ? 'Craft' : employeeTypeDropdownValue;

  const getGridInstance = () => {
    return gridInstancesById[gridId] ?? null;
  };

  const [dataFilterRole, setDataFilterRole] = useDataFilter({
    filter: 'string',
    localStorageKey: `grid${gridId}Role`,
    searchParamKey: 'role',
  });
  const [dataFilterStartDate, setDataFilterStartDate] = useDataFilter({
    filter: 'date',
    localStorageKey: `grid${gridId}StartDate`,
    searchParamKey: 'startDate',
  });
  const [dataFilterEndDate, setDataFilterEndDate] = useDataFilter({
    filter: 'date',
    localStorageKey: `grid${gridId}EndDate`,
    searchParamKey: 'endDate',
  });

  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (dataFilterRole) count++;
    if (dataFilterStartDate) count++;
    if (dataFilterEndDate) count++;
    return count;
  }, [dataFilterRole, dataFilterStartDate, dataFilterEndDate]);

  const canViewPersonnelDetails = (row: PersonnelGridRowData) => {
    return (
      isEnterpriseAdmin ||
      isDM ||
      isStockholder ||
      isCraft ||
      (isMarketing &&
        (currentUser?.homeDistrict === row.personnel.prDistrict ||
          row.personnel.sharedWithDistricts?.includes(currentUser?.homeDistrict?.toString() ?? '')))
    );
  };

  const canViewPersonalDetails = () => {
    return isEnterpriseAdmin || isDM || isStockholder || isMarketing || isCraft;
  };

  const searchSettings = {
    fields: ['personnel.name.lastCommaFirst', 'role.project.name', 'personnel.notes', 'role.roleFullDisplayName'],
  };

  const columnList = () => {
    const standardColumns: React.ComponentProps<typeof ColumnDirective>[] = [
      {
        field: 'personnel.id',
        type: 'string',
        headerText: 'Personnel ID',
        allowEditing: false,
        visible: false,
        isPrimaryKey: true,
      },
      {
        field: 'role.id',
        type: 'string',
        headerText: 'Project Role ID',
        allowEditing: false,
        visible: false,
        isPrimaryKey: true,
      },
      {
        field: 'personnel.name.lastCommaFirst',
        type: 'string',
        headerText: 'Consultant Name',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={EMPLOYEE_MASTER_SOURCE} titleText={props.headerText} />
        ),
        template: (row: PersonnelGridRowData) =>
          row.personnel.id !== CONSULTANT_PERSONNEL_ID ? (
            <Typography sx={cellTypographyStyle}>
              <IconButton
                href={`/personnel/${row.personnel.id}`}
                target="_blank"
                sx={cellIconButtonStyle}
                disabled={!canViewPersonnelDetails(row)}
              >
                <OpenInNew sx={cellIconStyle} />
              </IconButton>
              {row.personnel.notesFlag && <Flag sx={cellIconStyle} />}
              {row.personnel.name?.lastCommaFirst}
            </Typography>
          ) : (
            <Typography sx={cellTypographyStyle}>{row.personnel.name?.lastCommaFirst}</Typography>
          ),
        allowEditing: false,
        width: 250,
      },
      {
        field: 'role.project.name',
        type: 'string',
        headerText: 'Job Name',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
        ),
        allowEditing: false,
        width: 250,
      },
      {
        field: 'role.roleFullDisplayName',
        type: 'string',
        headerText: 'Role',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
        ),
        template: (row: PersonnelGridRowData) =>
          ROLES_BY_FULL_DISPLAY_NAME[row.role?.roleFullDisplayName ?? '']?.abbreviation ?? '',
        sortComparer: roleFullDisplayNameSortComparer,
        allowEditing: false,
        width: 100,
      },
      {
        field: 'role.startDate',
        type: 'date',
        headerText: 'Role Start',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
        ),
        template: (row: PersonnelGridRowData) =>
          row && row.role ? graphqlDateToDateTime(row.role?.startDate).toFormat(DATE_FORMAT) : '',
        allowEditing: false,
        allowFiltering: false,
        width: 125,
      },
      {
        field: 'role.endDate',
        type: 'date',
        headerText: 'Role End',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
        ),
        template: (row: PersonnelGridRowData) =>
          row && row.role ? graphqlDateToDateTime(row.role?.endDate).toFormat(DATE_FORMAT) : '',
        allowEditing: false,
        allowFiltering: false,
        width: 125,
      },
    ];

    const personalDetailColumns: React.ComponentProps<typeof ColumnDirective>[] = [
      {
        field: 'personnel.notes',
        type: 'string',
        headerText: 'Notes',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
        ),
        allowEditing: false,
        width: 200,
      },
    ];

    let columns = standardColumns;
    if (canViewPersonalDetails()) {
      columns = columns.concat(personalDetailColumns);
    }
    return columns;
  };

  const columnDirectivePropsList: React.ComponentProps<typeof ColumnDirective>[] = columnList();

  // foreignKeyValue appears to be used as the column's name in the column picker.
  columnDirectivePropsList.forEach((column: GridColumnModel) => {
    if (typeof column.headerText === 'string') {
      column.foreignKeyValue = column.headerText;
    }
  });

  // TODO: disable/enable the buttons depending on the presence of groups.
  const toolbarClick: Exclude<GridModel['toolbarClick'], undefined> = (args: any) => {
    const gridInstance = getGridInstance();
    if (gridInstance && args.item.id.startsWith(gridId)) {
      // Trim away the grid ID and '_'
      const action = args.item.id.substring(gridId.length + 1);
      if (action === ACTION_CLEAR_ALL_FILTERS) {
        gridInstance.clearFiltering();
      } else if (action === 'excelexport') {
        const ps: ExcelExportProperties = {};
        const visibleColumns = gridInstance
          .getVisibleColumns()
          .filter((column) => column.foreignKeyValue !== ACTION_MENU);
        const exportColumns = createExportColumns(visibleColumns);
        ps.columns = exportColumns;
        gridInstance.excelExport(ps);
      } else if (action === ACTION_RESET_VIEW) {
        // TODO: we probably need to do this whenever the app updates,
        // since changes to the column definitions seem to scramble the view.
        gridInstance.enablePersistence = false;
        window.localStorage.setItem(`grid${gridId}`, '');
        gridInstance.destroy();
        window.location.reload();
      } else if (action === CREATE_CONSULTANT) {
        setIsCreateConsultantDialogOpen(true);
      }
    }
  };

  // Set Syncfusion DataUtil to use UTC time.
  // DataUtil.serverTimezoneOffset = 0;
  // const timezoneCorrectedDataSource = DataUtil.parse.parseJson?.(JSON.stringify(dataSource)) ?? dataSource;

  let values = '';
  let removeQuery = false;
  let valueAssign = false;

  const actionBegin: GridModel['actionBegin'] = (args: any) => {
    const gridInstance = getGridInstance();
    if (args.requestType === 'searching' && args.searchString) {
      const keys = args.searchString.split(' ');
      let flag = true;
      let predicate: Predicate | null | undefined = null;
      if (gridInstance && keys.length > 1) {
        if (gridInstance?.searchSettings.key !== '') {
          values = args.searchString;
          keys.forEach((key: Predicate) => {
            gridInstance?.getColumns().forEach((col) => {
              if (flag) {
                predicate = new Predicate(col.field, 'contains', key, true);
                flag = false;
              } else {
                var predic = new Predicate(col.field, 'contains', key, true);
                predicate = predicate?.or(predic);
              }
            });
          });
          if (predicate) {
            gridInstance.query = new Query().where(predicate);
            gridInstance.searchSettings.key = '';
            valueAssign = true;
            removeQuery = true;
            gridInstance.refresh();
          }
        }
      }
    }
  };

  const actionComplete: GridModel['actionComplete'] = (args: any) => {
    const gridInstance = getGridInstance();
    if (args.requestType === 'refresh' && valueAssign && gridInstance) {
      (document.getElementById(gridInstance.element.id + '_searchbar') as HTMLInputElement).value = values;
      valueAssign = false;
    } else if (
      gridInstance &&
      (document.getElementById(gridInstance.element.id + '_searchbar') as HTMLInputElement).value === '' &&
      args.requestType === 'refresh' &&
      removeQuery &&
      gridInstance
    ) {
      gridInstance.query = new Query();
      removeQuery = false;
      gridInstance.refresh();
    }
  };

  const onClearAllDataFilters = () => {
    setDataFilterRole(null);
    setDataFilterStartDate(null);
    setDataFilterEndDate(null);
  };

  const getEmployeeType = (row: PersonnelGridRowData) => {
    const isEmployeeCraftOrSalary = row.personnel.isCraft ? 'Craft' : 'Salary';
    return isEmployeeCraftOrSalary;
  };

  const filteredDataSource = dataSource.filter(
    (row) =>
      (dataFilterRole === null ||
        dataFilterRole === row.personnel.prJobTitleFullDisplayName ||
        dataFilterRole === row.role?.roleFullDisplayName) &&
      rowSpansRange(row, dataFilterStartDate, dataFilterEndDate) &&
      selectedEmployeeType === getEmployeeType(row),
  );

  const toolbarItems = [
    'ColumnChooser',
    ACTION_CLEAR_ALL_FILTERS,
    ACTION_RESET_VIEW,
    'ExcelExport',
    CREATE_CONSULTANT,
    'Search',
  ];

  return (
    <Fragment>
      <Stack>
        <Accordion sx={accordionRootStyle}>
          <Stack direction="column" sx={accordionSummaryStackStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={expandIconStyles} />} sx={accordionSummaryStyle}>
              <Typography sx={searchFilterTextStyle}>Search Filter ({activeFiltersCount})</Typography>
            </AccordionSummary>
          </Stack>
          <AccordionDetails sx={accordionDetailsStyle}>
            <Grid container>
              <Stack direction="row" spacing={7}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>Show</Typography>
                  <Autocomplete<string>
                    id="RoleFilter"
                    options={[ANY_ROLE, ...ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE(selectedEmployeeType === 'Craft')]}
                    value={dataFilterRole ?? ANY_ROLE}
                    onChange={(_, value) => setDataFilterRole(value === ANY_ROLE ? null : value)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={roleSelectStyle}
                    size="small"
                    PopperComponent={styled(Popper)(({ theme }) => ({
                      [`& .${autocompleteClasses.paper}`]: {
                        width: '400px',
                        // Shadow 8 is the same one that the StyledDatePicker ends up using.
                        boxShadow: theme.shadows[8],
                      },
                    }))}
                  />
                  <Typography>personnel and roles between:</Typography>
                  <StyledDatePicker
                    value={dataFilterStartDate ? convertUTCDateToLocalDate(dataFilterStartDate) : null}
                    onChange={setDataFilterStartDate}
                    maxDate={dataFilterEndDate || undefined}
                    sx={dateSelectStyle}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                  <Typography>and:</Typography>
                  <StyledDatePicker
                    value={dataFilterEndDate ? convertUTCDateToLocalDate(dataFilterEndDate) : null}
                    onChange={setDataFilterEndDate}
                    minDate={dataFilterStartDate || undefined}
                    sx={dateSelectStyle}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                  <Button onClick={onClearAllDataFilters} variant="outlined" sx={clearFiltersButtonStyle}>
                    Clear Filters
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Box>
          {useMemo(
            () => (
              <>
                {!isAuthenticated && <LoadingSpinner />}
                {isAuthenticated && (
                  <Grid container direction="column">
                    <Grid item>
                      <Box sx={gridContainerStyle}>
                        <GridComponent
                          id={gridId}
                          ref={(grid) => {
                            if (grid) {
                              gridInstancesById[gridId] = grid;
                            }
                          }}
                          allowExcelExport={true}
                          dataSource={filteredDataSource}
                          actionBegin={actionBegin}
                          actionComplete={actionComplete}
                          searchSettings={searchSettings}
                          // TODO: looks like there's a bug when trying to use Shimmer. Consider reporting it.
                          // loadingIndicator={{ indicatorType: 'Shimmer' }}
                          toolbar={
                            isOnlyCraft
                              ? toolbarItems.filter((toolbarItem) => toolbarItem !== CREATE_CONSULTANT)
                              : toolbarItems
                          }
                          toolbarClick={toolbarClick}
                          allowGrouping={false}
                          allowReordering={true}
                          allowResizing={true}
                          showColumnChooser={true}
                          showColumnMenu={true}
                          contextMenuItems={['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending']}
                          allowSorting={true}
                          allowFiltering={true}
                          allowSelection={true}
                          filterSettings={{ type: 'Excel' }}
                          // TODO: Decide which is more important: virtualization or fill handle.
                          // Fill handle requires cell selection, which is incompatible with virtualization.
                          // Pagination may be the only alternative to virtualization,
                          // and that would likely present challenges when it comes to group/filter/search.
                          // Note that if we chose not to use virtualization, other options would become available.
                          // TODO: find out what kind of incompatibility we're talking about. It seems to kind of work,
                          // so is it that there are edge cases/bugs? I only found one reference in the docs, so is it
                          // fixed, but not reflected in all of the docs?
                          // selectionSettings={{ cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell' }}
                          //enableVirtualization={true}
                          // TODO: decide whether column virtualization is worth trying.
                          // It does seem like it may improve things if you don't have good column hygeine,
                          // but it seems more buggy, in particular with grouping/aggregates.
                          // enableColumnVirtualization={true}
                          enablePersistence={true}
                          // TODO: If we figure out a way to make this dynamic, go clean up the other
                          // hard coded heights in the gantt tab
                          height={`calc(100vh - ${
                            TOTAL_HEIGHT_OVERHEAD_FROM_APP_WRAPPING_PX +
                            TAB_PANEL_HEIGHT_PX +
                            FILTER_HEIGHT_PX +
                            // This is a bit of a guess as to what really counts against the height. Syncfusion seems a bit inconsistent here.
                            SYNCFUSION_TOOLBAR_HEIGHT_PX +
                            ROW_HEIGHT_PX
                          }px)`}
                          width={`calc(100vw - ${ROOT_CONTAINER_PADDING_PX * 2}px)`}
                          enableInfiniteScrolling={true}
                          infiniteScrollSettings={{ initialBlocks: 5 }}
                          // TODO: Using a calculated height seems to throw off the auto page size.
                          // See if we can calculate this fully in code to get a simple ###px value that is dynamic.
                        >
                          <ColumnsDirective>
                            {columnDirectivePropsList.map(
                              (columnDirectiveProps: React.ComponentProps<typeof ColumnDirective>, index) => {
                                return <ColumnDirective key={index} {...columnDirectiveProps} />;
                              },
                            )}
                          </ColumnsDirective>
                          <Inject
                            services={[
                              Toolbar,
                              Reorder,
                              Resize,
                              ColumnChooser,
                              ColumnMenu,
                              ContextMenu,
                              ExcelExport,
                              Sort,
                              Filter,
                              Search,
                              InfiniteScroll,
                              CommandColumn,
                            ]}
                          />
                        </GridComponent>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </>
            ),
            [dataSource, isAuthenticated],
          )}
        </Box>
      </Stack>
      <CreateConsultantDialog isOpen={isCreateConsultantDialogOpen} setIsOpen={setIsCreateConsultantDialogOpen} />
    </Fragment>
  );
};

export default ConsultantsGrid;
