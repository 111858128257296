import { createContext, FC, useState, useContext, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { HP_LIGHT_THEME } from 'constants/themes';
import { hpYellowPrimary } from 'constants/themes/colors';

type ThemeContextType = {
  isDarkMode: boolean;
  toggleTheme: () => void;
};

export type AppThemeProviderProps = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

const AppThemeProvider: FC<AppThemeProviderProps> = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
    const savedTheme = localStorage.getItem('isDarkMode');
    return savedTheme ? JSON.parse(savedTheme) : false;
  });

  const lightTheme = HP_LIGHT_THEME;
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: hpYellowPrimary,
      },
    },
  });

  useEffect(() => {
    const darkModeStylesheet = document.createElement('link');
    darkModeStylesheet.rel = 'stylesheet';
    darkModeStylesheet.href = 'https://cdn.syncfusion.com/ej2/27.1.55/material3-dark.css';
    darkModeStylesheet.type = 'text/css';
    darkModeStylesheet.id = 'material3-dark';
    darkModeStylesheet.disabled = true;

    document.head.appendChild(darkModeStylesheet);
  }, []);

  useEffect(() => {
    const darkThemeStylesheet = document.getElementById('material3-dark') as HTMLLinkElement;
    if (darkThemeStylesheet) {
      darkThemeStylesheet.disabled = !isDarkMode;
    }

    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }

    localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
          <CssBaseline />
          {children}
        </MuiThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
};

export default AppThemeProvider;

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
