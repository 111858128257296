import AddIcon from '@mui/icons-material/Add';
import {
  Autocomplete,
  Grid,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { GanttComponent } from '@syncfusion/ej2-react-gantt';
import { GridComponent } from '@syncfusion/ej2-react-grids';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import { ROLES_BY_FULL_DISPLAY_NAME, ROLES_BY_NAME } from 'constants/roles';
import useProjects from 'hooks/useProjects/useProjects';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useCSVDownloader } from 'react-papaparse';
import { useRecoilState } from 'recoil';
import { selectedDistrictsState } from 'recoil/selectedDistrict/atom';
import { FormValues, ProjectImport, RoleImport, RolesFromCopiedProjectImport } from 'types';
import {
  Person,
  Project,
  ProjectRoleUpsertInput,
  useUpsertProjectMutation,
  useUpsertProjectRolesMutation,
} from 'types/generated/graphql';
import { calculateDuration, formatAddress, generateTransactionKey, serializeDate } from 'utils/general';

import ButtonPrimary from 'components/shared/ButtonPrimary';
import { CONSULTANT_PERSONNEL_ID } from 'constants/consultant';
import { ROLES_SUMMARY_DICTIONARY } from 'constants/rolesSummary';
import ButtonGroup from '../ButtonGroup';
import StyledStep from '../Step';
import CSVUploadDialog from './CSVUploadDialog';
import ProjectDetailsSummaryDialog from './ProjectDetailsSummaryDialog';
import RolesSummaryDialog from './RolesSummaryDialog';
import SelectProjectDialog from './SelectProjectDialog';

const csvUploadDialogStyle: SxProps<Theme> = {
  marginTop: '10px',
  marginBottom: '10px',
};

const iconButtonStyle: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: '#700006',
    color: 'white',
  },
  boxShadow: ' 0 2px 2px 0 rgba(0,0,0,0.14)',
});

type AddProjectDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  getGanttInstance?: () => GanttComponent | null | undefined;
  getGridInstance?: () => GridComponent | null | undefined;
  selectedEmployeeType: string | null;
  personList?: Person[];
  onDataChanged?: { (): Promise<void> };
};

const AddProjectDialog: FC<AddProjectDialogProps> = ({
  isOpen,
  setIsOpen,
  selectedEmployeeType,
  personList,
  onDataChanged,
}) => {
  const { displayToast } = useToast();
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const [isCancelConfirmationRendered, setIsCancelConfirmationRendered] = useState(false);
  const [activeStepIndex, setActiveStep] = useState(0);
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(false);
  const [projectDetails, setProjectDetails] = useState(null);
  const [projectImported, setProjectImported] = useState<any>({});
  const [rolesImported, setRolesImported] = useState<RoleImport[]>([]);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [selectedMethodValue, setSelectedMethodValue] = useState('');
  const [canContinueAfterProjectDetailsSummary, setCanContinueAfterProjectDetailsSummary] = useState<
    boolean | undefined
  >(true);
  const [canContinueAfterRolesSummary, setCanContinueAfterRolesSummary] = useState<boolean>(true);
  const [personnelList, setPersonnelList] = useState<any>();
  const [rolesFromCopiedProjectImport, setRolesFromCopiedProjectImport] = useState<RolesFromCopiedProjectImport[]>([]);
  const [shouldCreateProject, setShouldCreateProject] = useState<boolean>(false);
  const [existingProject, setExistingProject] = useState<any>({});
  const { CSVDownloader, Type } = useCSVDownloader();
  const csvUploadTemplate = [
    {
      'Project Full Name': '',
      'Employee Name': '',
      'Employee PR Number': '',
      'Role Position': '',
      'Role Begin Date': '',
      'Role End Date': '',
      Notes: '',
      'Focus Area': '',
      'Project Number': '',
      'Project Short Name': '',
      'Project Region': '',
      'Project Status': '',
      'Project Start Date': '',
      'Project End Date': '',
      'Project Description': '',
      'Project Street Address 1': '',
      'Project Street Address 2': '',
      'Project City': '',
      'Project State Abbr.': '',
      'Project Zip': '',
      'Vertical Markets': '',
      'Contract Type': '',
    },
  ];
  const allEmployees = personnelList
    ?.filter((personnel: Person) => personnel?.id !== CONSULTANT_PERSONNEL_ID)
    ?.map((personnel: Person) => {
      return {
        'Employee Name': personnel?.name?.lastCommaFirst,
        'Employee PR Number': personnel?.employeePrNumber,
        'Employee PR District': personnel?.prDistrict,
      };
    })
    .sort((a: { [x: string]: number }, b: { [x: string]: number }) =>
      a['Employee PR District'] > b['Employee PR District'] ? 1 : -1,
    );

  const defaultValues: FormValues = {
    method: '',
    projectSelection: {
      id: '',
      name: '',
    },
    shouldCreateProject: false,
    projectDetailsSummary: {
      name: '',
      number: '',
      shortName: '',
      districts: [],
      status: '',
      startDate: undefined,
      endDate: undefined,
      description: '',
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      state: '',
      zip: '',
      verticalMarkets: [],
      contractType: '',
    },
    rolesSummary: {
      roleQuantity: '',
    },
  };
  const methodsToCreateProject = ['CSV Import', 'Copy Existing Project', 'Create Blank Project'];
  const craftMethodToCreateProject = ['Create Blank Project'];

  const { projectListResult } = useProjects([], []);
  const [selectedDistricts] = useRecoilState(selectedDistrictsState);

  const listOfProjectNames = projectListResult?.items
    .filter(
      (project) =>
        !selectedDistricts?.length || selectedDistricts?.some((district) => project.districts.includes(district)),
    )
    .map((project) => {
      return { id: project.id, name: project.name };
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  const [addProject, { loading: isLoading }] = useUpsertProjectMutation();
  const [addProjectRoles] = useUpsertProjectRolesMutation();

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues });
  const selectedMethodToCreateProject = useWatch({ control, name: 'method' });

  const [
    projectDetailsSummaryName,
    projectDetailsSummaryStartDate,
    projectDetailsSummaryEndDate,
    projectDetailsSummaryVerticalMarkets,
    projectDetailsSummaryContractType,
    projectDetailsSummaryDistricts,
    projectDetailsSummaryStatus,
  ] = useWatch({
    control,
    name: [
      'projectDetailsSummary.name',
      'projectDetailsSummary.startDate',
      'projectDetailsSummary.endDate',
      'projectDetailsSummary.verticalMarkets',
      'projectDetailsSummary.contractType',
      'projectDetailsSummary.districts',
      'projectDetailsSummary.status',
    ],
  });

  const projectSelection = useWatch({ control, name: 'projectSelection' });

  const addProjectSteps: any = {
    'CSV Import': ['Upload CSV File', 'Project Details Summary', 'Project Roles Summary'],
    'Copy Existing Project': ['Copy Existing Project', 'Project Details Summary', 'Project Roles Summary'],
    'Create Blank Project': shouldCreateProject
      ? ['Search for Existing Project', 'Project Details Summary', 'Project Roles Summary']
      : ['Search for Existing Project', 'Project Roles Summary'],
  };

  const isComplete =
    activeStepIndex === addProjectSteps[selectedMethodValue ? selectedMethodValue : 'CSV Import'].length;

  const handleClose = () => {
    setIsOpen(false);
    reset(defaultValues);
    setIsDetailsPanelOpen(false);
    setProjectDetails(null);
    setActiveStep(0);
    setSelectedMethodValue('');
    setIsCancelConfirmationRendered(false);
    setUploadSuccess(false);
    setProjectImported({});
    setExistingProject({});
    setRolesImported([]);
    setShouldCreateProject(false);
  };

  const handleShouldClose = () => {
    if (activeStepIndex < 1 && selectedMethodToCreateProject !== null && selectedMethodToCreateProject !== '') {
      handleClose();
    } else {
      setIsCancelConfirmationRendered(true);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStepIndex) => prevActiveStepIndex + 1);
    if (selectedMethodValue === 'Create Blank Project' && shouldCreateProject && activeStepIndex === 2) {
      setRolesImported([
        {
          'Employee Id': null,
          'Role Position': '',
          'Role Begin Date': `${projectImported['Project Start Date']}`,
          'Role End Date': `${projectImported['Project End Date']}`,
          Quantity: '1',
          'Focus Area': '',
          Notes: '',
          notesFlag: false,
        },
      ]);
      setCanContinueAfterRolesSummary(false);
    }
    if (
      (selectedMethodValue === 'Copy Existing Project' && activeStepIndex === 2) ||
      (selectedMethodValue === 'Create Blank Project' &&
        ((shouldCreateProject && activeStepIndex === 3) || (!shouldCreateProject && activeStepIndex === 2)))
    ) {
      let rolesImportedCopy: RoleImport[] = rolesImported;
      const projectDuration = calculateDuration(
        new Date(projectImported['Project Start Date']),
        new Date(projectImported['Project End Date']),
      );

      rolesFromCopiedProjectImport.forEach((roleFromCopyImport) => {
        rolesImportedCopy.forEach((role) => {
          if (role['Role Position'] === roleFromCopyImport['Role Position']) {
            role['Role Begin Date'] =
              DateTime.fromISO(projectImported['Project Start Date'])
                .plus({ days: projectDuration * roleFromCopyImport.offsetStartDate })
                .toISODate() ?? '';
            role['Role End Date'] =
              DateTime.fromISO(projectImported['Project End Date'])
                .minus({ days: projectDuration * roleFromCopyImport.offsetEndDate })
                .toISODate() ?? '';
            role['Focus Area'] = roleFromCopyImport['Focus Area'];
            role['Notes'] = roleFromCopyImport['Notes'];
          }
        });
      });
      setCanContinueAfterRolesSummary(true);
      setRolesImported(rolesImportedCopy);
    }
    if (selectedMethodValue === 'CSV Import' && activeStepIndex === 2) {
      const propsToCheck: Array<keyof RoleImport> = ['Role Position', 'Role Begin Date', 'Role End Date'];
      const allPropsToCheckNotEmpty: boolean = rolesImported.every((role) =>
        propsToCheck.every((prop) => role[prop] !== ''),
      );
      setCanContinueAfterRolesSummary(allPropsToCheckNotEmpty);
    }
  };

  const handleBack = () => {
    if (activeStepIndex <= 1) {
      setUploadSuccess(false);
      setProjectImported({});
      setExistingProject({});
      setRolesImported([]);
      reset(defaultValues);
    }
    if (projectDetails) {
      setIsDetailsPanelOpen(false);
      setProjectDetails(null);
    } else {
      setActiveStep((prevActiveStepIndex) => prevActiveStepIndex - 1);
    }
    if (selectedMethodValue === 'Create Blank Project' && !shouldCreateProject) {
      setProjectImported({});
    }
  };

  const handleSelectedMethodChange = (value: string | null, formCallback: any) => {
    setSelectedMethodValue(value || '');
    formCallback(value);
  };

  const canSubmit = (() => {
    if (selectedMethodValue === 'CSV Import') {
      return !(errors.method || isLoading);
    } else if (selectedMethodValue === 'Copy Existing Project') {
      return !(errors.method || isLoading);
    } else {
      return !(errors.method || isLoading);
    }
  })();

  const canContinue = (() => {
    switch (activeStepIndex) {
      case 0:
        return selectedMethodToCreateProject !== null && selectedMethodToCreateProject !== '';
      case 1:
        return selectedMethodToCreateProject === 'CSV Import'
          ? uploadSuccess
          : selectedMethodToCreateProject === 'Copy Existing Project'
          ? projectSelection?.id && projectSelection.name !== ''
            ? true
            : false
          : selectedMethodToCreateProject === 'Create Blank Project'
          ? (projectSelection?.id && projectSelection.name !== '') || shouldCreateProject
            ? true
            : false
          : canContinueAfterProjectDetailsSummary;
      case 2:
        return (selectedMethodToCreateProject === 'Create Blank Project' && shouldCreateProject) ||
          selectedMethodToCreateProject !== 'Create Blank Project'
          ? canContinueAfterProjectDetailsSummary
          : canContinueAfterRolesSummary;
      case 3:
        return canContinueAfterRolesSummary;
      default:
        return false;
    }
  })();

  const addProjectDialogRefetch = async () => {
    if (onDataChanged) {
      await Promise.all([onDataChanged()]);
    }
  };

  const onSubmitSuccess: SubmitHandler<FormValues> = () => {
    const roles: ProjectRoleUpsertInput[] = [];

    rolesImported?.forEach((role: RoleImport) => {
      for (var i = 0; i < Number(role.Quantity); i++) {
        const convertedAndFormattedRoleEndDate = new Date(role['Role End Date'].replace(/-/g, '/'));
        const convertedAndFormattedStartDate = new Date(role['Role Begin Date'].replace(/-/g, '/'));
        roles.push({
          transactionKey: generateTransactionKey(),
          projectId: existingProject ? projectImported['Project ID'] : null,
          personId: role['Employee Id'] !== '' ? role['Employee Id'] : null,
          endDate: serializeDate(convertedAndFormattedRoleEndDate),
          roleName: ROLES_BY_FULL_DISPLAY_NAME[role['Role Position']].roleName ?? '',
          startDate: serializeDate(convertedAndFormattedStartDate),
          focusArea: role['Focus Area'] ?? '',
          notes: role['Notes'] ?? '',
          notesFlag: role.notesFlag,
        });
      }
    });

    const convertedAndFormattedProjectStartDate = new Date(projectImported['Project Start Date'].replace(/-/g, '/'));
    const convertedAndFormattedProjectEndDate = new Date(projectImported['Project End Date'].replace(/-/g, '/'));

    if (
      (projectSelection?.id === '' && projectSelection.name === '' && selectedMethodValue === 'Create Blank Project') ||
      selectedMethodValue !== 'Create Blank Project'
    ) {
      addProject({
        variables: {
          input: {
            transactionKey,
            name: projectImported['Project Full Name'],
            status: projectImported['Project Status'] ?? '',
            districts: projectImported['Project Region'] ?? [],
            verticalMarkets:
              projectImported['Vertical Markets']
                .map((verticalMarket: string) => verticalMarket.trim())
                .filter((verticalMarket: string) => verticalMarket !== '') ?? [],
            startDate: serializeDate(convertedAndFormattedProjectStartDate) ?? undefined,
            endDate: serializeDate(convertedAndFormattedProjectEndDate) ?? undefined,
            roles: roles,
            description: projectImported['Project Description'] ?? '',
            contractType: projectImported['Contract Type'] ?? '',
            location: {
              display:
                formatAddress(
                  projectImported['Project Street Address 1'],
                  projectImported['Project Street Address 2'],
                  projectImported['Project City'],
                  projectImported['Project State Abbr.'],
                  projectImported['Project Zip'],
                ) ?? '',
              streetAndNumber: projectImported['Project Street Address 1'] ?? '',
              streetDetail: projectImported['Project Street Address 2'] ?? '',
              city: projectImported['Project City'] ?? '',
              stateOrProvince: projectImported['Project State Abbr.'] ?? '',
              zipOrPostalCode: projectImported['Project Zip'] ?? '',
            },
            number: projectImported['Project Number'],
          },
        },
      })
        .then(() => {
          addProjectDialogRefetch();
          //TODO: Look into how to fix getting the project data back faster
          // ganttInstance ? ganttInstance?.resources.push(addNewProject) : gridInstance?.addRecord(addNewProject);
          //ganttInstance ? ganttInstance?.refresh() : gridInstance?.refresh();
          setTransactionKey(generateTransactionKey());
          handleClose();
          displayToast('The project was added successfully', 'success');
        })
        .catch(() => {
          displayToast(
            'Error: Something went wrong while trying to add a project. Please try again. If the problem persists, please contact support.',
            'error',
          );
        });
    } else {
      addProjectRoles({ variables: { input: roles } })
        .then(() => {
          addProjectDialogRefetch();
          handleClose();
          displayToast('The project roles were added successfully', 'success');
        })
        .catch(() => {
          displayToast(
            'Error: Something went wrong while trying to add project roles. Please try again. If the problem persists, please contact support.',
            'error',
          );
        });
    }
  };

  const handleProjectDetailsSummaryDialogInputChange = (value: string | string[] | Date, key: keyof ProjectImport) => {
    let projectImportedCopy: any = projectImported;
    projectImportedCopy[key] = value;
    setProjectImported(projectImportedCopy);
  };

  const handleRolesSummaryDialogInputChange = (
    value: string | boolean | null,
    required: boolean,
    index: number,
    key: keyof RoleImport,
  ) => {
    setCanContinueAfterRolesSummary(true);
    let rolesImportedCopy: any[] = [...rolesImported];
    rolesImportedCopy[index][key] =
      key === 'Employee Id'
        ? personnelList.find((personnel: Person) => {
            const personnelNameValueIndex = 0;
            const personnelValue = typeof value === 'string' ? value.split(' - ')[personnelNameValueIndex] : value;
            return personnel?.name?.lastCommaFirst === personnelValue;
          })?.id ?? null
        : value;
    setRolesImported(rolesImportedCopy);
    rolesImported?.forEach((role: RoleImport) => {
      Object.keys(role).forEach((roleKey: string) => {
        if (
          roleKey !== 'Employee Id' &&
          roleKey !== 'Quantity' &&
          roleKey !== 'Focus Area' &&
          roleKey !== 'Notes' &&
          roleKey !== 'notesFlag' &&
          !(role as RoleImport)[roleKey as keyof RoleImport]
        ) {
          setCanContinueAfterRolesSummary(false);
        }
      });

      if (canContinueAfterRolesSummary && key !== 'Employee Id') {
        required && value ? setCanContinueAfterRolesSummary(true) : setCanContinueAfterRolesSummary(false);
      }
    });
  };

  const handleRoleQuantity = (quantity: string, roleIndex: number) => {
    let rolesImportedCopy: RoleImport[] = rolesImported;
    rolesImportedCopy[roleIndex].Quantity = quantity;
    setRolesImported(rolesImportedCopy);
  };

  const AddRoleToRolesImported = () => {
    setRolesImported([
      ...rolesImported,
      {
        'Employee Id': null,
        'Role Position': '',
        'Role Begin Date': `${projectImported['Project Start Date']}`,
        'Role End Date': `${projectImported['Project End Date']}`,
        Quantity: '1',
        'Focus Area': '',
        Notes: '',
        notesFlag: false,
      },
    ]);
    setCanContinueAfterRolesSummary(false);
  };

  const DeleteRoleFromRolesImported = (roleIndex: number) => {
    setRolesImported([...rolesImported.slice(0, roleIndex), ...rolesImported.slice(roleIndex + 1)]);
    const tempRolesImportedArray = [...rolesImported.slice(0, roleIndex), ...rolesImported.slice(roleIndex + 1)];
    if (tempRolesImportedArray.length < 1) {
      setCanContinueAfterRolesSummary(false);
    } else {
      setCanContinueAfterRolesSummary(true);

      tempRolesImportedArray?.forEach((role: RoleImport) => {
        Object.keys(role).forEach((roleKey: string) => {
          if (roleKey !== 'Employee Id' && !(role as RoleImport)[roleKey as keyof RoleImport]) {
            setCanContinueAfterRolesSummary(false);
          } else {
            setCanContinueAfterRolesSummary(true);
          }
        });

        ROLES_SUMMARY_DICTIONARY.forEach((roleSummary) => {
          if (roleSummary.required && !role[roleSummary.key as keyof RoleImport]) {
            setCanContinueAfterRolesSummary(false);
          } else {
            setCanContinueAfterRolesSummary(true);
          }
        });
      });
    }
  };

  useEffect(() => {
    setCanContinueAfterProjectDetailsSummary(
      projectDetailsSummaryName &&
        projectDetailsSummaryStartDate &&
        projectDetailsSummaryEndDate &&
        (projectDetailsSummaryVerticalMarkets && projectDetailsSummaryVerticalMarkets?.length < 1 ? false : true) &&
        projectDetailsSummaryContractType &&
        projectDetailsSummaryStatus &&
        (projectDetailsSummaryDistricts?.length < 1 ? false : true)
        ? true
        : false,
    );
  }, [
    projectDetailsSummaryContractType,
    projectDetailsSummaryEndDate,
    projectDetailsSummaryName,
    projectDetailsSummaryStartDate,
    projectDetailsSummaryVerticalMarkets,
    projectDetailsSummaryDistricts,
    projectDetailsSummaryStatus,
  ]);

  useEffect(() => {
    setPersonnelList(personList ?? []);
  }, [personList]);

  useEffect(() => {
    if (selectedMethodValue === 'Create Blank Project' && shouldCreateProject) {
      setProjectImported({});
    }
  }, [selectedMethodValue, shouldCreateProject]);

  const getProjectFromResults: any = (project: Project) => {
    const result = {
      'Project ID': project.id,
      'Project Full Name': project.name,
      'Project Number': project.number,
      'Project Short Name': project.shortName,
      'Project Region': project.districts,
      'Project Status': project.status,
      'Project Start Date': project.startDate,
      'Project End Date': project.endDate,
      'Project Street Address 1': project.location?.streetAndNumber,
      'Project Street Address 2': project.location?.streetDetail,
      'Project City': project.location?.city,
      'Project State Abbr.': project.location?.stateOrProvince,
      'Project Zip': project.location?.zipOrPostalCode,
      'Vertical Markets': project.verticalMarkets,
      'Contract Type': project.contractType,
      'Project Description': project.description,
    };
    setExistingProject(result);
    return result;
  };

  useEffect(() => {
    let projectDuration: number;
    let foundRole: boolean = false;
    const rolesImportedArray: RoleImport[] = [];
    const projectSelected: any | undefined = projectListResult?.items?.find(
      (project) => project.id === projectSelection?.id,
    );

    projectSelected &&
      projectListResult?.items
        ?.find((project) => {
          if (project.id === projectSelection?.id) {
            projectDuration = calculateDuration(new Date(project.startDate), new Date(project.endDate));
            const foundProject = getProjectFromResults(project);
            if (selectedMethodValue === 'Create Blank Project' && !shouldCreateProject) {
              setProjectImported(foundProject);
            }
            return true;
          } else {
            setProjectImported({});
            return false;
          }
        })
        ?.roles.forEach((role: any) => {
          if (rolesFromCopiedProjectImport.length > 0) {
            rolesFromCopiedProjectImport.forEach((roleFromCopyImport) => {
              if (
                roleFromCopyImport['Role Position'] === role.roleFullDisplayName &&
                roleFromCopyImport &&
                roleFromCopyImport.totalDuration
              ) {
                roleFromCopyImport.totalDuration =
                  roleFromCopyImport?.totalDuration +
                  calculateDuration(new Date(role.startDate), new Date(role.endDate));
                foundRole = true;
              }
            });
            if (!foundRole) {
              rolesFromCopiedProjectImport.push({
                'Role Position': ROLES_BY_NAME[role.roleName].fullDisplayName,
                numberOfRoles: 0,
                totalDuration: calculateDuration(new Date(role.startDate), new Date(role.endDate)),
                offsetStartDate: 0,
                offsetEndDate: 0,
                'Focus Area': role.focusArea,
                Notes: role.notes,
                notesFlag: role.notesFlag,
              });
            }
            foundRole = false;
          } else {
            rolesFromCopiedProjectImport.push({
              'Role Position': ROLES_BY_NAME[role.roleName].fullDisplayName,
              numberOfRoles: 0,
              totalDuration: calculateDuration(new Date(role.startDate), new Date(role.endDate)),
              offsetStartDate: 0,
              offsetEndDate: 0,
              'Focus Area': role.focusArea,
              Notes: role.notes,
              notesFlag: role.notesFlag,
            });
          }
        });
    rolesFromCopiedProjectImport?.forEach((roleFromCopyImport) => {
      roleFromCopyImport.numberOfRoles = roleFromCopyImport?.totalDuration
        ? Math.ceil(roleFromCopyImport.totalDuration / projectDuration)
        : 0;
    });

    rolesFromCopiedProjectImport?.forEach((roleFromCopyProjectImport) => {
      const firstRoleOnCopyProject = projectSelected?.roles
        ?.sort((a: { startDate: number }, b: { startDate: number }) =>
          new Date(a?.startDate).getTime() < new Date(b?.startDate).getTime() ? -1 : 1,
        )
        ?.find((role: any) => {
          return role?.roleName === roleFromCopyProjectImport['Role Position'];
        });

      roleFromCopyProjectImport.offsetStartDate =
        calculateDuration(new Date(projectSelected?.startDate), new Date(firstRoleOnCopyProject?.startDate)) /
        projectDuration;

      const lastRoleOnCopyProject = projectSelected?.roles
        ?.sort((a: { endDate: number }, b: { endDate: number }) =>
          new Date(a?.endDate).getTime() > new Date(b?.endDate).getTime() ? -1 : 1,
        )
        ?.find((role: any) => {
          return role?.roleName === roleFromCopyProjectImport['Role Position'];
        });

      roleFromCopyProjectImport.offsetEndDate =
        calculateDuration(new Date(lastRoleOnCopyProject?.endDate), new Date(projectSelected?.endDate)) /
        projectDuration;
    });

    rolesFromCopiedProjectImport.forEach((roleFromCopyImport) => {
      for (let i = 0; i < roleFromCopyImport.numberOfRoles; i++) {
        rolesImportedArray.push({
          'Employee Id': null,
          'Role Position': roleFromCopyImport['Role Position'],
          'Role Begin Date': '',
          'Role End Date': '',
          'Focus Area': '',
          Notes: '',
          Quantity: '1',
          notesFlag: false,
        });
      }
    });

    setRolesImported(rolesImportedArray);
    setCanContinueAfterRolesSummary(false);
  }, [projectSelection, projectListResult, rolesFromCopiedProjectImport, shouldCreateProject, selectedMethodValue]);

  return (
    <>
      <StyledDialog
        data-testid="add-project-dialog"
        isOpen={isOpen}
        shouldShowCloseIconButton={!isCancelConfirmationRendered}
        handleClose={handleShouldClose}
        title={!isCancelConfirmationRendered ? 'Add Project' : 'Cancel Project Creation'}
        isLoading={isLoading}
        content={
          <form>
            {isCancelConfirmationRendered && (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography>{'Are you sure you want to cancel? All information will be lost.'}</Typography>
                </Grid>
                <Grid item>
                  <ButtonGroup
                    onClickAltAction={() => setIsCancelConfirmationRendered(false)}
                    labelAltAction={'Back'}
                    disabledAltAction={isLoading}
                    onClickMainAction={() => {
                      handleClose();
                    }}
                    labelMainAction={'Discard'}
                    disabledMainAction={isLoading}
                  />
                </Grid>
              </Grid>
            )}
            {!isCancelConfirmationRendered && (
              <Grid container justifyContent="center">
                {!projectDetails && (
                  <Grid item xs={12}>
                    <Stepper orientation="vertical" activeStep={activeStepIndex}>
                      <Step>
                        <StepLabel>Select A Method</StepLabel>
                        <StepContent>
                          <Typography component="div">
                            <StyledStep title={'Select method to add a project'}>
                              <Grid container direction="column" spacing={1}>
                                <Grid item xs={12}>
                                  <Controller
                                    name="method"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                      <Autocomplete
                                        id="method"
                                        size="small"
                                        options={
                                          selectedEmployeeType === 'Craft'
                                            ? craftMethodToCreateProject
                                            : methodsToCreateProject
                                        }
                                        filterSelectedOptions
                                        onChange={(_event, newValue) => handleSelectedMethodChange(newValue, onChange)}
                                        getOptionLabel={(option) => option}
                                        value={value ?? ''}
                                        isOptionEqualToValue={(option: string, value: string) => {
                                          return option === value;
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </StyledStep>
                          </Typography>
                        </StepContent>
                      </Step>
                      {selectedMethodValue !== '' &&
                        addProjectSteps[selectedMethodValue].map((label: string, index: number) => (
                          <Step key={index}>
                            <Grid container justifyContent={'space-between'}>
                              <Grid item xs={3}>
                                <StepLabel>{label}</StepLabel>
                              </Grid>
                              {(activeStepIndex === 3 ||
                                (((shouldCreateProject && activeStepIndex === 3) ||
                                  (!shouldCreateProject && activeStepIndex === 2)) &&
                                  selectedMethodValue === 'Create Blank Project')) &&
                                label === 'Project Roles Summary' && (
                                  <Grid item xs={9}>
                                    <Grid container alignItems={'center'} spacing={3}>
                                      <Grid item>
                                        <Tooltip title={'Add Role'}>
                                          <IconButton
                                            sx={{ root: iconButtonStyle }}
                                            size="small"
                                            onClick={() => AddRoleToRolesImported()}
                                          >
                                            <AddIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                      <Grid item>
                                        <Typography>Add Role</Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                            </Grid>
                            <StepContent>
                              <Typography component="div">
                                {selectedMethodValue === 'CSV Import' && (
                                  <>
                                    {activeStepIndex === 1 && (
                                      <Grid container>
                                        <Grid item xs={12} sx={csvUploadDialogStyle}>
                                          <CSVUploadDialog
                                            personnelList={personnelList}
                                            setUploadSuccess={setUploadSuccess}
                                            setValue={setValue}
                                            setRolesImported={setRolesImported}
                                            setProjectImported={setProjectImported}
                                            canContinueAfterRolesSummary={canContinueAfterRolesSummary}
                                            setCanContinueAfterRolesSummary={setCanContinueAfterRolesSummary}
                                          />
                                        </Grid>
                                        <Grid item xs={6} gap={'10px'}>
                                          <CSVDownloader
                                            type={Type.Link}
                                            filename={'CSV_Upload_Template'}
                                            bom={true}
                                            data={csvUploadTemplate}
                                          >
                                            <ButtonPrimary label={'Download A CSV Upload Template'}></ButtonPrimary>
                                          </CSVDownloader>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <CSVDownloader
                                            type={Type.Link}
                                            filename={'All_Personnel'}
                                            bom={true}
                                            data={allEmployees}
                                          >
                                            <ButtonPrimary label={'Download All Personnel'}></ButtonPrimary>
                                          </CSVDownloader>
                                        </Grid>
                                      </Grid>
                                    )}
                                    {activeStepIndex === 2 && (
                                      <ProjectDetailsSummaryDialog
                                        setValue={setValue}
                                        control={control}
                                        projectImported={projectImported}
                                        handleProjectDetailsSummaryDialogInputChange={
                                          handleProjectDetailsSummaryDialogInputChange
                                        }
                                      />
                                    )}
                                    {activeStepIndex === 3 &&
                                      rolesImported?.map((roleImported, index: number) => {
                                        return (
                                          <RolesSummaryDialog
                                            key={index}
                                            control={control}
                                            roleImported={roleImported}
                                            handleRolesSummaryDialogInputChange={handleRolesSummaryDialogInputChange}
                                            roleIndex={index}
                                            personnelList={personnelList}
                                            roleImportedLength={rolesImported?.length}
                                            DeleteRoleFromRolesImported={DeleteRoleFromRolesImported}
                                            handleRoleQuantity={handleRoleQuantity}
                                            selectedEmployeeType={selectedEmployeeType}
                                          />
                                        );
                                      })}
                                  </>
                                )}
                                {selectedMethodValue === 'Copy Existing Project' && (
                                  <>
                                    {activeStepIndex === 1 && (
                                      <SelectProjectDialog
                                        control={control}
                                        listOfProjectNames={listOfProjectNames}
                                        setValue={setValue}
                                        handleProjectDetailsSummaryDialogInputChange={
                                          handleProjectDetailsSummaryDialogInputChange
                                        }
                                        setRolesFromCopiedProjectImport={setRolesFromCopiedProjectImport}
                                      />
                                    )}
                                    {activeStepIndex === 2 && (
                                      <ProjectDetailsSummaryDialog
                                        setValue={setValue}
                                        control={control}
                                        projectImported={projectImported}
                                        handleProjectDetailsSummaryDialogInputChange={
                                          handleProjectDetailsSummaryDialogInputChange
                                        }
                                      />
                                    )}
                                    {activeStepIndex === 3 &&
                                      rolesImported?.map((roleImported, index: number) => {
                                        return (
                                          <RolesSummaryDialog
                                            key={index + 1}
                                            control={control}
                                            roleImported={roleImported}
                                            handleRolesSummaryDialogInputChange={handleRolesSummaryDialogInputChange}
                                            roleIndex={index}
                                            personnelList={personnelList}
                                            roleImportedLength={rolesImported?.length}
                                            DeleteRoleFromRolesImported={DeleteRoleFromRolesImported}
                                            handleRoleQuantity={handleRoleQuantity}
                                            selectedEmployeeType={selectedEmployeeType}
                                          />
                                        );
                                      })}
                                  </>
                                )}
                                {selectedMethodValue === 'Create Blank Project' && (
                                  <>
                                    {activeStepIndex === 1 && (
                                      <SelectProjectDialog
                                        control={control}
                                        listOfProjectNames={listOfProjectNames}
                                        setValue={setValue}
                                        handleProjectDetailsSummaryDialogInputChange={
                                          handleProjectDetailsSummaryDialogInputChange
                                        }
                                        selectedMethodValue={selectedMethodValue}
                                        shouldCreateProject={shouldCreateProject}
                                        setShouldCreateProject={setShouldCreateProject}
                                        projectSelection={projectSelection}
                                        setRolesFromCopiedProjectImport={setRolesFromCopiedProjectImport}
                                      />
                                    )}
                                    {shouldCreateProject && activeStepIndex === 2 && (
                                      <ProjectDetailsSummaryDialog
                                        setValue={setValue}
                                        control={control}
                                        projectImported={projectImported}
                                        handleProjectDetailsSummaryDialogInputChange={
                                          handleProjectDetailsSummaryDialogInputChange
                                        }
                                      />
                                    )}
                                    {((shouldCreateProject && activeStepIndex === 3) ||
                                      (!shouldCreateProject && activeStepIndex === 2)) &&
                                      rolesImported.map((roleImported: any, index: number) => {
                                        return (
                                          <RolesSummaryDialog
                                            key={index}
                                            control={control}
                                            roleImported={roleImported}
                                            handleRolesSummaryDialogInputChange={handleRolesSummaryDialogInputChange}
                                            roleIndex={index}
                                            personnelList={personnelList}
                                            roleImportedLength={rolesImported.length}
                                            DeleteRoleFromRolesImported={DeleteRoleFromRolesImported}
                                            handleRoleQuantity={handleRoleQuantity}
                                            selectedEmployeeType={selectedEmployeeType}
                                          />
                                        );
                                      })}
                                  </>
                                )}
                              </Typography>
                            </StepContent>
                          </Step>
                        ))}
                    </Stepper>
                  </Grid>
                )}
              </Grid>
            )}
          </form>
        }
        actions={
          !isCancelConfirmationRendered && (
            <>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <StyledButtonSecondary label={'Cancel'} disabled={isLoading} onClick={handleShouldClose} />
                </Grid>
                <Grid item>
                  <Grid container justifyContent="flex-end" direction="row" spacing={2}>
                    <Grid item>
                      {activeStepIndex !== 0 && (
                        <StyledButtonPrimary
                          disabled={isLoading || isDetailsPanelOpen}
                          label={'Back'}
                          onClick={handleBack}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      {!isComplete && (
                        <StyledButtonPrimary
                          label={'Next'}
                          disabled={!canContinue || isDetailsPanelOpen}
                          onClick={handleNext}
                        />
                      )}
                      {isComplete && (
                        <StyledButtonPrimary
                          label={'Submit'}
                          disabled={!canContinue || !canSubmit}
                          onClick={handleSubmit(onSubmitSuccess)}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )
        }
      />
    </>
  );
};

export default AddProjectDialog;
