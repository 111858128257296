import { CONTRACT_TYPES } from 'constants/contractTypes';
import { DISTRICTS } from 'constants/districts';
import { PROJECT_DETAILS_SUMMARY_DICTIONARY } from 'constants/projectDetailsSummary';
import { ACTIVE_STATUS, ANTICIPATED_STATUS } from 'constants/projectStatus';
import { VERTICAL_MARKETS } from 'constants/verticalMarkets';
import { FC, useEffect, useState } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { FormValues, ProjectDetailsSummary, ProjectImport } from 'types';

import { Autocomplete, FormHelperText, Grid, InputLabel, SxProps, TextField, Theme } from '@mui/material';

const districtsStyle: SxProps<Theme> = {
  marginTop: '8px',
  height: '35%',
  width: '100%',
};

const verticalMarketsStyle: SxProps<Theme> = {
  marginTop: '8px',
  height: '35%',
  width: '100%',
};

const formHelperErrorTextStyle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error.main,
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  marginTop: '4px',
  marginRight: '14px',
  marginLeft: '14px',
});

type ProjectDetailsSummaryDialogProps = {
  control: Control<FormValues>;
  projectImported: any;
  setValue: UseFormSetValue<FormValues>;
  handleProjectDetailsSummaryDialogInputChange: (x: string | string[] | Date, y: keyof ProjectImport) => void;
};

type ProjectDetailsSummaryInputFieldsProps = {
  control: Control<FormValues>;
  projectDetailsSummary: ProjectDetailsSummary;
  projectImported: any;
  setValue: UseFormSetValue<FormValues>;
  handleProjectDetailsSummaryDialogInputChange: (x: string | string[] | Date, y: keyof ProjectImport) => void;
};

const ProjectDetailsSummaryInputFields: FC<ProjectDetailsSummaryInputFieldsProps> = ({
  control,
  projectDetailsSummary,
  projectImported,
  setValue,
  handleProjectDetailsSummaryDialogInputChange,
}) => {
  const [projectDetailsInputValue, setProjectDetailsInputValue] = useState<string | string[] | Date | null>(null);
  const [projectDetailsVerticalMarketsInputValue, setProjectDetailsVerticalMarketsInputValue] = useState<string[]>([]);
  const [projectDetailsContractTypeInputValue, setProjectDetailsContractTypeInputValue] = useState<
    string | undefined
  >();
  const [projectDetailsStatusInputValue, setProjectDetailsStatusInputValue] = useState<string | undefined>();
  const [selectedProjectDistricts, setSelectedProjectDistricts] = useState<string[]>([]);

  useEffect(() => {
    if (
      projectDetailsSummary.name === 'projectDetailsSummary.verticalMarkets' &&
      projectImported[projectDetailsSummary.key as keyof ProjectImport]
    ) {
      setProjectDetailsVerticalMarketsInputValue(
        projectImported[projectDetailsSummary.key as keyof ProjectImport]
          .map((projectVerticalMarket: string) => {
            return VERTICAL_MARKETS.find((verticalMarket: string) => {
              return projectVerticalMarket === verticalMarket;
            });
          })
          .filter((verticalMarket: string | undefined) => {
            return typeof verticalMarket === 'string' && verticalMarket !== '';
          }),
      );
    } else if (
      projectDetailsSummary.name === 'projectDetailsSummary.status' &&
      projectImported[projectDetailsSummary.key as keyof ProjectImport]
    ) {
      setProjectDetailsStatusInputValue(
        [...ACTIVE_STATUS, ...ANTICIPATED_STATUS].find((status: string) => {
          return projectImported[projectDetailsSummary.key as keyof ProjectImport].trim() === status;
        }),
      );
    } else if (
      projectDetailsSummary.name === 'projectDetailsSummary.contractType' &&
      projectImported[projectDetailsSummary.key as keyof ProjectImport]
    ) {
      setProjectDetailsContractTypeInputValue(
        CONTRACT_TYPES.find((contractType: string) => {
          return projectImported[projectDetailsSummary.key as keyof ProjectImport].trim() === contractType;
        }),
      );
    } else {
      projectDetailsSummary.name !== 'projectDetailsSummary.startDate' &&
      projectDetailsSummary.name !== 'projectDetailsSummary.endDate' &&
      projectDetailsSummary.key !== 'Project Region'
        ? setProjectDetailsInputValue(projectImported[projectDetailsSummary.key as keyof ProjectImport])
        : projectDetailsSummary.key !== 'Project Region'
        ? projectImported[projectDetailsSummary.key as keyof ProjectImport]
          ? setProjectDetailsInputValue(
              new Date(projectImported[projectDetailsSummary.key as keyof ProjectImport]).toISOString().split('T')[0],
            )
          : setProjectDetailsInputValue(null)
        : projectImported[projectDetailsSummary.key as keyof ProjectImport]
        ? setSelectedProjectDistricts(projectImported[projectDetailsSummary.key as keyof ProjectImport])
        : setSelectedProjectDistricts([]);
    }
  }, [projectDetailsSummary.key, projectDetailsSummary.name, projectImported]);

  return (
    <Grid
      item
      xs={projectDetailsSummary.xs}
      sx={{ display: projectDetailsSummary.key === 'Project ID' ? 'none' : 'inline' }}
    >
      <Controller
        name={projectDetailsSummary.name as keyof FormValues}
        control={control}
        render={() => (
          <>
            <InputLabel required={projectDetailsSummary.required}>{projectDetailsSummary.label}</InputLabel>
            {projectDetailsSummary.key !== 'Project Region' &&
            projectDetailsSummary.key !== 'Vertical Markets' &&
            projectDetailsSummary.key !== 'Contract Type' &&
            projectDetailsSummary.key !== 'Project Status' ? (
              <TextField
                error={
                  projectDetailsSummary.required
                    ? !projectImported[projectDetailsSummary.key as keyof ProjectImport]
                    : false
                }
                helperText={
                  projectDetailsSummary.required
                    ? projectImported[projectDetailsSummary.key as keyof ProjectImport]
                      ? ''
                      : 'Field Required'
                    : ''
                }
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                value={projectDetailsInputValue ?? ''}
                onChange={(event) => {
                  const changedTextFieldValue = event.target.value;
                  setProjectDetailsInputValue(changedTextFieldValue);
                  setValue(
                    projectDetailsSummary.name as keyof FormValues,
                    changedTextFieldValue as keyof ProjectImport,
                  );
                  handleProjectDetailsSummaryDialogInputChange(
                    changedTextFieldValue,
                    projectDetailsSummary.key as keyof ProjectImport,
                  );
                }}
                type={
                  projectDetailsSummary.name !== 'projectDetailsSummary.startDate' &&
                  projectDetailsSummary.name !== 'projectDetailsSummary.endDate'
                    ? 'string'
                    : 'date'
                }
              />
            ) : projectDetailsSummary.key === 'Vertical Markets' ? (
              <>
                <Autocomplete
                  sx={verticalMarketsStyle}
                  multiple
                  disableCloseOnSelect
                  id="verticalMarkets"
                  size="small"
                  options={VERTICAL_MARKETS}
                  getOptionLabel={(value) => value}
                  filterSelectedOptions
                  onChange={(_event, value) => {
                    setProjectDetailsVerticalMarketsInputValue(value);
                    setValue(projectDetailsSummary.name as keyof FormValues, value as unknown as keyof ProjectImport);
                    handleProjectDetailsSummaryDialogInputChange(
                      value,
                      projectDetailsSummary.key as keyof ProjectImport,
                    );
                  }}
                  value={projectDetailsVerticalMarketsInputValue ?? []}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option === value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={projectDetailsVerticalMarketsInputValue.length < 1}
                      {...params}
                      variant="outlined"
                    />
                  )}
                />
                {projectDetailsVerticalMarketsInputValue.length < 1 && (
                  <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>
                )}
              </>
            ) : projectDetailsSummary.key === 'Contract Type' ? (
              <>
                <Autocomplete
                  sx={verticalMarketsStyle}
                  id="contractType"
                  size="small"
                  options={CONTRACT_TYPES}
                  getOptionLabel={(value) => value}
                  filterSelectedOptions
                  onChange={(_event, value) => {
                    setProjectDetailsContractTypeInputValue(value ?? '');
                    setValue(projectDetailsSummary.name as keyof FormValues, value as unknown as keyof ProjectImport);
                    handleProjectDetailsSummaryDialogInputChange(
                      value ?? '',
                      projectDetailsSummary.key as keyof ProjectImport,
                    );
                  }}
                  value={projectDetailsContractTypeInputValue ?? ''}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option === value;
                  }}
                  renderInput={(params) => (
                    <TextField error={!projectDetailsContractTypeInputValue} {...params} variant="outlined" />
                  )}
                />
                {!projectDetailsContractTypeInputValue && (
                  <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>
                )}
              </>
            ) : projectDetailsSummary.key === 'Project Status' ? (
              <>
                <Autocomplete
                  sx={verticalMarketsStyle}
                  id="projectStatus"
                  size="small"
                  options={[...ACTIVE_STATUS, ...ANTICIPATED_STATUS]}
                  getOptionLabel={(value) => value}
                  filterSelectedOptions
                  onChange={(_event, value) => {
                    setProjectDetailsStatusInputValue(value ?? '');
                    setValue(projectDetailsSummary.name as keyof FormValues, value as unknown as keyof ProjectImport);
                    handleProjectDetailsSummaryDialogInputChange(
                      value ?? '',
                      projectDetailsSummary.key as keyof ProjectImport,
                    );
                  }}
                  value={projectDetailsStatusInputValue ?? ''}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option === value;
                  }}
                  renderInput={(params) => (
                    <TextField error={!projectDetailsStatusInputValue} {...params} variant="outlined" />
                  )}
                />
                {!projectDetailsStatusInputValue && (
                  <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>
                )}
              </>
            ) : (
              <>
                <Autocomplete
                  sx={districtsStyle}
                  multiple
                  disableCloseOnSelect
                  size="small"
                  options={DISTRICTS.map((district) => {
                    return `${district.number} - ${district.name}`;
                  })}
                  getOptionLabel={(value) => value}
                  filterSelectedOptions
                  onChange={(_event, value) => {
                    const changedDistrictValue = value.map((district) => district.split(' - ')[0]);
                    handleProjectDetailsSummaryDialogInputChange(
                      changedDistrictValue,
                      projectDetailsSummary.key as keyof ProjectImport,
                    );
                    setValue(
                      projectDetailsSummary.name as keyof FormValues,
                      changedDistrictValue as unknown as keyof ProjectImport,
                    );
                    setSelectedProjectDistricts(changedDistrictValue);
                  }}
                  value={selectedProjectDistricts}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option === value;
                  }}
                  renderInput={(params) => (
                    <TextField error={selectedProjectDistricts.length < 1} {...params} variant="outlined" />
                  )}
                />
                {selectedProjectDistricts.length < 1 && (
                  <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>
                )}
              </>
            )}
          </>
        )}
      />
    </Grid>
  );
};

const ProjectDetailsSummaryDialog: FC<ProjectDetailsSummaryDialogProps> = ({
  control,
  projectImported,
  setValue,
  handleProjectDetailsSummaryDialogInputChange,
}) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ sm: 2 }}>
      {PROJECT_DETAILS_SUMMARY_DICTIONARY.map((projectDetailsSummary: ProjectDetailsSummary, index: number) => {
        return (
          <ProjectDetailsSummaryInputFields
            setValue={setValue}
            key={index}
            control={control}
            projectDetailsSummary={projectDetailsSummary}
            projectImported={projectImported}
            handleProjectDetailsSummaryDialogInputChange={handleProjectDetailsSummaryDialogInputChange}
          />
        );
      })}
    </Grid>
  );
};

export default ProjectDetailsSummaryDialog;
