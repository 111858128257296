import { Role, ROLES_BY_FULL_DISPLAY_NAME, ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE } from 'constants/roles';
import { ROLES_SUMMARY_DICTIONARY } from 'constants/rolesSummary';
import { FC, useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { FormValues, RoleImport, RoleSummary } from 'types';
import { Person } from 'types/generated/graphql';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {
  Autocomplete,
  Checkbox,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  SxProps,
  TextField,
  Theme,
  Tooltip,
} from '@mui/material';

const formHelperErrorTextStyle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error.main,
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  marginTop: '4px',
  marginRight: '14px',
  marginLeft: '14px',
});

const quantityInputStyle: SxProps<Theme> = {
  marginTop: '8px',
  height: '60%',
};

const rolePositionStyle: SxProps<Theme> = {
  marginTop: '8px',
  width: '100%',
};

const roleSummaryContainerStyle: SxProps<Theme> = {
  marginBottom: '50px',
};

type RolesSummaryDialogProps = {
  control: Control<FormValues>;
  roleImported: RoleImport;
  handleRolesSummaryDialogInputChange: (a: string | null, b: boolean, c: number, d: keyof RoleImport) => void;
  roleIndex: number;
  personnelList: Person[];
  roleImportedLength: number | undefined;
  DeleteRoleFromRolesImported: (x: number) => void;
  handleRoleQuantity: (x: string, y: number) => void;
  selectedEmployeeType: string | null;
};

type RoleSummaryInputFieldProps = {
  control: Control<FormValues>;
  roleSummary: RoleSummary;
  roleImported: RoleImport;
  handleRolesSummaryDialogInputChange: (a: string | null, b: boolean, c: number, d: keyof RoleImport) => void;
  roleIndex: number;
  personnelList: Person[];
  roleEmployeeName: string | undefined | null;
  setRoleEmployeeName: (x: string | undefined | null) => void;
  selectedEmployeeType: string | null;
};

const RoleSummaryInputFields: FC<RoleSummaryInputFieldProps> = ({
  control,
  roleSummary,
  roleImported,
  handleRolesSummaryDialogInputChange,
  roleIndex,
  personnelList,
  roleEmployeeName,
  setRoleEmployeeName,
  selectedEmployeeType,
}) => {
  const [roleInputValue, setRoleInputValue] = useState<string | boolean | null>(roleImported[roleSummary.key]);
  const [rolePosition, setRolePosition] = useState<Role | undefined | null>(null);

  const personnelName =
    personnelList.find((personnel: Person) => {
      return personnel.id === roleImported[roleSummary.key];
    })?.name?.lastCommaFirst ?? '';

  const personnelJobTitleAbbr =
    personnelList.find((personnel: Person) => {
      return personnel.id === roleImported[roleSummary.key];
    })?.prJobTitleAbbreviation ?? '';

  useEffect(() => {
    roleSummary.key === 'Role Position'
      ? setRolePosition(ROLES_BY_FULL_DISPLAY_NAME[roleImported[roleSummary.key]])
      : roleSummary.key === 'Employee Id'
      ? setRoleEmployeeName(personnelName ? `${personnelName} - ${personnelJobTitleAbbr}` : '')
      : roleSummary.key === 'Role Begin Date' || roleSummary.key === 'Role End Date'
      ? roleImported[roleSummary.key]
        ? setRoleInputValue(new Date(roleImported[roleSummary.key]).toISOString().split('T')[0])
        : setRoleInputValue(roleImported[roleSummary.key])
      : setRoleInputValue(roleImported[roleSummary.key]);
  }, [personnelJobTitleAbbr, personnelName, roleImported, roleSummary.key, setRoleEmployeeName]);

  return (
    <Grid item xs={roleSummary.xs}>
      <Controller
        name={roleSummary.name as keyof FormValues}
        control={control}
        render={() => (
          <>
            <InputLabel required={roleSummary.required}>{roleSummary.label}</InputLabel>
            {roleSummary.key !== 'Employee Id' && roleSummary.key !== 'Role Position' ? (
              <TextField
                error={roleSummary.required ? !roleInputValue : false}
                helperText={roleSummary.required && !roleInputValue ? 'Field Required' : ''}
                required={roleSummary.required}
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                value={roleInputValue ?? ''}
                onChange={(event) => {
                  const value = event.target.value;
                  handleRolesSummaryDialogInputChange(value, roleSummary.required, roleIndex, roleSummary.key);
                  setRoleInputValue(value);
                }}
                type={
                  roleSummary.name !== 'rolesSummary.startDate' && roleSummary.name !== 'rolesSummary.endDate'
                    ? 'string'
                    : 'date'
                }
              />
            ) : roleSummary.key === 'Employee Id' ? (
              <Autocomplete
                sx={rolePositionStyle}
                size="small"
                options={personnelList
                  ?.sort((a: Person, b: Person) =>
                    a.name &&
                    b.name &&
                    a.name.lastCommaFirst &&
                    b.name.lastCommaFirst &&
                    a.name?.lastCommaFirst < b.name?.lastCommaFirst
                      ? -1
                      : 1,
                  )
                  .map((personnel: Person) => {
                    const personnelName = personnel.name?.lastCommaFirst ?? '';
                    return `${personnelName} - ${personnel.prJobTitleAbbreviation}` ?? '';
                  })}
                getOptionLabel={(value) => value}
                filterSelectedOptions
                onChange={(_event, value) => {
                  handleRolesSummaryDialogInputChange(value, roleSummary.required, roleIndex, roleSummary.key);
                  setRoleEmployeeName(value);
                }}
                value={roleEmployeeName ?? ''}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option === value;
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
            ) : (
              <>
                <Autocomplete
                  sx={rolePositionStyle}
                  size="small"
                  options={ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE(selectedEmployeeType === 'Craft')}
                  getOptionLabel={(value) => value}
                  filterSelectedOptions
                  onChange={(_event, value) => {
                    roleSummary.key === 'Role Position' && setRolePosition(ROLES_BY_FULL_DISPLAY_NAME[value ?? '']);
                    handleRolesSummaryDialogInputChange(value, roleSummary.required, roleIndex, roleSummary.key);
                  }}
                  value={rolePosition?.fullDisplayName ?? null}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option === value;
                  }}
                  renderInput={(params) => <TextField error={!rolePosition} {...params} variant="outlined" />}
                />
                {!rolePosition && <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>}
              </>
            )}
          </>
        )}
      />
    </Grid>
  );
};

const RolesSummaryDialog: FC<RolesSummaryDialogProps> = ({
  control,
  roleImported,
  roleImportedLength,
  handleRolesSummaryDialogInputChange,
  roleIndex,
  personnelList,
  DeleteRoleFromRolesImported,
  handleRoleQuantity,
  selectedEmployeeType,
}) => {
  const [roleQuantity, setRoleQuantity] = useState<string>(roleImported?.Quantity ?? '1');
  const [roleEmployeeName, setRoleEmployeeName] = useState<string | undefined | null>(null);
  const [notesFlag, setNotesFlag] = useState<boolean>(roleImported.notesFlag);
  const [focusArea, setFocusArea] = useState<string>(roleImported['Focus Area']);
  const [notes, setNotes] = useState<string>(roleImported['Notes']);
  const personnelListCopy = [...personnelList];

  useEffect(() => {
    setRoleQuantity(roleImported?.Quantity ?? '1');
    setFocusArea(roleImported['Focus Area']);
    setNotes(roleImported['Notes']);
  }, [roleImported]);

  return (
    <Grid container columnSpacing={{ sm: 2 }}>
      <Grid item xs={1}>
        <Grid container>
          <Grid item xs={12}>
            <InputLabel>Qty</InputLabel>
            <Controller
              name={'rolesSummary.roleQuantity'}
              control={control}
              render={({ field: { onChange, ref } }) => (
                <NumericFormat
                  sx={quantityInputStyle}
                  customInput={TextField}
                  InputLabelProps={{ style: { height: '65%' } }}
                  InputProps={{ style: { height: '65%' } }}
                  inputProps={{
                    style: { height: '65%' },
                  }}
                  value={roleEmployeeName ? '1' : roleQuantity}
                  valueIsNumericString={true}
                  margin="normal"
                  variant="outlined"
                  color="primary"
                  decimalScale={0}
                  prefix={''}
                  suffix={''}
                  allowNegative={false}
                  error={roleQuantity === ''}
                  required={true}
                  onValueChange={(event: { value: string }) => {
                    const value = Number(event.value) < 1 ? '' : event.value;
                    onChange(event);
                    setRoleQuantity(value);
                    handleRoleQuantity(value === '' ? '1' : value, roleIndex);
                  }}
                  disabled={roleEmployeeName ? true : false}
                  inputRef={ref}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip title={'Delete Role'}>
              <IconButton
                onClick={() => {
                  DeleteRoleFromRolesImported(roleIndex);
                }}
              >
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <Grid
          sx={roleImportedLength && roleImportedLength - 1 !== roleIndex ? roleSummaryContainerStyle : undefined}
          container
          columnSpacing={{ sm: 2 }}
        >
          {ROLES_SUMMARY_DICTIONARY.map((roleSummary: RoleSummary, index: number) => {
            return (
              <RoleSummaryInputFields
                key={index}
                roleIndex={roleIndex}
                control={control}
                roleSummary={roleSummary}
                roleImported={roleImported}
                handleRolesSummaryDialogInputChange={handleRolesSummaryDialogInputChange}
                personnelList={personnelListCopy}
                roleEmployeeName={roleEmployeeName}
                setRoleEmployeeName={setRoleEmployeeName}
                selectedEmployeeType={selectedEmployeeType}
              />
            );
          })}
          <Grid item xs={6}>
            <>
              <InputLabel>Focus Area</InputLabel>
              <TextField
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                value={focusArea}
                onChange={(event) => {
                  const value = event.target.value;
                  setFocusArea(value);
                  roleImported['Focus Area'] = value;
                }}
                type={'string'}
              />
            </>
          </Grid>
          <Grid item xs={4}>
            <>
              <InputLabel>Notes</InputLabel>
              <TextField
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                value={notes}
                onChange={(event) => {
                  const value = event.target.value;
                  setNotes(value);
                  roleImported['Notes'] = value;
                }}
                type={'string'}
              />
            </>
          </Grid>
          <Grid item xs={2}>
            <InputLabel>Notes Flag </InputLabel>
            <Checkbox
              checked={notesFlag}
              onChange={(e) => {
                setNotesFlag(e.target.checked);
                roleImported.notesFlag = e.target.checked;
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RolesSummaryDialog;
