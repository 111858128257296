import { Link as RouterLink } from 'react-router-dom';

import { Grid, List, ListItemButton, ListItemText, Link as MuiLink, SxProps, Theme, Typography } from '@mui/material';

const listItemTextStyle: SxProps<Theme> = (theme: Theme) => ({
  fontSize: '0.875rem !important',
  maxWidth: 240,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  '&hover': { backgroundColor: theme.palette.secondary.dark },
});

const containerStyle: SxProps<Theme> = {
  width: 300,
};

const masterPageRouteLink = '/projects';
const personnelListPageRouteLink = '/personnel';
const scenarioPageRouteLink = '/reporting';
const consultantsPageRouteLink = '/consultants';
const staffingPlanPageRouteLink = '/staffing-plan';

const SideNavigationMenuList = ({ closeDrawer }: any) => {
  return (
    <Grid container justifyContent="center" sx={containerStyle}>
      <Grid item>
        <List component="nav">
          <MuiLink component={RouterLink} to={masterPageRouteLink} onClick={closeDrawer} underline="none">
            <ListItemButton style={{ width: 300 }}>
              <ListItemText>
                <Typography sx={listItemTextStyle} color="textPrimary">
                  {'Project List'}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </MuiLink>
          <MuiLink component={RouterLink} to={personnelListPageRouteLink} onClick={closeDrawer} underline="none">
            <ListItemButton style={{ width: 300 }}>
              <ListItemText>
                <Typography sx={listItemTextStyle} color="textPrimary">
                  {'Personnel List'}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </MuiLink>
          <MuiLink component={RouterLink} to={consultantsPageRouteLink} onClick={closeDrawer} underline="none">
            <ListItemButton style={{ width: 300 }}>
              <ListItemText>
                <Typography sx={listItemTextStyle} color="textPrimary">
                  {'Consultants List'}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </MuiLink>
          <MuiLink component={RouterLink} to={scenarioPageRouteLink} onClick={closeDrawer} underline="none">
            <ListItemButton style={{ width: 300 }}>
              <ListItemText>
                <Typography sx={listItemTextStyle} color="textPrimary">
                  {'Reporting'}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </MuiLink>
          <MuiLink component={RouterLink} to={staffingPlanPageRouteLink} onClick={closeDrawer} underline="none">
            <ListItemButton style={{ width: 300 }}>
              <ListItemText>
                <Typography sx={listItemTextStyle} color="textPrimary">
                  {'Staffing Plan'}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </MuiLink>
        </List>
      </Grid>
    </Grid>
  );
};

export default SideNavigationMenuList;
