import {
  Box,
  Chip,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import useRoles from 'hooks/useRoles';
import { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

type TestByRoleDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  downgradedRoles: string[];
};

type TestByRoleDialogInput = {
  downgradedRoles: string[];
};

const chipSetBoxStyle: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'nowrap',
  gap: 0.5,
};

const chipSetStyle: SxProps<Theme> = {
  minWidth: '410px',
};

const chipStyle: SxProps<Theme> = {
  borderRadius: '12px !important',
};

const TestByRoleDialog: FC<TestByRoleDialogProps> = ({ isOpen, setIsOpen, downgradedRoles }) => {
  const { handleSubmit, control } = useForm<TestByRoleDialogInput>();
  const [selectedDowngradedRoles, setSelectedDowngradedRoles] = useState<string[]>(downgradedRoles);

  const { roles } = useRoles();

  const handleClose = () => {
    setIsOpen(false);
    setSelectedDowngradedRoles(downgradedRoles);
  };

  const onSubmitSuccess: SubmitHandler<TestByRoleDialogInput> = () => {
    window.sessionStorage.setItem('downgradedRoles', selectedDowngradedRoles.join(','));
    setIsOpen(false);
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      maxWidth="sm"
      title={'Test by Role(s)'}
      content={
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              The following user roles have been designated with permissioned access rights to the application. Toggle
              to selectively test by role(s).
            </Typography>
          </Grid>
          <Controller
            name="downgradedRoles"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                multiple
                value={selectedDowngradedRoles ?? []}
                onChange={(event: SelectChangeEvent<string[]>) => {
                  const value = event.target.value;
                  const valueAsArray = typeof value === 'string' ? value.split(',') : value;
                  setSelectedDowngradedRoles(valueAsArray);
                  onChange(event);
                }}
                variant="outlined"
                sx={chipSetStyle}
                displayEmpty
                renderValue={() => {
                  return (
                    <Box sx={chipSetBoxStyle}>
                      {[...selectedDowngradedRoles].sort().map((role, index) => (
                        <Chip sx={chipStyle} key={index} label={role} />
                      ))}
                    </Box>
                  );
                }}
              >
                {roles.map((role: string) => (
                  <MenuItem key={`districtFilterOption${role}`} value={role}>
                    <ListItemText primary={role} />
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary disabled={false} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={'submit'}
                  type="submit"
                  onClick={handleSubmit(onSubmitSuccess)}
                  disabled={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

export default TestByRoleDialog;
