import { useApolloClient } from '@apollo/client';
import { Autocomplete, Box, FormHelperText, Grid, SxProps, TextField, Theme, Typography } from '@mui/material';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import { ROLES_BY_ABBREVIATION, ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE } from 'constants/roles';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { FC, Fragment, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Project, ProjectRole, useUpsertProjectRoleMutation } from 'types/generated/graphql';
import { convertDateToUseableISOString, generateTransactionKey } from 'utils/general';

type TransferPersonnelDialogProps = {
  isTransferPersonnelOpen: boolean;
  setIsTransferPersonnelOpen: (x: boolean) => void;
  projectListResult: Project[] | undefined;
  transferPersonnelRole: ProjectRole;
  selectedEmployeeType: string | null;
};

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1),
});

const formStyle: SxProps<Theme> = {
  width: '100%',
};

const formHelperErrorTextStyle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error.main,
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  marginTop: '4px',
  marginRight: '14px',
  marginLeft: '14px',
});

const formRowStyle: SxProps<Theme> = {
  flexDirection: 'row',
  display: 'flex',
  marginBottom: '20px',
};

const removeTopMargin: SxProps<Theme> = {
  marginTop: '0px',
};

type TransferPersonnelFormValues = {
  project: {
    name: string;
    id: string;
  };
  role: string;
  personnel: string;
  startDate: Date;
  endDate: Date | undefined;
  unfilledRole?: ProjectRole | null;
};

const moveRoleToFront = (roles: string[], role: string) => {
  const roleIndex = roles.indexOf(role);

  if (roleIndex > -1) {
    roles.splice(roleIndex, 1);
    roles.unshift(role);
  }
  return roles;
};

const TransferPersonnelDialog: FC<TransferPersonnelDialogProps> = ({
  isTransferPersonnelOpen,
  setIsTransferPersonnelOpen,
  projectListResult,
  transferPersonnelRole,
  selectedEmployeeType,
}) => {
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [unfilledRolesOptions, setUnfilledRolesOptions] = useState<ProjectRole[]>([]);
  const [rolesOptions, setRolesOptions] = useState<string[]>([]);
  const transferRoleStartDate = DateTime.fromJSDate(new Date(transferPersonnelRole?.endDate)).plus({ days: 1 });
  const personnelFirstAvailableDate = new Date(transferPersonnelRole?.person?.earliestDateAvailable);
  const [addProjectRole, { loading: isLoading }] = useUpsertProjectRoleMutation();
  const [upsertProjectRole] = useUpsertProjectRoleMutation();

  const defaultValues = {
    project: { id: transferPersonnelRole?.project?.id ?? '', name: transferPersonnelRole?.project?.name ?? '' },
    role: transferPersonnelRole?.roleName ?? '',
    personnel: transferPersonnelRole?.person?.name?.lastCommaFirst ?? '',
    unfilledRole: null,
  };
  const { handleSubmit, control, reset, setValue } = useForm<TransferPersonnelFormValues>({ defaultValues });

  const [project, role, endDate, unfilledRole, startDate] = useWatch({
    control,
    name: ['project', 'role', 'endDate', 'unfilledRole', 'startDate'],
  });

  const handleClose = () => {
    if (setIsTransferPersonnelOpen) {
      setIsTransferPersonnelOpen(false);
    }
    reset({ project: { name: '', id: '' }, role: '', personnel: '', unfilledRole: null });
    setTransactionKey(generateTransactionKey());
  };

  const onSubmit: SubmitHandler<TransferPersonnelFormValues> = (data) => {
    const roleStartDate = data.startDate;
    const roleEndDate = data.endDate;
    const personId = transferPersonnelRole?.person?.id;
    const unfilledRole = data.unfilledRole;
    const roleName = data.role.includes('-')
      ? data.role.split(' - ')[1]
      : (ROLES_BY_ABBREVIATION[data.role]?.roleName as string);
    const projectId = data.project.id;

    if (roleName) {
      addProjectRole({
        variables: {
          input: {
            transactionKey,
            startDate: convertDateToUseableISOString(roleStartDate) ?? undefined,
            endDate: convertDateToUseableISOString(roleEndDate as Date) ?? undefined,
            roleName,
            personId,
            projectId,
          },
        },
      })
        .then(() => {
          apolloClient.reFetchObservableQueries();
          displayToast('The personnel was transferred successfully', 'success');
          handleClose();
        })
        .catch(() => {
          displayToast(
            'Error: Something went wrong while trying to transfer the personnel. Please try again. If the problem persists, please contact support.',
            'error',
          );
        });
    } else {
      upsertProjectRole({
        variables: {
          input: {
            id: unfilledRole?.id,
            startDate: convertDateToUseableISOString(roleStartDate) ?? undefined,
            endDate: convertDateToUseableISOString(roleEndDate as Date) ?? undefined,
            roleName: unfilledRole?.roleName ?? '',
            personId,
            projectId,
          },
        },
      })
        .then(() => {
          apolloClient.reFetchObservableQueries();
          displayToast("This person's next assignment's start date was adjusted", 'success');
          handleClose();
        })
        .catch(() => {
          displayToast(
            "Error: Something went wrong while trying to update This person's next assignment's start date. Please try again. If the problem persists, please contact support.",
            'error',
          );
        });
    }
  };

  useEffect(() => {
    const foundProject = projectListResult?.find((projectFromList) => projectFromList?.id === project?.id);
    const foundProjectUnfilledRoles = foundProject?.roles.filter((role) => !role.person);
    setUnfilledRolesOptions(foundProjectUnfilledRoles ?? []);
  }, [project]);

  useEffect(() => {
    const isEndDateInvalid = isNaN(new Date(endDate as Date).getTime());
    const isStartDateInvalid = isNaN(new Date(startDate).getTime());
    project?.id && project?.name && (role || unfilledRole !== undefined) && !isEndDateInvalid && !isStartDateInvalid
      ? setCanSubmit(true)
      : setCanSubmit(false);
  }, [endDate, project, role, unfilledRole, startDate]);

  useEffect(() => {
    setRolesOptions(
      moveRoleToFront(
        ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE(selectedEmployeeType === 'Craft'),
        transferPersonnelRole?.roleFullDisplayName ?? '',
      ),
    );
  }, [transferPersonnelRole?.roleFullDisplayName]);

  const formatDateString = (dateStr: string, isPersonnelEarliestAvailableDate: boolean) => {
    if (typeof dateStr === 'string') {
      if (isPersonnelEarliestAvailableDate) {
        const dateTimePlusOne = DateTime.fromISO(dateStr).plus({ days: 1 });
        return dateTimePlusOne.toFormat('MM/dd/yyyy');
      }
      const dateTime = DateTime.fromISO(dateStr);
      return dateTime.toFormat('MM/dd/yyyy');
    }
  };

  return (
    <StyledDialog
      title={`Transfer Personnel`}
      isLoading={isLoading}
      content={
        <Grid container sx={containerStyle}>
          <Box component="form" sx={formStyle}>
            <Grid container spacing={6} sx={formRowStyle}>
              <Grid item xs={4}>
                <Typography>Personnel</Typography>
                <TextField
                  size="small"
                  disabled
                  fullWidth
                  variant="outlined"
                  value={transferPersonnelRole?.person?.name?.lastCommaFirst}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>First Available Date</Typography>
                <TextField
                  size="small"
                  disabled
                  fullWidth
                  variant="outlined"
                  value={formatDateString(transferPersonnelRole?.person?.earliestDateAvailable, true)}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>Project</Typography>
                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Fragment>
                        <Autocomplete
                          id="contentType"
                          size="small"
                          fullWidth
                          options={
                            projectListResult
                              ?.filter((project) => transferPersonnelRole.project.id !== project.id)
                              ?.map((project) => {
                                return { id: project.id, name: project.name };
                              }) ?? []
                          }
                          getOptionLabel={(value) => value.name}
                          filterSelectedOptions
                          onChange={(_event, newValue) => {
                            onChange(newValue);
                            setValue('unfilledRole', null);
                          }}
                          value={value || { id: '', name: '' }}
                          isOptionEqualToValue={(
                            option: { id: string; name: string },
                            value: { id: string; name: string },
                          ) => {
                            return option.id === value.id;
                          }}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={props.id}>
                                {option.name}
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField error={!value?.id && !value?.name} {...params} variant="outlined" />
                          )}
                        />
                        {!value?.id && !value?.name && (
                          <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>
                        )}
                      </Fragment>
                    );
                  }}
                  name="project"
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} sx={formRowStyle}>
              <Grid item xs={6}>
                <Typography>Unfilled Roles Available</Typography>
                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Fragment>
                        <Autocomplete
                          id="contentType"
                          size="small"
                          disabled={unfilledRolesOptions.length === 0 || role !== ''}
                          options={unfilledRolesOptions}
                          getOptionLabel={(value) => {
                            const roleFullDisplayName = value.roleFullDisplayName ?? '';
                            const roleStartDate = formatDateString(value.startDate, false) ?? '';
                            const roleEndDate = formatDateString(value.endDate, false) ?? '';
                            return `${roleFullDisplayName} (${roleStartDate} - ${roleEndDate})`;
                          }}
                          filterSelectedOptions
                          onChange={(_event, newValue) => {
                            onChange(newValue);
                            if (newValue) {
                              setValue('unfilledRole', newValue);
                              setValue('role', '');
                              setValue('endDate', new Date(newValue.endDate));
                              setValue('startDate', new Date(newValue.startDate));
                            }
                          }}
                          value={value}
                          isOptionEqualToValue={(option: any, value: any) => {
                            return option === value;
                          }}
                          renderInput={(params) => <TextField error={!value} {...params} variant="outlined" />}
                        />
                        {!value && unfilledRolesOptions.length > 0 && role === '' && (
                          <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>
                        )}
                      </Fragment>
                    );
                  }}
                  name="unfilledRole"
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>New Role To Create</Typography>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Fragment>
                      <Autocomplete
                        id="contentType"
                        size="small"
                        disabled={unfilledRole !== undefined && unfilledRole !== null}
                        options={rolesOptions}
                        getOptionLabel={(value) => value}
                        filterSelectedOptions
                        onChange={(_event, newValue) => {
                          const personnelStartDatePlusOne = new Date(
                            personnelFirstAvailableDate.setDate(personnelFirstAvailableDate.getDate() + 1),
                          );
                          onChange(newValue);
                          setValue('role', newValue ?? '');
                          setValue('unfilledRole', null);
                          setValue('endDate', undefined);
                          setValue('startDate', personnelStartDatePlusOne);
                        }}
                        value={value}
                        isOptionEqualToValue={(option: any, value: any) => {
                          return option === value;
                        }}
                        renderInput={(params) => <TextField error={!value} {...params} variant="outlined" />}
                      />
                      {!value && (unfilledRole === undefined || unfilledRole === null) && (
                        <FormHelperText sx={formHelperErrorTextStyle}>Field Required </FormHelperText>
                      )}
                    </Fragment>
                  )}
                  name="role"
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} sx={formRowStyle}>
              <Grid item xs={4}>
                <Typography>Start Date</Typography>
                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        sx={removeTopMargin}
                        error={!value}
                        helperText={!value ? 'Field Required' : ''}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        value={value?.toISOString().split('T')[0] ?? ''}
                        onChange={(event) => {
                          const value = event.target.value;
                          onChange(value);
                          setValue('startDate', new Date(value));
                        }}
                        type={'date'}
                      />
                    );
                  }}
                  name="startDate"
                  control={control}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>End Date</Typography>
                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        sx={removeTopMargin}
                        error={!value}
                        helperText={!value ? 'Field Required' : ''}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        value={value?.toISOString().split('T')[0] ?? ''}
                        inputProps={{
                          min: transferRoleStartDate?.plus({ days: 1 }).toFormat('yyyy-MM-dd'),
                        }}
                        onChange={(event) => {
                          const value = event.target.value;
                          onChange(value);
                          setValue('endDate', new Date(value));
                        }}
                        type={'date'}
                      />
                    );
                  }}
                  name="endDate"
                  control={control}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      }
      actions={
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <StyledButtonSecondary disabled={false} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={'Submit'}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!canSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isTransferPersonnelOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default TransferPersonnelDialog;
