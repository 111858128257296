import { PersonnelListResult, ProjectListResultItem } from 'components/ProjectsGrid/ProjectsGrid';
import StyledDatePicker from 'components/shared/KeyboardDatePicker/KeyboardDatePicker';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { LEAVE_PROJECT_ID } from 'constants/leaveProject';
import {
  ROLES,
  ROLES_BY_ABBREVIATION,
  ROLES_BY_FULL_DISPLAY_NAME,
  ROLES_BY_NAME,
  ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE,
} from 'constants/roles';
import { PROJECT_GANTT_ID } from 'constants/syncfusionComponentIds';
import useDataFilter from 'hooks/useDataFilter';
import useRoles from 'hooks/useRoles';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { FC, useEffect, useState, useMemo } from 'react';
import { useDeleteProjectRoleMutation, useUpsertProjectRoleMutation } from 'types/generated/graphql';
import {
  calculateDuration,
  convertDateToUseableISOString,
  convertSyncfusionDatePickerToValidDate,
  convertUTCDateToLocalDate,
  isDeepEqual,
  lengthOfTimeInYearsSortComparer,
  snapTaskbarEndOfMonth,
  snapTaskbarStartOfMonth,
} from 'utils/general';
import { roleSort } from 'utils/roles';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Popper,
  Stack,
  styled,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { GanttComponent, GanttModel } from '@syncfusion/ej2-react-gantt';

import { Flag } from '@mui/icons-material';
import { GridColumnModel } from '@syncfusion/ej2-react-grids';
import useCurrentUser from 'hooks/useCurrentUser';
import GanttChart from '../Gantt/GanttChart';
import { parentTaskbarTemplate, projectTooltipTemplate, taskbarTemplate } from './utils/projectGanttFunctions';
import {
  addEditDialogFields,
  labelSettings,
  projectDropdownOptionsTemplate,
  projectDropdownTemplate,
  projectRolesOptions,
  projectToolbarOptions,
  taskFields,
} from './utils/projectGanttOptions';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { hpYellowPrimary, trueBlack } from 'constants/themes/colors';

const ANY_ROLE = 'Any';
export const ANY_PROJECT = 'All Projects';

const indicatorIconStyle: SxProps<Theme> = (theme: Theme) => ({
  color: `${theme.palette.primary.contrastText} !important`,
  marginRight: theme.spacing(1),
});

const selectDistrictCardStyle: SxProps<Theme> = {
  backgroundColor: '#fff',
  borderRadius: '4px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '680px',
};

const checkboxLabelStyles: SxProps<Theme> = {
  fontSize: '12px',
};

const roleSelectStyle: SxProps<Theme> = () => ({
  width: '250px',
});

const dateSelectStyle: SxProps<Theme> = () => ({
  width: '170px',
});

const projectSelectStyle: SxProps<Theme> = () => ({
  width: '500px',
});

const editWarningStyle: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.warning.light,
  color: trueBlack,
});

const editWarningTextStyle: SxProps<Theme> = {
  fontSize: '0.875rem',
};

const notesTextAndFlagStyle: SxProps<Theme> = {
  fontSize: '13px',
};

const accordionRootStyle: SxProps<Theme> = {
  boxShadow: 'none',
};

const accordionDetailsStyle: SxProps<Theme> = {
  padding: '0 16px',
};

const accordionSummaryStackStyle: SxProps<Theme> = {
  borderBottom: `3px solid ${hpYellowPrimary}`,
};

const accordionSummaryStyle: SxProps<Theme> = {
  width: '160px',
  backgroundColor: hpYellowPrimary,
  borderRadius: '16px 16px 0 0',
  minHeight: '32px',
  height: '32px',
  '&.Mui-expanded': {
    minHeight: '32px',
    height: '32px',
  },
};

const searchFilterTextStyle: SxProps<Theme> = {
  fontSize: '13px',
  fontWeight: 'bold',
  color: trueBlack,
};

const expandIconStyles: SxProps<Theme> = {
  color: trueBlack,
};

const nestedStackRootStyle: SxProps<Theme> = {
  marginTop: '35px !important',
};

const nestedStackStyle: SxProps<Theme> = {
  height: '90px',
};

const clearFiltersButtonStyle: SxProps<Theme> = {
  padding: '7px 15px',
};

interface ProjectGanttProps {
  selectedDistricts?: string[];
  personnelListForGantt?: PersonnelListResult[];
  projectListForGantt: ProjectListResultItem[];
  selectedEmployeeType: string | null;
  onDataChanged: { (): Promise<void> };
}

const UNFILLED_ROLES_REGEX = new RegExp(/([0-9]+) unfilled/);

export const ProjectGantt: FC<ProjectGanttProps> = ({
  selectedDistricts,
  personnelListForGantt,
  projectListForGantt,
  selectedEmployeeType,
  onDataChanged,
}) => {
  const currentUser = useCurrentUser();
  const { isEnterpriseAdmin, isDM, isStockholder, isMarketing, isAuthenticated, isCraft } = useRoles();
  const { displayToast } = useToast();
  const [ganttInstance, setGanttInstance] = useState<GanttComponent | null | undefined>(null);
  const [openAddRolesDialog, setOpenAddRolesDialog] = useState<boolean>(false);
  const [editTaskbar, setEditTaskbar] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState(
    {
      id: '',
      name: '',
    } || null,
  );
  const [previousRoleData, setPreviousRoleData] = useState<any>(null);

  useEffect(() => {
    if (ganttInstance) {
      if (!editTaskbar) {
        ganttInstance.toolbarModule.enableItems(['ExitEditTaskbar'], false);
        ganttInstance.toolbarModule.enableItems(['EditTaskbar'], true);
        ganttInstance.editSettings.allowTaskbarEditing = false;
      } else {
        ganttInstance.toolbarModule.enableItems(['ExitEditTaskbar'], true);
        ganttInstance.toolbarModule.enableItems(['EditTaskbar'], false);
        ganttInstance.editSettings.allowTaskbarEditing = true;
      }
    }
  }, [editTaskbar, ganttInstance]);

  const [dataFilterShouldShowEndedRoles, setDataFilterShouldShowEndedRoles] = useDataFilter({
    filter: 'boolean',
    localStorageKey: `${PROJECT_GANTT_ID}ShowEndedRoles`,
    searchParamKey: 'showEndedRoles',
  });
  const shouldShowEndedRoles = dataFilterShouldShowEndedRoles === true;
  const [dataFilterShouldShowTargetProjects, setDataFilterShouldShowTargetProjects] = useDataFilter({
    filter: 'boolean',
    localStorageKey: `${PROJECT_GANTT_ID}ShowTargetProjects`,
    searchParamKey: 'showTargetProjects',
  });
  const shouldShowTargetProjects = dataFilterShouldShowTargetProjects === true;
  const [dataFilterShouldShowArchivedProjects, setDataFilterShouldShowArchivedProjects] = useDataFilter({
    filter: 'boolean',
    localStorageKey: `${PROJECT_GANTT_ID}ShowArchivedProjects`,
    searchParamKey: 'showArchivedProjects',
  });
  const shouldShowArchivedProjects = dataFilterShouldShowArchivedProjects === true;
  const [dataFilterRole, setDataFilterRole] = useDataFilter({
    filter: 'string',
    localStorageKey: `${PROJECT_GANTT_ID}Role`,
    searchParamKey: 'role',
  });
  const [dataFilterStartDate, setDataFilterStartDate] = useDataFilter({
    filter: 'date',
    localStorageKey: `${PROJECT_GANTT_ID}StartDate`,
    searchParamKey: 'startDate',
  });
  const [dataFilterEndDate, setDataFilterEndDate] = useDataFilter({
    filter: 'date',
    localStorageKey: `${PROJECT_GANTT_ID}EndDate`,
    searchParamKey: 'endDate',
  });
  const [dataFilterProject, setDataFilterProject] = useDataFilter({
    filter: 'stringArray',
    localStorageKey: `${PROJECT_GANTT_ID}Project`,
    searchParamKey: 'project',
  });

  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (dataFilterRole) count++;
    if (dataFilterStartDate) count++;
    if (dataFilterEndDate) count++;
    if (dataFilterProject && dataFilterProject.length > 0) count++;
    if (shouldShowEndedRoles) count++;
    if (shouldShowTargetProjects) count++;
    if (shouldShowArchivedProjects) count++;
    return count;
  }, [
    dataFilterRole,
    dataFilterStartDate,
    dataFilterEndDate,
    dataFilterProject,
    shouldShowEndedRoles,
    shouldShowTargetProjects,
    shouldShowArchivedProjects,
  ]);

  const projectList =
    projectListForGantt.map((project) => {
      if (project.id === LEAVE_PROJECT_ID) {
        project.startDate = DateTime.now().minus({ month: 1 }).toISODate();
        project.endDate = DateTime.now().minus({ month: 1 }).plus({ year: 3 }).toISODate();
        project.startJsDate = DateTime.now().minus({ month: 1 }).toJSDate();
        project.endJsDate = DateTime.now().minus({ month: 1 }).plus({ year: 3 }).toJSDate();
      }
      return project;
    }) ?? [];
  const allProjectsById = projectList.reduce<{ [id: string]: (typeof projectList)[number] }>((accumulator, project) => {
    accumulator[project.id] = project;
    return accumulator;
  }, {});

  const personnelList = personnelListForGantt?.filter(
    (personnel) =>
      !(personnel.name?.firstName === null && personnel.name?.lastName === null && personnel.name?.display === null),
  );

  const [deleteProjectRoleMutation] = useDeleteProjectRoleMutation();
  const [editProjectRole] = useUpsertProjectRoleMutation();

  const getRoleUpsertInput = (role: any) => {
    const roleEndDate = typeof role.endDate === 'string' ? role.endDate : convertDateToUseableISOString(role.endDate);
    const roleStartDate =
      typeof role.startDate === 'string' ? role.startDate : convertDateToUseableISOString(role.startDate);

    return {
      id: role.taskID,
      personnel: role.personnel,
      personId: role.personId,
      roles: role.roles,
      startDate: roleStartDate,
      endDate: roleEndDate,
      notes: role.notes,
      focusArea: role.focusArea,
    };
  };

  const handleEditProjectRoleMutation = async (updatedProjectRoleData: any) => {
    const personnelNameLastCommaFirst = updatedProjectRoleData.personnel.includes('-')
      ? updatedProjectRoleData.personnel.split(' - ')[0]
      : '';
    const personId =
      (personnelList?.find((personnel) => personnel.name?.lastCommaFirst === personnelNameLastCommaFirst)
        ?.id as string) ?? '';
    const roleName = updatedProjectRoleData.roles.includes('-')
      ? updatedProjectRoleData.roles.split(' - ')[1]
      : ROLES_BY_ABBREVIATION[updatedProjectRoleData.roles]
      ? (ROLES_BY_ABBREVIATION[updatedProjectRoleData.roles]?.roleName as string)
      : updatedProjectRoleData.roles;

    const convertedRoleEndDate = convertSyncfusionDatePickerToValidDate(updatedProjectRoleData.endDate);
    const roleEndDate = editTaskbar
      ? convertDateToUseableISOString(convertedRoleEndDate)
      : updatedProjectRoleData.endDate;
    const roleStartDate = editTaskbar
      ? convertDateToUseableISOString(new Date(updatedProjectRoleData.startDate))
      : updatedProjectRoleData.startDate;
    const roleId = editTaskbar ? updatedProjectRoleData.taskID : updatedProjectRoleData.id;

    return await editProjectRole({
      variables: {
        input: {
          id: roleId,
          personId,
          roleName,
          startDate: roleStartDate,
          endDate: roleEndDate,
          notes:
            updatedProjectRoleData.notes === undefined || updatedProjectRoleData.notes === ''
              ? null
              : updatedProjectRoleData.notes,
          focusArea:
            updatedProjectRoleData.focusArea === undefined || updatedProjectRoleData.focusArea === ''
              ? null
              : updatedProjectRoleData.focusArea,
        },
      },
    })
      .then(() => {
        displayToast('The project role was updated successfully', 'success');

        if (!editTaskbar) {
          setEditTaskbar(false);
        }
      })
      .catch(() => {
        displayToast(
          'Error: Something went wrong while trying to update the project role. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  const handleDeleteProjectRoleMutation = (deleteProjectRoleData: any[]) => {
    deleteProjectRoleData.forEach((deleteItem) => {
      if (deleteItem.isDelete) {
        const deletedTaskData = deleteItem.taskData;
        const entityType: string = deletedTaskData.entityType;
        const deleteMutation = entityType === 'ProjectRole' ? deleteProjectRoleMutation : undefined;
        if (deleteMutation === undefined) {
          throw new Error(`Delete not supported for entityType ${entityType}`);
        }
        deleteMutation({
          variables: { id: deletedTaskData.taskID },
        })
          .then(() => {
            displayToast('The project role was deleted successfully', 'success');
          })
          .catch(() => {
            displayToast(
              'Error: Something went wrong while trying to delete the project role. Please try again. If the problem persists, please contact support.',
              'error',
            );
          });
      }
    });
  };

  const canViewPersonnelDetails = (row: any) => {
    return (
      isEnterpriseAdmin ||
      isDM ||
      isStockholder ||
      isCraft ||
      (isMarketing &&
        (currentUser?.homeDistrict === row.personnel.prDistrict ||
          row.personnel.sharedWithDistricts?.includes(currentUser?.homeDistrict?.toString() ?? '')))
    );
  };

  const rowSelected = (args: any) => {
    if (ganttInstance) {
      if (args.data?.level === 0) {
        ganttInstance?.toolbarModule.enableItems(['ProjectsGantt_delete'], false);
        ganttInstance?.toolbarModule.enableItems(['ProjectsGantt_edit'], false);
      } else {
        ganttInstance?.toolbarModule.enableItems(['ProjectsGantt_delete'], true);
        ganttInstance?.toolbarModule.enableItems(['ProjectsGantt_edit'], true);
      }
    }
  };

  const personnelListOptions = personnelList?.map((personnel, idx) => {
    const prJobTitle = ROLES_BY_NAME[personnel.prJobTitle ?? '']?.abbreviation;
    return projectDropdownOptionsTemplate(idx + 1, `${personnel.name?.lastCommaFirst} - ${prJobTitle}`);
  });
  personnelListOptions?.unshift(projectDropdownOptionsTemplate(0, `Unfilled`));

  const created: GanttModel['created'] = () => {
    ganttInstance?.toolbarModule.enableItems(['ProjectsGantt_delete'], false);
    ganttInstance?.toolbarModule.enableItems(['ProjectsGantt_edit'], false);
    if (ganttInstance) {
      ganttInstance.treeGrid.grid.commandClick = (args: any) => {
        if (args.commandColumn.title === 'View Project Details Page') {
          window.open(`/projects/${args.rowData.taskID}`, '_blank');
        } else if (args.commandColumn.title === 'View Personnel Details Page') {
          if (canViewPersonnelDetails(args.rowData.taskData)) {
            window.open(`/personnel/${args.rowData.taskData.personId}`, '_blank');
          } else {
            displayToast('You do not have permission to view this personnel record', 'error');
          }
        } else if (args.commandColumn.title === 'Add Project Role') {
          setSelectedProject({ id: args.rowData.taskID, name: args.rowData.name });
          setOpenAddRolesDialog(true);
        }
      };
    }
  };

  const ActionMenu = [
    {
      buttonOption: {
        iconCss: 'e-icons e-add',
        cssClass: 'e-flat e-addprojectrole e-commandButton',
      },
      title: 'Add Project Role',
    },
    {
      buttonOption: {
        iconCss: 'e-icons e-people',
        cssClass: 'e-flat e-viewpersonneldetailspage e-commandButton',
      },
      title: 'View Personnel Details Page',
    },
    {
      buttonOption: {
        iconCss: 'e-icons e-open-link',
        cssClass: 'e-flat e-viewprojectdetailspage e-commandButton',
      },
      title: 'View Project Details Page',
    },
  ];

  const notesTemplate = (props: any) => {
    if (props.level === 0) {
      return '';
    } else {
      return (
        <Typography sx={notesTextAndFlagStyle}>
          {props.taskData.notesFlag && <Flag fontSize="small" sx={notesTextAndFlagStyle} />} {props.taskData.notes}
        </Typography>
      );
    }
  };

  // Non-visible fields need to be in the gantt for project details to persist after editing.
  const taskProjectColumnDirectivePropsList: GridColumnModel[] = [
    { field: 'taskID', visible: false, headerText: 'Task ID', isPrimaryKey: true },
    { field: 'number', headerText: 'Job #', headerTextAlign: 'Center', width: 100, allowEditing: false },
    {
      field: 'commands',
      headerText: 'Action Menu',
      headerTextAlign: 'Center',
      commands: ActionMenu,
      visible: true,
      allowFiltering: false,
      allowEditing: false,
      allowSorting: false,
      allowSearching: false,
      width: 110,
    },
    {
      field: 'project',
      visible: false,
      headerText: 'Project',
      allowEditing: false,
    },
    {
      field: 'districts',
      width: 100,
      headerTextAlign: 'Center',
      headerText: 'Regions',
      allowEditing: false,
    },
    {
      field: 'name',
      headerTextAlign: 'Center',
      headerText: 'Name',
      width: 250,
      allowEditing: false,
    },
    {
      field: 'personnel',
      edit: projectDropdownTemplate(personnelListOptions, 'Personnel', false, 'option'),
      width: 250,
      headerTextAlign: 'Center',
      headerText: 'Personnel',
    },
    {
      field: 'roles',
      edit: projectDropdownTemplate(projectRolesOptions, 'Role', false, 'option'),
      width: 100,
      headerTextAlign: 'Center',
      headerText: 'Roles',
      filter: { operator: 'contains' },
      sortComparer: (a, b) => {
        if (typeof a === 'string' && typeof b === 'string') {
          const aUnfilledMatch = UNFILLED_ROLES_REGEX.exec(a);
          const bUnfilledMatch = UNFILLED_ROLES_REGEX.exec(b);
          if (aUnfilledMatch && bUnfilledMatch) {
            return Number.parseInt(aUnfilledMatch[1]) - Number.parseInt(bUnfilledMatch[1]);
          } else if (aUnfilledMatch) {
            return 1;
          } else if (bUnfilledMatch) {
            return -1;
          } else {
            return roleSort(ROLES_BY_FULL_DISPLAY_NAME[a], ROLES_BY_FULL_DISPLAY_NAME[b]);
          }
        } else {
          console.log('Unexpected type while sorting roles.');
          return 0;
        }
      },
    },
    { field: 'focusArea', width: 125, headerTextAlign: 'Center', headerText: 'Focus Area' },
    { field: 'notes', width: 125, headerTextAlign: 'Center', template: notesTemplate, headerText: 'Notes' },
    { field: 'verticalMarkets', visible: false, headerText: 'Vertical Markets', allowEditing: false },
    { field: 'status', visible: false, headerText: 'Status', allowEditing: false },
    {
      field: 'totalLOS',
      allowEditing: false,
      type: 'number',
      headerTextAlign: 'Center',
      headerText: 'Total LOS',
      width: 115,
      sortComparer: lengthOfTimeInYearsSortComparer,
    },
    { field: 'startDate', headerTextAlign: 'Center', width: 115, allowFiltering: false },
    { field: 'endDate', headerTextAlign: 'Center', width: 115, allowFiltering: false },
  ];

  const cellEdit: GanttModel['cellEdit'] = (args: any) => {
    if (args.rowData.level === 0) {
      args.cancel = true;
    }
  };

  let previousEndDate: Date | null;
  let previousStartDate: Date | null;
  let endDateEdit: boolean = false;

  const actionBegin: GanttModel['actionBegin'] = (args: any) => {
    const rolePropsToRemoveFromAddEditDialog = ['name', 'districts'];
    const copyOfGanttInstance: any = ganttInstance;
    const currentStartDate = args.rowData?.startDate;
    const currentEndDate = args.rowData?.endDate;
    const previousRoleRowData = args.rowData?.taskData;
    setPreviousRoleData(previousRoleRowData);

    if (args.requestType === 'beforeOpenEditDialog') {
      if (args.rowData.level === 0) {
        args.cancel = true;
      }
      if (args.rowData.level === 1) {
        args.rowData.roles = ROLES_BY_NAME[args.rowData.taskData.roles]?.fullDisplayName ?? args.rowData.taskData.roles;
        rolePropsToRemoveFromAddEditDialog.forEach((prop) => delete args.General[prop]);
        copyOfGanttInstance.columnByField.project.visible = false;
      }
      args.General.startDate.change = null;
      previousEndDate = currentEndDate;
      endDateEdit = previousStartDate !== currentStartDate ? true : false;
    }

    if ((args.type === 'save' || args.type === 'edit') && args.columnName === 'startDate') {
      previousEndDate = currentEndDate;
      endDateEdit = true;
    }

    if (args.requestType === 'beforeSave' && endDateEdit) {
      args.data.ganttProperties.endDate = previousEndDate;
      args.data.endDate = previousEndDate;
      args.data.taskData.endDate = previousEndDate;
      args.data.ganttProperties.duration = copyOfGanttInstance?.dataOperation.getDuration(
        args.data.startDate,
        previousEndDate,
        args.data.ganttProperties.durationUnit,
        args.data.ganttProperties.isAutoSchedule,
        args.data.ganttProperties.isMilestone,
      );
      endDateEdit = false;
      previousEndDate = null;
    }
  };

  if (window.location.toString().includes('projects/')) {
    ganttInstance?.toolbarModule.enableItems(['MassProjectUpload'], false);
    ganttInstance?.toolbarModule.enableItems(['AddProject'], false);
  } else {
    ganttInstance?.toolbarModule.enableItems(['MassProjectUpload'], isEnterpriseAdmin);
    ganttInstance?.toolbarModule.enableItems(['AddProject'], true);
  }

  const actionComplete: GanttModel['actionComplete'] = (...args: any) => {
    args.forEach((actionPayload: any) => {
      if (actionPayload.requestType === 'searching' && actionPayload.searchString === '') {
        ganttInstance?.collapseAll();
      }
      if (actionPayload.action === 'TaskbarEditing' && actionPayload.requestType === 'save' && editTaskbar) {
        if (actionPayload.taskBarEditAction === 'ChildDrag') {
          const endDate = DateTime.fromJSDate(actionPayload.data.endDate).minus({ month: 1 }).endOf('month').toJSDate();
          const data = {
            taskID: actionPayload.data.taskID,
            duration: calculateDuration(snapTaskbarStartOfMonth(actionPayload.data.startDate), endDate),
            startDate: snapTaskbarStartOfMonth(actionPayload.data.startDate),
            resources: actionPayload.data.ganttProperties.resourceInfo,
            taskType: 'FixedDuration',
          };
          ganttInstance?.updateRecordByID(data);
        }
        if (actionPayload.taskBarEditAction === 'LeftResizing') {
          const data = {
            taskID: actionPayload.data.taskID,
            duration: calculateDuration(
              snapTaskbarStartOfMonth(actionPayload.data.startDate),
              actionPayload.data.endDate,
            ),
            startDate: snapTaskbarStartOfMonth(actionPayload.data.startDate),
            resources: actionPayload.data.ganttProperties.resourceInfo,
            taskType: 'FixedDuration',
          };
          ganttInstance?.updateRecordByID(data);
        }
        if (actionPayload.taskBarEditAction === 'RightResizing') {
          const data = {
            taskID: actionPayload.data.taskID,
            duration: calculateDuration(
              actionPayload.data.startDate,
              snapTaskbarEndOfMonth(actionPayload.data.endDate),
            ),
            startDate: actionPayload.data.startDate,
            resources: actionPayload.data.ganttProperties.resourceInfo,
            taskType: 'FixedDuration',
          };
          ganttInstance?.updateRecordByID(data);
        }

        if (actionPayload.taskBarEditAction === 'LeftResizing') {
          const data = {
            taskID: actionPayload.data.taskID,
            duration: calculateDuration(
              snapTaskbarStartOfMonth(actionPayload.data.startDate),
              actionPayload.data.endDate,
            ),
            startDate: snapTaskbarStartOfMonth(actionPayload.data.startDate),
            resources: actionPayload.data.ganttProperties.resourceInfo,
            taskType: 'FixedDuration',
          };
          ganttInstance?.updateRecordByID(data);
        }

        if (actionPayload.taskBarEditAction === 'RightResizing') {
          const data = {
            taskID: actionPayload.data.taskID,
            duration: calculateDuration(
              snapTaskbarStartOfMonth(actionPayload.data.startDate),
              DateTime.fromJSDate(actionPayload.data.endDate).endOf('month').toJSDate(),
            ),
            startDate: actionPayload.data.startDate,
            resources: actionPayload.data.ganttProperties.resourceInfo,
            taskType: 'FixedDuration',
          };
          ganttInstance?.updateRecordByID(data);
        }
      }
      if (
        actionPayload.action === 'TaskbarEditing' &&
        actionPayload.requestType === 'save' &&
        (actionPayload.taskBarEditAction === 'ChildDrag' ||
          actionPayload.taskBarEditAction === 'LeftResizing' ||
          actionPayload.taskBarEditAction === 'RightResizing') &&
        editTaskbar
      ) {
        const editRoleData: any = actionPayload.data.taskData;
        handleEditProjectRoleMutation(editRoleData);
      }
      if (actionPayload.action === 'delete') {
        const deleteRoleData: any[] = actionPayload.data;
        handleDeleteProjectRoleMutation(deleteRoleData);
      }
      if (actionPayload.action === 'DialogEditing' || actionPayload.action === 'CellEditing') {
        if (actionPayload.requestType === 'cancel') {
          return;
        } else {
          const updatedRoleData: any = actionPayload.data.taskData;
          const changedPersonnel = previousRoleData.personnel !== updatedRoleData.personnel;

          // Hacky sanitization of the dual-purpose person name field for editing and updating the personId
          if (changedPersonnel) {
            const personnelNameLastCommaFirst = updatedRoleData.personnel.includes('-')
              ? updatedRoleData.personnel.split(' - ')[0]
              : '';

            const personId =
              (personnelList?.find((personnel) => personnel.name?.lastCommaFirst === personnelNameLastCommaFirst)
                ?.id as string) ?? '';

            updatedRoleData.personId = personId;
          }

          const previousRoleUpsertInput = getRoleUpsertInput(previousRoleData);
          const updatedRoleUpsertInput = getRoleUpsertInput(updatedRoleData);

          if (!isDeepEqual(previousRoleUpsertInput, updatedRoleUpsertInput)) {
            handleEditProjectRoleMutation(updatedRoleUpsertInput);
          }
        }
      }
    });
  };

  const queryTaskbarInfo: GanttModel['queryTaskbarInfo'] = (args: any) => {
    if (args.data.taskData.taskName === 'Unfilled') {
      args.taskbarBgColor = 'red';
    } else if (args.data?.level === 0 && args.taskbarType === 'ParentTask') {
      args.taskbarBgColor = 'green';
    }

    // Hide projectRole taskbar editing indicators when not in edit mode
    if (!editTaskbar && args.data?.level === 1 && args.taskbarType === 'ChildTask') {
      const childNodes = args.taskbarElement.childNodes ?? [];
      if (childNodes.length > 0) {
        childNodes.forEach((childNode: any) => {
          if (childNode.className.includes('resizer.e-icon')) {
            childNode.className += 'e-preventEdit';
          }
        });
      }
    }
  };

  const taskbarEditing: GanttModel['taskbarEditing'] = (args: any) => {
    if (!editTaskbar) {
      args.cancel = true;
    }
  };

  const tooltipSettings = {
    showTooltip: true,
    taskbar: projectTooltipTemplate,
  };

  const dataSource = projectList?.map((project) => ({
    entityType: project.__typename,
    taskID: project.id,
    number: project.number,
    taskName: project.name,
    name: project.name,
    districts: project.districts?.join(', '),
    verticalMarkets: project.verticalMarkets?.join(', '),
    status: project.status,
    roles: `${project.roles.filter((projectRole) => !projectRole.person).length} unfilled`,
    startDate: project.startDate,
    duration: Math.floor(calculateDuration(new Date(project.startDate), new Date(project.endDate))) + 1,
    subtasks: project.roles.map((projectRole) => ({
      entityType: projectRole.__typename,
      taskID: projectRole.id,
      taskName: ROLES.find((role) => role.roleName === projectRole.roleName)?.fullDisplayName,
      name: '',
      projectId: project.id,
      project: project.name,
      personId: projectRole.person?.id,
      personIsCraft: projectRole.person?.isCraft,
      focusArea: projectRole.focusArea,
      notesFlag: projectRole.notesFlag,
      notes: projectRole.notes,
      personnel: projectRole.person?.name?.lastCommaFirst
        ? `${projectRole.person.name.lastCommaFirst} - ${ROLES_BY_NAME[projectRole?.person?.prJobTitle ?? '']
            ?.abbreviation}`
        : 'Unfilled',
      roles: ROLES.find((role) => role.roleName === projectRole.roleName)?.fullDisplayName,
      personPrDistrict: projectRole.person?.prDistrict,
      personSharedWithDistricts: projectRole.person?.sharedWithDistricts,
      totalLOS: projectRole.person?.personTenureYear ?? '',
      startDate: projectRole.startDate,
      endDate: projectRole.endDate,
      duration: Math.floor(calculateDuration(new Date(projectRole.startDate), new Date(projectRole.endDate))) + 1,
      indicators: projectRole.person?.possiblePromotionDate
        ? [
            {
              date: projectRole.person.possiblePromotionDate,
              name: ROLES_BY_NAME[projectRole.person.possiblePromotionTitle ?? '']?.abbreviation,
              tooltip: `possible promotion of ${projectRole.person.name?.lastCommaFirst} to ${projectRole.person.possiblePromotionTitle} on ${projectRole.person.possiblePromotionDate}`,
              iconClass: `e-btn-icon e-notes-info e-icons e-icon-left e-gantt e-notes-info::before ${indicatorIconStyle}`,
            },
          ]
        : [],
    })),
  }));

  const subtaskSpansRange = (
    subtask: (typeof dataSource)[number]['subtasks'][number],
    startDate: DateTime | null,
    endDate: DateTime | null,
  ) => {
    if (!startDate && !endDate) {
      return true;
    } else if (subtask.startDate && subtask.endDate) {
      const roleStart = DateTime.fromISO(subtask.startDate);
      const roleEnd = DateTime.fromISO(subtask.endDate);
      if (startDate && endDate) {
        const roleStartsDuringRange = roleStart >= startDate && roleStart <= endDate;
        const roleEndsDuringRange = roleEnd >= startDate && roleEnd <= endDate;
        return roleStartsDuringRange || roleEndsDuringRange || (roleStart <= startDate && roleEnd >= endDate);
      } else if (endDate) {
        return roleStart <= endDate;
      } else if (startDate) {
        return roleEnd >= startDate;
      } else {
        throw new Error('Should be unreachable.');
      }
    } else {
      return false;
    }
  };

  const showEndedRoles = (subtask: (typeof dataSource)[number]['subtasks'][number], showRolesEnded: boolean) => {
    if (!showRolesEnded) {
      const roleEnd = DateTime.fromISO(subtask.endDate);
      return roleEnd > DateTime.now();
    } else {
      return subtask;
    }
  };

  const showTargetProjects = (project: (typeof dataSource)[number], showTargetProjects: boolean) => {
    if (!showTargetProjects) {
      return project.status !== 'Target';
    } else {
      return project;
    }
  };

  const showArchivedProjects = (project: (typeof dataSource)[number], showArchivedProjects: boolean) => {
    if (!showArchivedProjects) {
      return project.status !== 'Archived';
    } else {
      return project;
    }
  };

  const onClearAllDataFilters = () => {
    setDataFilterRole(null);
    setDataFilterStartDate(null);
    setDataFilterEndDate(null);
    setDataFilterProject(null);
    setDataFilterShouldShowEndedRoles(null);
    setDataFilterShouldShowTargetProjects(null);
    setDataFilterShouldShowArchivedProjects(null);
  };

  const getEmployeeType = (subtask: (typeof dataSource)[number]['subtasks'][number]) => {
    const isEmployeeCraftOrSalary = ROLES.some(
      (role) => role.fullDisplayName === subtask.roles && role.type === 'CRAFT',
    )
      ? 'Craft'
      : 'Salary';
    return isEmployeeCraftOrSalary;
  };

  const filteredDataSource = dataSource
    .filter(
      (task) =>
        dataFilterProject &&
        dataFilterProject.length > 0 &&
        dataFilterProject.find((filteredProject: string) => {
          if (filteredProject === ANY_PROJECT) return true;
          return filteredProject === task.taskID;
        }),
    )
    .map((task) => ({
      ...task,
      subtasks: task.subtasks.filter(
        (subtask) =>
          (dataFilterRole === null || dataFilterRole === subtask.roles) &&
          subtaskSpansRange(subtask, dataFilterStartDate, dataFilterEndDate) &&
          showEndedRoles(subtask, shouldShowEndedRoles) &&
          showTargetProjects(task, shouldShowTargetProjects) &&
          showArchivedProjects(task, shouldShowArchivedProjects) &&
          selectedEmployeeType === getEmployeeType(subtask),
      ),
    }))
    .filter((task) => task.subtasks.length);

  const noRecordsToDisplayMessage = 'No records to display';
  const pleaseChooseProjectMessage =
    'Please choose at least one project from the Search Filter dropdown above. You may choose a minimal selection of projects for faster loading or select "All Projects" to display all projects.';

  // Note: it would be preferable to use the L10n library for this, but it doesn't seem to work with the Gantt component.
  const replaceNoRecordsToDisplayInDom = () => {
    const tdElements = document.querySelectorAll('tr.e-emptyrow td');
    tdElements.forEach((tdElement) => {
      if (tdElement.textContent === noRecordsToDisplayMessage || tdElement.textContent === pleaseChooseProjectMessage) {
        tdElement.textContent =
          (dataFilterProject && dataFilterProject.length === 0) || dataFilterProject === null
            ? pleaseChooseProjectMessage
            : noRecordsToDisplayMessage;
      }
    });
  };

  useEffect(replaceNoRecordsToDisplayInDom, [dataFilterProject, replaceNoRecordsToDisplayInDom]);

  const dataBound: GanttComponent['dataBound'] = () => {
    replaceNoRecordsToDisplayInDom();
  };

  const rowDataBound: GanttComponent['rowDataBound'] = (args: any) => {
    if (args.row) {
      const personnel = args.data.personnel !== null && args.data.personnel !== 'Unfilled';
      const foundRowWithViewPersonnelDetailsIcon = args.row.querySelector('.e-viewpersonneldetailspage');
      const foundRowWithAddProjectRoleIcon = args.row.querySelector('.e-addprojectrole');
      const foundRowWithViewProjectDetailsIcon = args.row.querySelector('.e-viewprojectdetailspage');

      if (args.data.level === 0) {
        if (foundRowWithViewPersonnelDetailsIcon) {
          foundRowWithViewPersonnelDetailsIcon.style.display = 'none';
        }
      } else if (args.data.level === 1) {
        if (!personnel) {
          if (foundRowWithViewPersonnelDetailsIcon) {
            foundRowWithViewPersonnelDetailsIcon.style.display = 'none';
          }
        }

        if (foundRowWithAddProjectRoleIcon) {
          foundRowWithAddProjectRoleIcon.style.display = 'none';
        }

        if (foundRowWithViewProjectDetailsIcon) {
          foundRowWithViewProjectDetailsIcon.style.display = 'none';
        }
      }
    }
  };

  return (
    <Stack>
      <Accordion sx={accordionRootStyle}>
        <Stack direction="column" sx={accordionSummaryStackStyle}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={expandIconStyles} />} sx={accordionSummaryStyle}>
            <Typography sx={searchFilterTextStyle}>Search Filter ({activeFiltersCount})</Typography>
          </AccordionSummary>
        </Stack>
        <AccordionDetails sx={accordionDetailsStyle}>
          <Grid container>
            <Stack direction="row" spacing={7}>
              <Stack direction="row" alignItems="center" spacing={1} sx={nestedStackStyle}>
                <Typography>Show</Typography>
                <Autocomplete<string>
                  id="RoleFilter"
                  size="small"
                  options={[ANY_ROLE, ...ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE(selectedEmployeeType === 'Craft')]}
                  value={dataFilterRole ?? ANY_ROLE}
                  onChange={(_, value) => setDataFilterRole(value === ANY_ROLE ? null : value)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={roleSelectStyle}
                  PopperComponent={styled(Popper)(({ theme }) => ({
                    [`& .${autocompleteClasses.paper}`]: {
                      width: '400px',
                      // Shadow 8 is the same one that the StyledDatePicker ends up using.
                      boxShadow: theme.shadows[8],
                    },
                  }))}
                />
                <Typography>roles overlapping</Typography>
                <StyledDatePicker
                  value={dataFilterStartDate ? convertUTCDateToLocalDate(dataFilterStartDate) : null}
                  onChange={setDataFilterStartDate}
                  maxDate={dataFilterEndDate || undefined}
                  sx={dateSelectStyle}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <Typography>through</Typography>
                <Stack direction="column" sx={nestedStackRootStyle}>
                  <StyledDatePicker
                    value={dataFilterEndDate ? convertUTCDateToLocalDate(dataFilterEndDate) : null}
                    onChange={setDataFilterEndDate}
                    minDate={
                      dataFilterStartDate && !shouldShowEndedRoles
                        ? DateTime.max(dataFilterStartDate, DateTime.now())
                        : dataFilterStartDate
                        ? dataFilterStartDate
                        : !shouldShowEndedRoles
                        ? DateTime.now()
                        : undefined
                    }
                    sx={dateSelectStyle}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={shouldShowEndedRoles}
                        onChange={(e) => {
                          setDataFilterShouldShowEndedRoles(e.target.checked);
                        }}
                        size="small"
                      />
                    }
                    label={<Typography sx={checkboxLabelStyles}>Show Ended Roles</Typography>}
                    labelPlacement="end"
                  />
                </Stack>
                <Typography>on</Typography>
                <Stack direction="column" sx={nestedStackRootStyle}>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="ProjectFilter"
                    limitTags={2}
                    size="small"
                    options={[ANY_PROJECT, ...projectList.map((project) => project.id)]}
                    getOptionDisabled={(projectId) =>
                      (!shouldShowTargetProjects && allProjectsById[projectId]?.status === 'Target') ||
                      (!shouldShowArchivedProjects && allProjectsById[projectId]?.status === 'Archived')
                    }
                    value={dataFilterProject && dataFilterProject.length > 0 ? dataFilterProject : []}
                    onChange={(_, value) => {
                      if (value[0] === ANY_PROJECT && value.length > 1) {
                        const newValue = value.filter((projectId) => projectId !== ANY_PROJECT);
                        setDataFilterProject(newValue);
                      } else if (value.find((projectId) => projectId === ANY_PROJECT)) {
                        setDataFilterProject([ANY_PROJECT]);
                      } else {
                        setDataFilterProject(value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          dataFilterProject && dataFilterProject.length > 0 ? '' : 'Select Projects to Display'
                        }
                      />
                    )}
                    renderTags={(values, getTagProps, ownerState) =>
                      values
                        .map((value, index) =>
                          value === ANY_PROJECT || value in allProjectsById ? (
                            <Chip {...getTagProps({ index })} label={ownerState.getOptionLabel(value)} size="small" />
                          ) : (
                            false
                          ),
                        )
                        .filter((value) => !!value)
                    }
                    getOptionLabel={(projectId) => {
                      if (projectId === ANY_PROJECT) {
                        return ANY_PROJECT;
                      }
                      const project = allProjectsById[projectId];
                      if (!project) {
                        return 'project not found';
                      }
                      return project.number ? `${project.name} - ${project.number}` : `${project.name}`;
                    }}
                    sx={projectSelectStyle}
                    PopperComponent={styled(Popper)(({ theme }) => ({
                      [`& .${autocompleteClasses.paper}`]: {
                        width: '400px',
                        boxShadow: theme.shadows[8],
                      },
                    }))}
                  />
                  <Stack direction="row">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={shouldShowTargetProjects}
                          onChange={(e) => {
                            setDataFilterShouldShowTargetProjects(e.target.checked);
                          }}
                          size="small"
                        />
                      }
                      label={<Typography sx={checkboxLabelStyles}>Show Target Projects</Typography>}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={shouldShowArchivedProjects}
                          onChange={(e) => {
                            setDataFilterShouldShowArchivedProjects(e.target.checked);
                          }}
                          size="small"
                        />
                      }
                      label={<Typography sx={checkboxLabelStyles}>Show Archived Projects</Typography>}
                      labelPlacement="end"
                    />
                  </Stack>
                </Stack>
                <Button onClick={onClearAllDataFilters} variant="outlined" sx={clearFiltersButtonStyle}>
                  Clear Filters
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {editTaskbar && (
        <Stack direction="row" alignItems="center" padding={2} sx={editWarningStyle}>
          <>
            <WarningIcon />
            <Typography sx={editWarningTextStyle}>
              YOU ARE IN EDIT MODE. ANY CHANGES TO THE GANTT TASKBAR WILL BE AUTOMATICALLY SAVED.
            </Typography>
          </>
        </Stack>
      )}
      <Box>
        {!isAuthenticated ? (
          <LoadingSpinner />
        ) : selectedDistricts?.length === 0 ? (
          <>
            <Grid container sx={selectDistrictCardStyle}>
              <Grid item>Please select at least one region from the filter at the top of the page.</Grid>
            </Grid>
          </>
        ) : (
          <GanttChart
            ganttComponentProps={{
              id: PROJECT_GANTT_ID,
              dataSource: filteredDataSource,
              created,
              taskFields,
              labelSettings,
              taskbarTemplate,
              parentTaskbarTemplate,
              showOverAllocation: false,
              enableMultiTaskbar: false,
              enableVirtualMaskRow: false,
              queryTaskbarInfo,
              actionComplete,
              addDialogFields: [addEditDialogFields],
              editDialogFields: [addEditDialogFields],
              actionBegin,
              rowSelected,
              tooltipSettings,
              enablePersistence: true,
              taskbarEditing,
              dataBound,
              rowDataBound,
              cellEdit,
            }}
            selectedProject={selectedProject}
            columnDirectivePropsList={taskProjectColumnDirectivePropsList}
            setGanttInstance={setGanttInstance}
            toolbar={projectToolbarOptions}
            isProject
            openAddRolesDialog={openAddRolesDialog}
            setOpenAddRolesDialog={setOpenAddRolesDialog}
            personnelList={personnelListForGantt}
            projectList={projectList}
            setEditTaskbar={setEditTaskbar}
            dataFilterProject={dataFilterProject}
            onDataChanged={onDataChanged}
          />
        )}
      </Box>
    </Stack>
  );
};

export default ProjectGantt;
