import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-notifications/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-react-grids/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';

import HelpTooltip from 'components/shared/HelpTooltip';
import StyledDatePicker from 'components/shared/KeyboardDatePicker/KeyboardDatePicker';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE } from 'constants/roles';
import {
  DASH_SOURCE,
  EDW_SOURCE,
  EMPLOYEE_MASTER_SOURCE,
  HIRE_DATE_CALC_SOURCE,
  PEOPLE360_CALC_SOURCE,
  USER_INPUT_SOURCE,
} from 'constants/tooltips';
import useCurrentUser from 'hooks/useCurrentUser';
import useDataFilter from 'hooks/useDataFilter';
import useRoles from 'hooks/useRoles';
import { DateTime } from 'luxon';
import { FC, useMemo } from 'react';
import { PersonListResultForGanttQueryResult } from 'types/generated/graphql';
import {
  convertUTCDateToLocalDate,
  createExportColumns,
  graphqlDateToDateTime,
  lengthOfTimeInYearsSortComparer,
} from 'utils/general';
import { roleFullDisplayNameSortComparer } from 'utils/roles';

import { Flag } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Popper,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
  autocompleteClasses,
  styled,
} from '@mui/material';
import { Predicate, Query } from '@syncfusion/ej2-data';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  CommandColumn,
  CommandModel,
  ContextMenu,
  ExcelExport,
  ExcelExportProperties,
  Filter,
  GridColumnModel,
  GridComponent,
  GridModel,
  InfiniteScroll,
  Inject,
  Reorder,
  Resize,
  Search,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { useRecoilValue } from 'recoil';
import { selectedEmployeeTypeState } from 'recoil/selectedDistrict/atom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { hpYellowPrimary, trueBlack } from 'constants/themes/colors';

const ROW_HEIGHT_PX = 20;

const ANY_ROLE = 'Any';
const ACTION_MENU = 'Action Menu';

const gridContainerStyle: SxProps<Theme> = {
  width: '100%',
  zIndex: 100,
};

const cellIconStyle: SxProps<Theme> = {
  height: `${ROW_HEIGHT_PX - 2}px`,
  verticalAlign: 'middle',
};

const roleSelectStyle: SxProps<Theme> = () => ({
  width: '250px',
});

const dateSelectStyle: SxProps<Theme> = () => ({
  width: '170px',
});

const accordionRootStyle: SxProps<Theme> = {
  boxShadow: 'none',
};

const accordionDetailsStyle: SxProps<Theme> = {
  padding: '16px',
};

const accordionSummaryStackStyle: SxProps<Theme> = {
  borderBottom: `3px solid ${hpYellowPrimary}`,
};

const accordionSummaryStyle: SxProps<Theme> = {
  width: '160px',
  backgroundColor: hpYellowPrimary,
  borderRadius: '16px 16px 0 0',
  minHeight: '32px',
  height: '32px',
  '&.Mui-expanded': {
    minHeight: '32px',
    height: '32px',
  },
};

const searchFilterTextStyle: SxProps<Theme> = {
  fontSize: '13px',
  fontWeight: 'bold',
  color: trueBlack,
};

const expandIconStyles: SxProps<Theme> = {
  color: trueBlack,
};

const clearFiltersButtonStyle: SxProps<Theme> = {
  padding: '7px 15px',
};

const ACTION_RESET_VIEW = 'Reset View';
const ACTION_CLEAR_ALL_FILTERS = 'Clear All Filters';
const DATE_FORMAT = 'yyyy-MM-dd';

type UnassignedTime = {
  id: string;
  startDate: DateTime;
  endDate: DateTime;
};

export type PersonnelUnassignedGridProps = {
  gridId: string;
  personnelList: Exclude<PersonListResultForGanttQueryResult['data'], undefined>['personListResult']['items'];
};

type UnassignedTimeGridRowData = {
  personnel: PersonnelUnassignedGridProps['personnelList'][number];
  unassignedTime: UnassignedTime;
};

export let gridInstancesById: { [id: string]: GridComponent } = {};

const minimumDateTime = DateTime.fromMillis(0);
const maximumDateTime = DateTime.fromMillis(253402300799999);

const rowSpansRange = (rowData: UnassignedTimeGridRowData, startDate: DateTime | null, endDate: DateTime | null) => {
  if (!startDate && !endDate) {
    return true;
  } else {
    const unassignedTime = rowData.unassignedTime;
    const unassignedTimeStart = unassignedTime.startDate;
    const unassignedTimeEnd = unassignedTime.endDate;
    if (startDate && endDate) {
      const unassignedTimeStartsDuringRange = unassignedTimeStart >= startDate && unassignedTimeStart <= endDate;
      const unassignedTimeEndsDuringRange = unassignedTimeEnd >= startDate && unassignedTimeEnd <= endDate;
      return (
        unassignedTimeStartsDuringRange ||
        unassignedTimeEndsDuringRange ||
        (unassignedTimeStart <= startDate && unassignedTimeEnd >= endDate)
      );
    } else if (endDate) {
      return unassignedTimeStart <= endDate;
    } else if (startDate) {
      return unassignedTimeEnd >= startDate;
    } else {
      throw new Error('Should be unreachable.');
    }
  }
};

export const PersonnelUnassignedGrid: FC<PersonnelUnassignedGridProps> = ({ gridId, personnelList }) => {
  const currentUser = useCurrentUser();
  const { isEnterpriseAdmin, isDM, isStockholder, isMarketing, isAuthenticated, isCraft, isOnlyCraft } = useRoles();

  const employeeTypeDropdownValue = useRecoilValue(selectedEmployeeTypeState);
  const selectedEmployeeType = isOnlyCraft ? 'Craft' : employeeTypeDropdownValue;

  const getGridInstance = () => {
    return gridInstancesById[gridId] ?? null;
  };

  const [dataFilterRole, setDataFilterRole] = useDataFilter({
    filter: 'string',
    localStorageKey: `grid${gridId}Role`,
    searchParamKey: 'role',
  });
  const [dataFilterStartDate, setDataFilterStartDate] = useDataFilter({
    filter: 'date',
    localStorageKey: `grid${gridId}StartDate`,
    searchParamKey: 'startDate',
  });
  const [dataFilterEndDate, setDataFilterEndDate] = useDataFilter({
    filter: 'date',
    localStorageKey: `grid${gridId}EndDate`,
    searchParamKey: 'endDate',
  });

  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (dataFilterRole) count++;
    if (dataFilterStartDate) count++;
    if (dataFilterEndDate) count++;
    return count;
  }, [dataFilterRole, dataFilterStartDate, dataFilterEndDate]);

  const dataSource = personnelList.reduce<UnassignedTimeGridRowData[]>((accumulator, personnel) => {
    const roleSpans = personnel.roles.map((role) => ({
      startDate: graphqlDateToDateTime(role.startDate),
      endDate: graphqlDateToDateTime(role.endDate),
    }));
    roleSpans.sort((a, b) => {
      const startDiff = a.startDate.toMillis() - b.startDate.toMillis();
      if (startDiff !== 0) {
        return startDiff;
      } else {
        return a.endDate.toMillis() - b.endDate.toMillis();
      }
    });

    const personnelStartDate = personnel.hireDate ? graphqlDateToDateTime(personnel.hireDate) : minimumDateTime;
    const personnelEndDate = personnel.anticipatedSeparationDate
      ? graphqlDateToDateTime(personnel.anticipatedSeparationDate)
      : maximumDateTime;
    if (!roleSpans.length) {
      accumulator.push({
        personnel,
        unassignedTime: {
          id: accumulator.length.toString(),
          startDate: personnelStartDate,
          endDate: personnelEndDate,
        },
      });
    } else {
      const firstRoleStartDate = roleSpans[0].startDate;
      if (personnelStartDate.toMillis() < firstRoleStartDate.toMillis()) {
        accumulator.push({
          personnel,
          unassignedTime: {
            id: accumulator.length.toString(),
            startDate: personnelStartDate,
            endDate: firstRoleStartDate,
          },
        });
      }

      let roleStartDateCursor: DateTime = firstRoleStartDate;
      let roleEndDateCursor: DateTime = roleSpans[0].endDate;
      roleSpans.forEach((roleSpan) => {
        const roleStartMilliseconds = roleSpan.startDate.toMillis();
        const cursorStartMilliseconds = roleStartDateCursor.toMillis();
        const roleStartsAfterStartCursor = roleStartMilliseconds > cursorStartMilliseconds;
        if (roleStartsAfterStartCursor) {
          const cursorEndMilliseconds = roleEndDateCursor.toMillis();
          const roleStartsAfterEndCursor = roleStartMilliseconds > cursorEndMilliseconds;
          if (roleStartsAfterEndCursor) {
            accumulator.push({
              personnel,
              unassignedTime: {
                id: accumulator.length.toString(),
                startDate: roleEndDateCursor,
                endDate: roleSpan.startDate,
              },
            });
            roleStartDateCursor = roleSpan.startDate;
            roleEndDateCursor = roleSpan.endDate;
          }
          roleStartDateCursor = roleSpan.startDate;
        }
        const roleEndsAfterEndCursor = roleSpan.endDate.toMillis() > roleEndDateCursor.toMillis();
        if (roleEndsAfterEndCursor) {
          roleEndDateCursor = roleSpan.endDate;
        }
      });

      const lastRoleEndDate = roleSpans[roleSpans.length - 1].endDate;
      if (lastRoleEndDate.toMillis() < personnelEndDate.toMillis())
        accumulator.push({
          personnel,
          unassignedTime: {
            id: accumulator.length.toString(),
            startDate: lastRoleEndDate,
            endDate: personnelEndDate,
          },
        });
    }
    return accumulator;
  }, []);

  const canViewPersonnelDetails = (row: UnassignedTimeGridRowData) => {
    return (
      isEnterpriseAdmin ||
      isDM ||
      isStockholder ||
      isCraft ||
      (isMarketing &&
        (currentUser?.homeDistrict === row.personnel.prDistrict ||
          row.personnel.sharedWithDistricts?.includes(currentUser?.homeDistrict?.toString() ?? '')))
    );
  };

  const canViewPersonalDetails = () => {
    return isEnterpriseAdmin || isDM || isStockholder || isMarketing || isCraft;
  };

  const searchSettings = {
    fields: [
      'personnel.name.lastCommaFirst',
      'role.project.name',
      'personnel.notes',
      'personnel.prJobTitleFullDisplayName',
    ],
  };

  const ActionMenu: CommandModel[] = [
    {
      buttonOption: {
        iconCss: 'e-icons e-people',
        cssClass: 'e-flat e-commandButton',
      },
      title: 'View Personnel Details Page',
    },
  ];

  const commandClick = (args: any) => {
    const gridInstance = getGridInstance();
    const row = args.rowData;
    if (gridInstance) {
      if (row.personnel && canViewPersonnelDetails(row.personnel)) {
        window.open(`/personnel/${row.personnel.id}`, '_blank');
      }
    }
  };

  const columnList = () => {
    const standardColumns: React.ComponentProps<typeof ColumnDirective>[] = [
      {
        field: 'personnel.id',
        type: 'string',
        headerText: 'Personnel ID',
        allowEditing: false,
        visible: false,
        isPrimaryKey: true,
      },
      {
        field: 'unassignedTime.id',
        type: 'string',
        headerText: 'Free Time ID',
        allowEditing: false,
        visible: false,
        isPrimaryKey: true,
      },
      {
        headerText: 'Action Menu',
        width: 100,
        commands: ActionMenu,
      },
      {
        field: 'personnel.name.lastCommaFirst',
        type: 'string',
        headerText: 'Personnel Name',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={EMPLOYEE_MASTER_SOURCE} titleText={props.headerText} />
        ),
        template: (row: UnassignedTimeGridRowData) => (
          <>
            {row.personnel.notesFlag && <Flag sx={cellIconStyle} />}
            {row.personnel.name?.lastCommaFirst}
          </>
        ),
        allowEditing: false,
        width: 250,
      },
      {
        field: 'personnel.prJobTitleFullDisplayName',
        type: 'string',
        headerText: 'Payroll Title',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={EMPLOYEE_MASTER_SOURCE} titleText={props.headerText} />
        ),
        allowEditing: false,
        sortComparer: roleFullDisplayNameSortComparer,
        width: 100,
      },
      {
        field: `personnel.personTenureYear`,
        type: 'string',
        headerText: 'Total LOS',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={HIRE_DATE_CALC_SOURCE} titleText={props.headerText} />
        ),
        sortComparer: lengthOfTimeInYearsSortComparer,
        allowEditing: false,
        width: 125,
      },
      {
        field: 'personnel.industryLengthOfExperience',
        type: 'string',
        headerText: 'Industry Experience',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={DASH_SOURCE} titleText={props.headerText} />
        ),
        sortComparer: lengthOfTimeInYearsSortComparer,
        allowEditing: false,
        width: 190,
      },
      {
        field: 'unassignedTime.startDate',
        type: 'date',
        headerText: 'Free Starting',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={PEOPLE360_CALC_SOURCE} titleText={props.headerText} />
        ),
        template: (row: UnassignedTimeGridRowData) => row.unassignedTime.startDate.toFormat(DATE_FORMAT),
        allowEditing: false,
        allowFiltering: false,
        width: 125,
      },
      {
        field: 'personnel.hireDate',
        type: 'date',
        headerText: 'Hire Date',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={EMPLOYEE_MASTER_SOURCE} titleText={props.headerText} />
        ),
        template: (row: UnassignedTimeGridRowData) =>
          row.personnel.hireDate ? graphqlDateToDateTime(row.personnel.hireDate).toFormat(DATE_FORMAT) : null,
        allowEditing: false,
        width: 125,
      },
    ];

    const personalDetailColumns: React.ComponentProps<typeof ColumnDirective>[] = [
      {
        field: 'personnel.anticipatedSeparationDate',
        type: 'date',
        headerText: 'Anticipated Separation Date',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
        ),
        template: (row: UnassignedTimeGridRowData) =>
          row.personnel.anticipatedSeparationDate
            ? graphqlDateToDateTime(row.personnel.anticipatedSeparationDate).toFormat(DATE_FORMAT)
            : null,
        allowEditing: false,
        width: 190,
      },
      {
        field: 'personnel.possiblePromotionDate',
        type: 'string',
        headerText: 'Possible Promotion Date',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
        ),
        allowEditing: false,
        width: 200,
      },
      {
        field: 'personnel.possiblePromotionTitle',
        type: 'string',
        headerText: 'Possible Promotion Title',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
        ),
        allowEditing: false,
        width: 200,
      },
      {
        field: 'personnel.recentTitleChange',
        type: 'string',
        headerText: 'Last Promotion',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={EDW_SOURCE} titleText={props.headerText} />
        ),
        allowEditing: false,
        width: 200,
      },
      {
        field: 'personnel.notesFlag',
        type: 'boolean',
        headerText: 'Notes Flag',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
        ),
        template: (row: UnassignedTimeGridRowData) => row.personnel?.notesFlag && <Flag />,
        allowEditing: false,
        width: 125,
      },
      {
        field: 'personnel.notes',
        type: 'string',
        headerText: 'Notes',
        headerTemplate: (props: { headerText: string }) => (
          <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
        ),
        allowEditing: false,
        width: 200,
      },
    ];

    let columns = standardColumns;
    if (canViewPersonalDetails()) {
      columns = columns.concat(personalDetailColumns);
    }
    return columns;
  };

  const columnDirectivePropsList: React.ComponentProps<typeof ColumnDirective>[] = columnList();

  // foreignKeyValue appears to be used as the column's name in the column picker.
  columnDirectivePropsList.forEach((column: GridColumnModel) => {
    if (typeof column.headerText === 'string') {
      column.foreignKeyValue = column.headerText;
    }
  });

  const excelQueryCellInfo: GridModel['excelQueryCellInfo'] = (args: any) => {
    if (args.column.field === 'unassignedTime.endDate' || args.column.field === 'unassignedTime.startDate') {
      args.value = args.value.toFormat(DATE_FORMAT);
    }
  };

  // TODO: disable/enable the buttons depending on the presence of groups.
  const toolbarClick: Exclude<GridModel['toolbarClick'], undefined> = (args: any) => {
    const gridInstance = getGridInstance();
    if (gridInstance && args.item.id.startsWith(gridId)) {
      // Trim away the grid ID and '_'
      const action = args.item.id.substring(gridId.length + 1);
      if (action === ACTION_CLEAR_ALL_FILTERS) {
        gridInstance.clearFiltering();
      } else if (action === 'excelexport') {
        const ps: ExcelExportProperties = {};
        const visibleColumns = gridInstance
          .getVisibleColumns()
          .filter((column) => column.foreignKeyValue !== ACTION_MENU);
        const exportColumns = createExportColumns(visibleColumns);
        ps.columns = exportColumns;
        gridInstance.excelExport(ps);
      } else if (action === ACTION_RESET_VIEW) {
        // TODO: we probably need to do this whenever the app updates,
        // since changes to the column definitions seem to scramble the view.
        gridInstance.enablePersistence = false;
        window.localStorage.setItem(`grid${gridId}`, '');
        gridInstance.destroy();
        window.location.reload();
      }
    }
  };

  // Set Syncfusion DataUtil to use UTC time.
  // DataUtil.serverTimezoneOffset = 0;
  // const timezoneCorrectedDataSource = DataUtil.parse.parseJson?.(JSON.stringify(dataSource)) ?? dataSource;

  let values = '';
  let removeQuery = false;
  let valueAssign = false;

  const actionBegin: GridModel['actionBegin'] = (args: any) => {
    const gridInstance = getGridInstance();
    if (args.requestType === 'searching' && args.searchString) {
      const keys = args.searchString.split(' ');
      let flag = true;
      let predicate: Predicate | null | undefined = null;
      if (gridInstance && keys.length > 1) {
        if (gridInstance?.searchSettings.key !== '') {
          values = args.searchString;
          keys.forEach((key: Predicate) => {
            gridInstance?.getColumns().forEach((col) => {
              if (flag) {
                predicate = new Predicate(col.field, 'contains', key, true);
                flag = false;
              } else {
                var predic = new Predicate(col.field, 'contains', key, true);
                predicate = predicate?.or(predic);
              }
            });
          });
          if (predicate) {
            gridInstance.query = new Query().where(predicate);
            gridInstance.searchSettings.key = '';
            valueAssign = true;
            removeQuery = true;
            gridInstance.refresh();
          }
        }
      }
    }
  };

  const actionComplete: GridModel['actionComplete'] = (args: any) => {
    const gridInstance = getGridInstance();
    if (args.requestType === 'refresh' && valueAssign && gridInstance) {
      (document.getElementById(gridInstance.element.id + '_searchbar') as HTMLInputElement).value = values;
      valueAssign = false;
    } else if (
      gridInstance &&
      (document.getElementById(gridInstance.element.id + '_searchbar') as HTMLInputElement).value === '' &&
      args.requestType === 'refresh' &&
      removeQuery &&
      gridInstance
    ) {
      gridInstance.query = new Query();
      removeQuery = false;
      gridInstance.refresh();
    }
  };

  const onClearAllDataFilters = () => {
    setDataFilterRole(null);
    setDataFilterStartDate(null);
    setDataFilterEndDate(null);
  };

  const getEmployeeType = (row: UnassignedTimeGridRowData) => {
    const isEmployeeCraftOrSalary = row.personnel.isCraft ? 'Craft' : 'Salary';
    return isEmployeeCraftOrSalary;
  };

  const currentDate = DateTime.now().startOf('day');

  const filteredDataSource = dataSource.filter(
    (row) =>
      (dataFilterRole === null || dataFilterRole === row.personnel.prJobTitleFullDisplayName) &&
      rowSpansRange(row, dataFilterStartDate, dataFilterEndDate) &&
      row.unassignedTime.startDate.startOf('day') >= currentDate &&
      selectedEmployeeType === getEmployeeType(row),
  );

  return (
    <Stack>
      <Accordion sx={accordionRootStyle}>
        <Stack direction="column" sx={accordionSummaryStackStyle}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={expandIconStyles} />} sx={accordionSummaryStyle}>
            <Typography sx={searchFilterTextStyle}>Search Filter ({activeFiltersCount})</Typography>
          </AccordionSummary>
        </Stack>
        <AccordionDetails sx={accordionDetailsStyle}>
          <Grid container>
            <Stack direction="row" spacing={7}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography>Show</Typography>
                <Autocomplete<string>
                  id="RoleFilter"
                  size="small"
                  options={[ANY_ROLE, ...ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE(selectedEmployeeType === 'Craft')]}
                  value={dataFilterRole ?? ANY_ROLE}
                  onChange={(_, value) => setDataFilterRole(value === ANY_ROLE ? null : value)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={roleSelectStyle}
                  PopperComponent={styled(Popper)(({ theme }) => ({
                    [`& .${autocompleteClasses.paper}`]: {
                      width: '400px',
                      // Shadow 8 is the same one that the StyledDatePicker ends up using.
                      boxShadow: theme.shadows[8],
                    },
                  }))}
                />
                <Typography>personnel with free time between:</Typography>
                <StyledDatePicker
                  value={dataFilterStartDate ? convertUTCDateToLocalDate(dataFilterStartDate) : null}
                  onChange={setDataFilterStartDate}
                  maxDate={dataFilterEndDate || undefined}
                  sx={dateSelectStyle}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <Typography>and:</Typography>
                <StyledDatePicker
                  value={dataFilterEndDate ? convertUTCDateToLocalDate(dataFilterEndDate) : null}
                  onChange={setDataFilterEndDate}
                  minDate={dataFilterStartDate || undefined}
                  sx={dateSelectStyle}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <Button onClick={onClearAllDataFilters} variant="outlined" sx={clearFiltersButtonStyle}>
                  Clear Filters
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Box>
        {useMemo(
          () => (
            <>
              {!isAuthenticated && <LoadingSpinner />}
              {isAuthenticated && (
                <Grid container direction="column">
                  <Grid item>
                    <Box sx={gridContainerStyle}>
                      <GridComponent
                        id={gridId}
                        ref={(grid) => {
                          if (grid) {
                            gridInstancesById[gridId] = grid;
                          }
                        }}
                        allowExcelExport={true}
                        dataSource={filteredDataSource}
                        actionBegin={actionBegin}
                        actionComplete={actionComplete}
                        searchSettings={searchSettings}
                        excelQueryCellInfo={excelQueryCellInfo}
                        // TODO: looks like there's a bug when trying to use Shimmer. Consider reporting it.
                        // loadingIndicator={{ indicatorType: 'Shimmer' }}
                        toolbar={[
                          'ColumnChooser',
                          ACTION_CLEAR_ALL_FILTERS,
                          ACTION_RESET_VIEW,
                          'ExcelExport',
                          'Search',
                        ]}
                        toolbarClick={toolbarClick}
                        allowGrouping={false}
                        allowReordering={true}
                        allowResizing={true}
                        showColumnChooser={true}
                        showColumnMenu={true}
                        contextMenuItems={['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending']}
                        allowSorting={true}
                        allowFiltering={true}
                        allowSelection={true}
                        filterSettings={{ type: 'Excel' }}
                        commandClick={commandClick}
                        // TODO: Decide which is more important: virtualization or fill handle.
                        // Fill handle requires cell selection, which is incompatible with virtualization.
                        // Pagination may be the only alternative to virtualization,
                        // and that would likely present challenges when it comes to group/filter/search.
                        // Note that if we chose not to use virtualization, other options would become available.
                        // TODO: find out what kind of incompatibility we're talking about. It seems to kind of work,
                        // so is it that there are edge cases/bugs? I only found one reference in the docs, so is it
                        // fixed, but not reflected in all of the docs?
                        // selectionSettings={{ cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell' }}
                        //enableVirtualization={true}
                        // TODO: decide whether column virtualization is worth trying.
                        // It does seem like it may improve things if you don't have good column hygeine,
                        // but it seems more buggy, in particular with grouping/aggregates.
                        // enableColumnVirtualization={true}
                        enablePersistence={true}
                        // TODO: If we figure out a way to make this dynamic, go clean up the other
                        // hard coded heights in the gantt tab
                        height={'calc(100vh - 280px)'}
                        width={`calc(100vw - 20px)`}
                        enableInfiniteScrolling={true}
                        infiniteScrollSettings={{ initialBlocks: 5 }}
                        enableVirtualMaskRow={false}
                        // TODO: Using a calculated height seems to throw off the auto page size.
                        // See if we can calculate this fully in code to get a simple ###px value that is dynamic.
                      >
                        <ColumnsDirective>
                          {columnDirectivePropsList.map(
                            (columnDirectiveProps: React.ComponentProps<typeof ColumnDirective>, index) => {
                              return <ColumnDirective key={index} {...columnDirectiveProps} />;
                            },
                          )}
                        </ColumnsDirective>
                        <Inject
                          services={[
                            Toolbar,
                            Reorder,
                            Resize,
                            ColumnChooser,
                            ColumnMenu,
                            ContextMenu,
                            ExcelExport,
                            Sort,
                            Filter,
                            Search,
                            InfiniteScroll,
                            CommandColumn,
                          ]}
                        />
                      </GridComponent>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </>
          ),
          [dataSource, dataFilterStartDate, dataFilterEndDate, isAuthenticated],
        )}
      </Box>
    </Stack>
  );
};

export default PersonnelUnassignedGrid;
