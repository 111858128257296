import AddFile from 'components/common/AddFile';
import FileDisplayImage from 'components/FileDisplayImage';
import PdfViewer from 'components/PdfViewer';
import StyledButtonGroup from 'components/shared/ButtonGroup';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import IconButton from 'components/shared/IconButton';
import StyledDatePicker from 'components/shared/KeyboardDatePicker';
import TRAINING_DIALOG_VIEWS from 'constants/trainingDialogViews';
import { TRAINING_OPTIONS } from 'constants/trainingOptions';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { HiOutlinePencil } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import {
  GetSinglePersonQuery,
  TrainingOption,
  useCreatePersonTrainingMutation,
  useDeletePersonTrainingMutation,
  useUpdatePersonTrainingMutation,
} from 'types/generated/graphql';
import { isDocFile, isImageFile, isPdfFile, UploadedFile } from 'utils/fileViewer';
import { convertUTCDateToLocalDate, generateTransactionKey } from 'utils/general';

import { useApolloClient } from '@apollo/client';
import ErrorIcon from '@mui/icons-material/Error';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

type TrainingDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  trainingData: any;
  setTrainingDialogView: any;
  trainingDialogView: string;
  personnel: GetSinglePersonQuery['person'] | undefined;
  handleClose: (x: any) => void;
};

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1),
});

const fileContainerStyle: SxProps<Theme> = (theme: Theme) => ({
  margin: '0px',
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  borderRadius: 4,
  justifyContent: 'space-between',
  height: 125,
  [theme.breakpoints.down('sm')]: {
    height: 175,
    justifyContent: 'space-around',
  },
});

const fileNameStyle: SxProps<Theme> = (theme: Theme) => ({
  whiteSpace: 'nowrap',
  alignSelf: 'center',
  marginLeft: '40px',
  maxWidth: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',
    width: 150,
    maxWidth: 150,
  },
});

const imagePlaceholderTextStyle: SxProps<Theme> = (theme: Theme) => ({
  alignSelf: 'center',
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
});

const inputLabelStyle: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: '-8px',
  marginTop: '5px',
  color: theme.palette.secondary.contrastText,
});

const titleTextStyle: SxProps<Theme> = {
  fontWeight: 'bold',
};

const fileStyle: SxProps<Theme> = {
  display: 'inline-flex',
  marginBottom: '8px',
  marginRight: '8px',
  marginLeft: '8px',
  width: 100,
  height: 100,
  padding: '4px',
  boxSizing: 'border-box',
};

const fileInnerStyle: SxProps<Theme> = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  flexDirection: 'row',
};

const fileSectionStyle: SxProps<Theme> = {
  marginBottom: '15px',
};

const fileTextStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
};

const pdfIconStyle: SxProps<Theme> = {
  width: '100%',
  height: '100%',
};

const viewButtonContainerStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
};

const fullSizePreviewStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
};

const fullSizePlaceholderStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};

const materialIconStyle: SxProps<Theme> = {
  fontSize: '1.75rem',
  color: '#000',
};

const inputStyle: SxProps<Theme> = {
  marginTop: '0px',
};

const customTrainingFieldStyle: SxProps<Theme> = {
  marginTop: '15px',
};

const errorMessageStyle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error.main,
  marginLeft: '14px',
  marginTop: '4px',
  fontSize: '.80rem',
});

type EditTrainingInput = {
  name: TrainingOption;
  otherName?: string;
  awardedDate: string;
  expirationDate?: string;
  noExpirationDateCheck?: boolean;
  files?: File[] | UploadedFile[];
};

type useParamsProps = {
  personnelId: string;
};

const TrainingDialog: FC<TrainingDialogProps> = ({
  isOpen,
  setIsOpen,
  trainingData,
  setTrainingDialogView,
  trainingDialogView,
  personnel,
}) => {
  const { displayToast } = useToast();
  const { personnelId } = useParams<useParamsProps>();
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const [fileAttachmentToView, setFileAttachmentToView] = useState<UploadedFile | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [addedFiles, setAddedFiles] = useState<File[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<UploadedFile[]>([]);
  const [shouldNeverExpire, setShouldNeverExpire] = useState(
    trainingData?.expirationDate === null || trainingData?.expirationDate === 'Never Expires',
  );

  const {
    VIEW_TRAINING,
    ADD_TRAINING,
    EDIT_TRAINING,
    VIEW_PDF,
    VIEW_IMAGE,
    CONFIRM_DELETE,
    CONFIRM_CANCEL,
    NO_DOC_PREVIEW,
    NO_EXCEL_PREVIEW,
  } = TRAINING_DIALOG_VIEWS;

  const [createPersonTraining] = useCreatePersonTrainingMutation();
  const [updatePersonTraining, { loading: isLoading }] = useUpdatePersonTrainingMutation();
  const [deletePersonTraining] = useDeletePersonTrainingMutation();

  const apolloClient = useApolloClient();

  const defaultValues = {
    name: trainingData?.name ?? '',
    otherName: '',
    noExpirationDateCheck: trainingData?.expirationDate === null ? true : false,
    expirationDate: trainingData?.expirationDate ?? null,
    awardedDate: trainingData?.awardedDate ?? null,
  };

  const { handleSubmit, control, reset, watch } = useForm<EditTrainingInput>({
    defaultValues: defaultValues,
  });

  const personnelTrainingName = watch('name') ?? '';
  const personnelTrainingExpirationDate = watch('expirationDate') ?? '';
  const isExpirationDateChecked = watch('noExpirationDateCheck') ?? false;
  const isCustomTraining = personnelTrainingName === 'Other';

  const handleClose = () => {
    setIsOpen(false);
    setIsEditing(false);
    reset(defaultValues);
    setTransactionKey(generateTransactionKey());
  };

  const handleEditButtonClick = () => {
    setIsEditing(true);
    reset(defaultValues);
  };

  const trainingHasFilesUploaded = trainingData?.files?.length > 0 || trainingData?.updatedFiles?.length > 0;
  const existingFiles = trainingData?.updatedFiles?.length > 0 ? trainingData?.updatedFiles : [];

  const generateDialogTitle = () => {
    if (trainingDialogView === EDIT_TRAINING) {
      return `Editing ${trainingData?.otherName ?? trainingData?.name}`;
    }

    if (trainingDialogView === ADD_TRAINING) {
      return 'Add New Training';
    }

    if (trainingDialogView === VIEW_TRAINING) {
      return `${trainingData?.otherName ?? trainingData?.name}`;
    }

    if (
      trainingDialogView === VIEW_PDF ||
      trainingDialogView === VIEW_IMAGE ||
      trainingDialogView === NO_DOC_PREVIEW ||
      trainingDialogView === NO_EXCEL_PREVIEW
    ) {
      return fileAttachmentToView?.name;
    }

    if (trainingDialogView === CONFIRM_DELETE) {
      return `Delete ${trainingData?.otherName ?? trainingData?.name}`;
    }

    if (trainingDialogView === CONFIRM_CANCEL) {
      return 'Discard Training';
    }
  };

  const generateDialogContent = () => {
    if (trainingDialogView === CONFIRM_CANCEL) {
      return (
        <>
          <Grid container>
            <Grid item>
              <Typography className="margin-bottom">
                {'Are you sure you want to cancel? All information will be lost.'}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    if (trainingDialogView === CONFIRM_DELETE) {
      return (
        <>
          <Grid container>
            <Grid item>
              <Typography className="margin-bottom">
                {`Are you use you want to delete the training: ${
                  trainingData?.otherName ?? trainingData?.name
                }? This action cannot be undone.`}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    const openFilePreview = async (file: UploadedFile) => {
      isPdfFile(file)
        ? setTrainingDialogView(VIEW_PDF)
        : isImageFile(file)
        ? setTrainingDialogView(VIEW_IMAGE)
        : isDocFile(file)
        ? setTrainingDialogView(NO_DOC_PREVIEW)
        : setTrainingDialogView(NO_EXCEL_PREVIEW);

      setFileAttachmentToView(file);
    };

    if (trainingDialogView === VIEW_PDF) {
      return <PdfViewer file={fileAttachmentToView} />;
    }

    if (trainingDialogView === VIEW_IMAGE) {
      return (
        <Grid sx={fileAttachmentToView?.url === null ? fullSizePlaceholderStyle : fullSizePreviewStyle}>
          <FileDisplayImage file={fileAttachmentToView} isFullSizePreview={true} />
          {!fileAttachmentToView?.url && !fileAttachmentToView?.isMalware && (
            <Typography sx={imagePlaceholderTextStyle}>
              {'File preview image not found. If the problem persists, please contact support.'}
            </Typography>
          )}
          {fileAttachmentToView?.isMalware && (
            <Typography sx={imagePlaceholderTextStyle}>{'Malware detected. This file is unavailable.'}</Typography>
          )}
        </Grid>
      );
    }

    if ((trainingDialogView === EDIT_TRAINING && isEditing) || trainingDialogView === ADD_TRAINING) {
      return (
        <Grid container sx={containerStyle}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
              <InputLabel sx={inputLabelStyle} shrink>
                Training Name
              </InputLabel>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Autocomplete
                      disableClearable={false}
                      id="name"
                      options={TRAINING_OPTIONS.map((trainingOption) => trainingOption.label)}
                      filterSelectedOptions
                      onChange={(_event, newValue) => onChange(newValue)}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                      value={value || null}
                    />
                    <Grid sx={errorMessageStyle}>{error ? 'Required: Training Name' : null}</Grid>
                  </>
                )}
                rules={{ required: true }}
              />
            </Grid>
            {isCustomTraining && (
              <Grid item xs={12} sx={customTrainingFieldStyle}>
                <Controller
                  name="otherName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      sx={inputStyle}
                      label="Add New Training Name"
                      onChange={onChange}
                      value={value}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: isCustomTraining ? 'Required: Other Training Name' : false }}
                />
              </Grid>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <InputLabel sx={inputLabelStyle} shrink>
                  Awarded Date
                </InputLabel>
                <Controller
                  name="awardedDate"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => {
                    return (
                      <>
                        <StyledDatePicker
                          value={value ? convertUTCDateToLocalDate(value) : null}
                          onChange={onChange}
                          disableFuture
                        />
                        <Grid sx={errorMessageStyle}>{error ? 'Required: Awarded Date' : null}</Grid>
                      </>
                    );
                  }}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel sx={inputLabelStyle} shrink>
                  Expiration Date
                </InputLabel>
                <Controller
                  name="expirationDate"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                      <StyledDatePicker
                        value={shouldNeverExpire ? null : convertUTCDateToLocalDate(value)}
                        onChange={(newDate) => {
                          onChange(newDate);
                          setShouldNeverExpire(false);
                        }}
                        disablePast={true}
                      />
                      <Grid sx={errorMessageStyle}>
                        {error ? 'Required: Choose a date or check the box below' : null}
                      </Grid>
                    </>
                  )}
                  rules={{ required: personnelTrainingExpirationDate === '' && isExpirationDateChecked === false }}
                />
                <Grid item>
                  <FormControlLabel
                    control={
                      <Controller
                        name={'noExpirationDateCheck'}
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange } }) => {
                          return (
                            <Checkbox
                              checked={shouldNeverExpire}
                              onChange={(e) => {
                                onChange(e.target.checked);
                                setShouldNeverExpire(!shouldNeverExpire);
                              }}
                            />
                          );
                        }}
                      />
                    }
                    label={'Never Expires'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12}>
            <InputLabel sx={inputLabelStyle} shrink>
              Files
            </InputLabel>
            <Grid container direction="row" alignItems="center" display={'block'}>
              <Grid item>
                <AddFile
                  fileDataFromDb={trainingData?.updatedFiles ?? []}
                  setAddedFiles={setAddedFiles}
                  addedFiles={addedFiles}
                  existingFiles={existingFiles}
                  setFilesToDelete={setFilesToDelete}
                  filesToDelete={filesToDelete}
                  maxNumFiles={1}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    if (trainingDialogView === VIEW_TRAINING) {
      return (
        <>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Grid container justifyContent={'space-between'}>
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography sx={titleTextStyle}>{'Awarded Date'}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>{trainingData?.awardedDate}</Typography>
                        </Grid>
                        <Grid item xs={12}></Grid>
                      </Grid>
                    </Grid>
                    {!isTrainingFromLinkApp && (
                      <IconButton
                        sx={materialIconStyle}
                        title="Edit Training"
                        icon={<HiOutlinePencil />}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleEditButtonClick();
                          setTrainingDialogView(EDIT_TRAINING);
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography sx={titleTextStyle}>{'Expiration Date'}</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {trainingData?.expirationDate === null ? 'Never Expires' : trainingData?.expirationDate}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography sx={titleTextStyle}>{'Uploaded Files'}</Typography>
                  </Grid>
                  <Grid container>
                    {!trainingHasFilesUploaded && (
                      <>
                        <Grid item>
                          <Typography>{'There are no uploaded files.'}</Typography>
                        </Grid>
                      </>
                    )}
                    {trainingData.updatedFiles?.length > 0 &&
                      trainingData.updatedFiles.map((file: UploadedFile, index: number) => {
                        return (
                          <Grid item key={index} xs={12} sx={fileSectionStyle}>
                            <Grid container direction="row" sx={fileContainerStyle}>
                              <Grid item sx={fileTextStyle}>
                                <Grid sx={fileStyle}>
                                  <Grid sx={fileInnerStyle}>
                                    {isImageFile(file) && <FileDisplayImage file={file} />}
                                    {isPdfFile(file) && !file.isMalware && <PictureAsPdf sx={pdfIconStyle} />}
                                    {isPdfFile(file) && file.isMalware && <ErrorIcon sx={pdfIconStyle} />}
                                  </Grid>
                                </Grid>
                                <Tooltip title={file.name}>
                                  <Grid sx={fileNameStyle}>{file.name}</Grid>
                                </Tooltip>
                              </Grid>
                              <Grid item sx={viewButtonContainerStyle}>
                                <StyledButtonPrimary
                                  label={'View'}
                                  onClick={() => {
                                    openFilePreview(file);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  const formattedName = (name: any) => {
    return name.replace(/\s/g, '');
  };

  const onSubmit: SubmitHandler<EditTrainingInput> = (data: any) => {
    const trainingName = data.otherName !== '' ? 'Other' : formattedName(data.name);
    const awardedDate = DateTime.fromISO(data.awardedDate).toISODate();
    const expirationDate = shouldNeverExpire ? null : DateTime.fromISO(data.expirationDate ?? '').toISODate();

    if (trainingDialogView === ADD_TRAINING && personnelId) {
      createPersonTraining({
        variables: {
          input: {
            transactionKey,
            name: trainingName,
            otherName: data.otherName,
            awardedDate: DateTime.fromISO(data.awardedDate ?? '').toISODate() ?? null,
            expirationDate: DateTime.fromISO(data.expirationDate ?? '').toISODate() ?? null,
            personId: personnelId,
            filesToAdd: addedFiles ?? [],
          },
        },
      })
        .then(() => {
          handleClose();
          apolloClient.reFetchObservableQueries();
          displayToast('The training was added successfully', 'success');
        })
        .catch((error: any) => {
          console.error('Add training failed: ', error);
          displayToast(
            'Error: Something went wrong while trying to add the training. Please try again. If the problem persists, please contact support.',
            'error',
          );
        });
    }

    if (trainingDialogView === EDIT_TRAINING) {
      updatePersonTraining({
        variables: {
          input: {
            id: trainingData.id,
            name: trainingName,
            otherName: data.otherName,
            awardedDate,
            expirationDate,
            shouldDeleteExpirationDate: shouldNeverExpire,
            filesToAdd: addedFiles ?? [],
            filesToDelete,
          },
        },
      })
        .then(() => {
          handleClose();
          apolloClient.reFetchObservableQueries();
          displayToast('The training was updated successfully', 'success');
        })
        .catch((error: any) => {
          console.error('Update training failed: ', error);
          displayToast(
            'Error: Something went wrong while trying to update the training. Please try again. If the problem persists, please contact support.',
            'error',
          );
        });
    }
  };

  const deleteTraining = () => {
    deletePersonTraining({
      variables: { id: trainingData.id },
    })
      .then(() => {
        handleClose();
        apolloClient.reFetchObservableQueries();
        displayToast('The training was deleted successfully', 'success');
      })
      .catch((error: any) => {
        console.error('Delete training failed: ', error);
        displayToast(
          'Error: Something went wrong while trying to delete the training. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  const generateActions = () => {
    if (trainingDialogView === CONFIRM_DELETE) {
      return (
        <StyledButtonGroup
          onClickAltAction={() => setTrainingDialogView(VIEW_TRAINING)}
          labelAltAction={'Back'}
          disabledAltAction={isLoading}
          onClickMainAction={() => {
            deleteTraining();
          }}
          labelMainAction={'Delete Training'}
          disabledMainAction={isLoading}
        />
      );
    }

    if (trainingDialogView === CONFIRM_CANCEL) {
      return (
        <StyledButtonGroup
          onClickAltAction={() => setTrainingDialogView(VIEW_TRAINING)}
          labelAltAction={'Back'}
          disabledAltAction={isLoading}
          onClickMainAction={() => {
            handleClose();
          }}
          labelMainAction={'Discard'}
          disabledMainAction={isLoading}
        />
      );
    }

    if (trainingDialogView === EDIT_TRAINING) {
      return (
        <>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <StyledButtonSecondary
                label={'Cancel'}
                disabled={isLoading}
                onClick={() => {
                  handleClose();
                }}
              />
            </Grid>
            <Grid item>
              <StyledButtonGroup
                onClickAltAction={() => setTrainingDialogView(VIEW_TRAINING)}
                labelAltAction={'Back'}
                disabledAltAction={null}
                onClickMainAction={handleSubmit(onSubmit)}
                labelMainAction={'Save'}
                disabledMainAction={isLoading}
              />
            </Grid>
          </Grid>
        </>
      );
    }

    if (trainingDialogView === ADD_TRAINING) {
      return (
        <>
          <Grid container justifyContent="space-between">
            <Grid item>
              <StyledButtonSecondary
                label={'Cancel'}
                disabled={isLoading}
                onClick={() => setTrainingDialogView(CONFIRM_CANCEL)}
              />
            </Grid>
            <Grid item>
              <StyledButtonPrimary label={'Submit'} disabled={isLoading} onClick={handleSubmit(onSubmit)} />
            </Grid>
          </Grid>
        </>
      );
    }

    if (trainingDialogView === VIEW_TRAINING) {
      return (
        <>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <StyledButtonSecondary label={'Cancel'} disabled={isLoading} onClick={handleClose} />
            </Grid>
            {!isTrainingFromLinkApp && (
              <Grid item>
                <StyledButtonPrimary label={'Delete'} onClick={() => setTrainingDialogView(CONFIRM_DELETE)} />
              </Grid>
            )}
          </Grid>
        </>
      );
    }

    if (
      trainingDialogView === VIEW_PDF ||
      trainingDialogView === VIEW_IMAGE ||
      trainingDialogView === NO_DOC_PREVIEW ||
      trainingDialogView === NO_EXCEL_PREVIEW
    ) {
      return (
        <>
          <Grid item>
            <StyledButtonPrimary
              label={'Back'}
              disabled={isLoading}
              onClick={() => setTrainingDialogView(VIEW_TRAINING)}
            />
          </Grid>
        </>
      );
    }
  };

  const isTrainingFromLinkApp = personnel?.trainings?.find((training: any) => training.id === trainingData?.id)
    ?.isFromLinkApp;

  return (
    <StyledDialog
      title={generateDialogTitle()}
      content={generateDialogContent()}
      actions={generateActions()}
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default TrainingDialog;
