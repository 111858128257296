import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-notifications/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-react-grids/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';

import ReassignRoleDialog from 'components/ReassignRole/ReassignRoleDialog';
import HelpTooltip from 'components/shared/HelpTooltip';
import StyledDatePicker from 'components/shared/KeyboardDatePicker/KeyboardDatePicker';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { ROLES, ROLES_BY_FULL_DISPLAY_NAME, ROLES_BY_NAME, ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE } from 'constants/roles';
import { PROJECT_GRID_UNFILLED_ROLES_ID } from 'constants/syncfusionComponentIds';
import {
  ROOT_CONTAINER_PADDING_PX,
  TAB_PANEL_HEIGHT_PX,
  TOTAL_HEIGHT_OVERHEAD_FROM_APP_WRAPPING_PX,
} from 'constants/themes/dimensions';
import { EMPLOYEE_MASTER_SOURCE, USER_INPUT_SOURCE } from 'constants/tooltips';
import useCurrentUser from 'hooks/useCurrentUser';
import useDataFilter from 'hooks/useDataFilter/useDataFilter';
import useRoles from 'hooks/useRoles';
import useToast from 'hooks/useToast';
import { DateTime, Duration } from 'luxon';
import { ComponentProps, FC, Fragment, useMemo, useState } from 'react';
import {
  Person,
  PersonListResultQueryResult,
  Project,
  ProjectListResultQueryResult,
  ProjectRole,
  ProjectRoleUpsertInput,
  useUpsertProjectRoleMutation,
} from 'types/generated/graphql';
import { convertUTCDateToLocalDate, createExportColumns, GRAPHQL_DATE_FORMAT, isDeepEqual } from 'utils/general';

import { Flag } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Popper,
  Stack,
  styled,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { L10n } from '@syncfusion/ej2-base';
import { DataManager, Predicate, Query } from '@syncfusion/ej2-data';
import { DropDownList, DropDownTree } from '@syncfusion/ej2-dropdowns';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  CommandColumn,
  CommandModel,
  ContextMenu,
  Edit,
  ExcelExport,
  ExcelExportProperties,
  Filter,
  GridColumnModel,
  GridComponent,
  GridModel,
  InfiniteScroll,
  Inject,
  Resize,
  Search,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { Tooltip } from '@syncfusion/ej2-react-popups';
import { hpYellowPrimary, trueBlack } from 'constants/themes/colors';
import AddProjectDialog from '../shared/ProjectGantt/AddProjectDialog';
import AddProjectRoleDialog from './AddProjectRoleDialog';
import DeleteRoleDialog from './DeleteRoleDialog';
import TransferPersonnelDialog from './TransferPersonnelDialog';

const ROW_HEIGHT_PX = 20;
// These do not control the actual heights. If the heights change, these values should be updated.
const FILTER_HEIGHT_PX = 86;
const SYNCFUSION_TOOLBAR_HEIGHT_PX = 22;

const ANY_ROLE = 'Any';
const ANY_PROJECT = 'All Projects';
const ACTION_MENU = 'Action Menu';

const checkboxLabelStyles: SxProps<Theme> = {
  fontSize: '12px',
};

const gridContainerStyle: SxProps<Theme> = {
  width: '100%',
  zIndex: 100,
};

const cellIconStyle: SxProps<Theme> = {
  height: `${ROW_HEIGHT_PX - 2}px`,
  verticalAlign: 'middle',
};

const roleSelectStyle: SxProps<Theme> = () => ({
  width: '250px',
});

const dateSelectStyle: SxProps<Theme> = () => ({
  width: '170px',
});

const projectSelectStyle: SxProps<Theme> = () => ({
  width: '500px',
});

const accordionRootStyle: SxProps<Theme> = {
  boxShadow: 'none',
};

const accordionDetailsStyle: SxProps<Theme> = {
  padding: '0 16px',
};

const accordionSummaryStackStyle: SxProps<Theme> = {
  borderBottom: `3px solid ${hpYellowPrimary}`,
};

const accordionSummaryStyle: SxProps<Theme> = {
  width: '160px',
  backgroundColor: hpYellowPrimary,
  borderRadius: '16px 16px 0 0',
  minHeight: '32px',
  height: '32px',
  '&.Mui-expanded': {
    minHeight: '32px',
    height: '32px',
  },
};

const searchFilterTextStyle: SxProps<Theme> = {
  fontSize: '13px',
  fontWeight: 'bold',
  color: trueBlack,
};

const expandIconStyles: SxProps<Theme> = {
  color: trueBlack,
};

const nestedStackRootStyle: SxProps<Theme> = {
  marginTop: '35px !important',
};

const nestedStackStyle: SxProps<Theme> = {
  height: '90px',
};

const clearFiltersButtonStyle: SxProps<Theme> = {
  padding: '7px 15px',
};

const DATE_FORMAT = 'yyyy-MM-dd';

export type PersonnelListResult = Exclude<
  PersonListResultQueryResult['data'],
  undefined
>['personListResult']['items'][number];
export type ProjectFromListResult = Exclude<
  ProjectListResultQueryResult['data'],
  undefined
>['projectListResult']['items'][number];
type ProjectRoleFromProjectListResult = ProjectFromListResult['roles'][number];

export type ProjectsGridRowData = {
  project: ProjectFromListResult;
  role?: ProjectRoleFromProjectListResult;
};

export type ProjectListResultItem = Exclude<
  ProjectListResultQueryResult['data'],
  undefined
>['projectListResult']['items'][number];

export enum ToolbarItemId {
  COLUMN_CHOOSER = 'ColumnChooser',
  ACTION_CLEAR_ALL_FILTERS = 'Clear All Filters',
  ACTION_RESET_VIEW = 'Reset View',
  ADD_PROJECT = 'Add Project',
  ADD_PROJECT_ROLE = 'Add Project Roles',
  SEARCH = 'Search',
  DELETE_ROLE = 'Delete Role',
  EXCEL_EXPORT = 'ExcelExport',
}

export type ProjectsGridProps = {
  gridId: string;
  personnelList: PersonnelListResult[];
  projectList: ProjectListResultItem[];
  onDataChanged: { (): Promise<void> };
  dataSource: ProjectsGridRowData[];
  disabledToolbarItems?: ToolbarItemId[];
  disabledFields?: string[];
  selectedEmployeeType: string | null;
  selectedDistricts?: string[];
};

const rowSpansRange = (rowData: ProjectsGridRowData, startDate: DateTime | null, endDate: DateTime | null) => {
  if (!startDate && !endDate) {
    return true;
  } else if (rowData.role) {
    const role = rowData.role;
    const roleStart = DateTime.fromJSDate(role.startJsDate);
    const roleEnd = DateTime.fromJSDate(role.endJsDate);
    if (startDate && endDate) {
      const roleStartsDuringRange = roleStart >= startDate && roleStart <= endDate;
      const roleEndsDuringRange = roleEnd >= startDate && roleEnd <= endDate;
      return roleStartsDuringRange || roleEndsDuringRange || (roleStart <= startDate && roleEnd >= endDate);
    } else if (endDate) {
      return roleStart <= endDate;
    } else if (startDate) {
      return roleEnd >= startDate;
    } else {
      throw new Error('Should be unreachable.');
    }
  } else {
    return false;
  }
};

const showEndedRoles = (rowData: ProjectsGridRowData, showRolesEnded: boolean) => {
  if (!showRolesEnded) {
    if (rowData.role) {
      const role = rowData.role;
      const roleEnd = DateTime.fromJSDate(role.endJsDate);
      return roleEnd > DateTime.now();
    }
  } else {
    return rowData;
  }
};

const showTargetProjects = (rowData: ProjectsGridRowData, showTargetProjects: boolean) => {
  if (!showTargetProjects) {
    if (rowData.project) {
      return rowData.project.status !== 'Target';
    }
  } else {
    return rowData;
  }
};

const showArchivedProjects = (rowData: ProjectsGridRowData, showArchivedProjects: boolean) => {
  if (!showArchivedProjects) {
    if (rowData.project) {
      return rowData.project.status !== 'Archived';
    }
  } else {
    return rowData;
  }
};

const accessByPath = (data: any, path: string): any => {
  if (!data) {
    return undefined;
  }
  const dotIndex = path.indexOf('.');
  if (dotIndex > -1) {
    return accessByPath(data[path.substring(0, dotIndex)], path.substring(dotIndex + 1));
  } else {
    return data[path];
  }
};

const dropdownListTemplate = (
  dataSource: { option: string; label: string }[],
  placeholder: string,
  isMulti: boolean = false,
) => {
  let inputElem: HTMLInputElement;
  let dropDownList: DropDownTree | DropDownList;

  return {
    create: () => {
      inputElem = document.createElement('input');
      return inputElem;
    },
    read: () => {
      return Array.isArray(dropDownList.value) ? dropDownList.value.join(', ') : dropDownList.value;
    },
    destroy: () => {
      if (dropDownList) {
        dropDownList.destroy();
      }
    },
    write: (args: any) => {
      const value = accessByPath(args.rowData, args.column.field);
      dropDownList = isMulti
        ? new DropDownTree({
            fields: { dataSource, value: 'option', text: 'label' },
            placeholder,
            showCheckBox: true,
            value: value?.split(', '),
            popupWidth: 'auto',
          })
        : new DropDownList({
            dataSource,
            fields: { value: 'option', text: 'label' },
            placeholder,
            value,
            allowFiltering: true,
            filterType: 'Contains',
            popupWidth: 'auto',
          });
      dropDownList.appendTo(inputElem);
    },
  };
};

export let gridInstancesById: { [id: string]: GridComponent } = {};

export const ProjectsGrid: FC<ProjectsGridProps> = ({
  gridId,
  personnelList,
  projectList,
  onDataChanged,
  dataSource,
  disabledToolbarItems = [],
  disabledFields = [],
  selectedEmployeeType,
  selectedDistricts,
}) => {
  const { displayToast } = useToast();
  const currentUser = useCurrentUser();
  const { isAuthenticated, isEnterpriseAdmin, isDM, isStockholder, isMarketing, isCraft } = useRoles();
  const [isAddProjectDialogOpen, setIsAddProjectDialogOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(
    {
      id: '',
      name: '',
    } || null,
  );
  const [selectedRowData, setSelectedRowData] = useState<ProjectsGridRowData>();
  const [isAddProjectRoleDialogOpen, setIsAddProjectRoleDialogOpen] = useState(false);
  const [isDeleteProjectAndRoleDialogOpen, setIsDeleteProjectAndRoleDialogOpen] = useState(false);
  const [isReassignRoleDialogOpen, setIsReassignRoleDialogOpen] = useState(false);
  const [isTransferPersonnelOpen, setIsTransferPersonnelOpen] = useState(false);
  const [transferPersonnelRole, setTransferPersonnelRole] = useState<ProjectRole>();
  const [reassignProjectAndRole, setReassignProjectAndRole] = useState<ProjectsGridRowData>();
  const [startingReassignmentProjectAndRole, setStartingReassignmentProjectAndRole] = useState<ProjectsGridRowData>();
  const [dialogAction, setDialogAction] = useState('');

  const [dataFilterShouldShowEndedRoles, setDataFilterShouldShowEndedRoles] = useDataFilter({
    filter: 'boolean',
    localStorageKey: `grid${gridId}ShowEndedRoles`,
    searchParamKey: 'showEndedRoles',
  });
  const shouldShowEndedRoles = dataFilterShouldShowEndedRoles === true;
  const [dataFilterShouldShowTargetProjects, setDataFilterShouldShowTargetProjects] = useDataFilter({
    filter: 'boolean',
    localStorageKey: `grid${gridId}ShowTargetProjects`,
    searchParamKey: 'showTargetProjects',
  });
  const shouldShowTargetProjects = dataFilterShouldShowTargetProjects === true;
  const [dataFilterShouldShowArchivedProjects, setDataFilterShouldShowArchivedProjects] = useDataFilter({
    filter: 'boolean',
    localStorageKey: `grid${gridId}ShowArchivedProjects`,
    searchParamKey: 'showArchivedProjects',
  });
  const shouldShowArchivedProjects = dataFilterShouldShowArchivedProjects === true;
  const [dataFilterRole, setDataFilterRole] = useDataFilter({
    filter: 'string',
    localStorageKey: `grid${gridId}Role`,
    searchParamKey: 'role',
  });
  const [dataFilterStartDate, setDataFilterStartDate] = useDataFilter({
    filter: 'date',
    localStorageKey: `grid${gridId}StartDate`,
    searchParamKey: 'startDate',
  });
  const [dataFilterEndDate, setDataFilterEndDate] = useDataFilter({
    filter: 'date',
    localStorageKey: `grid${gridId}EndDate`,
    searchParamKey: 'endDate',
  });
  const [dataFilterProject, setDataFilterProject] = useDataFilter({
    filter: 'stringArray',
    localStorageKey: `grid${gridId}Project`,
    searchParamKey: 'project',
  });

  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (dataFilterRole) count++;
    if (dataFilterStartDate) count++;
    if (dataFilterEndDate) count++;
    if (dataFilterProject && dataFilterProject.length > 0) count++;
    if (shouldShowEndedRoles) count++;
    if (shouldShowTargetProjects) count++;
    if (shouldShowArchivedProjects) count++;
    return count;
  }, [
    dataFilterRole,
    dataFilterStartDate,
    dataFilterEndDate,
    dataFilterProject,
    shouldShowEndedRoles,
    shouldShowTargetProjects,
    shouldShowArchivedProjects,
  ]);

  const allProjectsById = projectList.reduce<{ [id: string]: ProjectListResultItem }>((accumulator, project) => {
    accumulator[project.id] = project;
    return accumulator;
  }, {});

  let values = '';
  let removeQuery = false;
  let valueAssign = false;

  const getGridInstance = () => {
    return gridInstancesById[gridId] ?? null;
  };

  const toolbarItemDefinitionsById: Record<ToolbarItemId, ComponentProps<typeof GridComponent>['toolbar']> = {
    [ToolbarItemId.COLUMN_CHOOSER]: ToolbarItemId.COLUMN_CHOOSER,
    [ToolbarItemId.ACTION_CLEAR_ALL_FILTERS]: {
      text: ToolbarItemId.ACTION_CLEAR_ALL_FILTERS,
      cssClass: 'clear-all-filters',
    },
    [ToolbarItemId.ACTION_RESET_VIEW]: ToolbarItemId.ACTION_RESET_VIEW,
    [ToolbarItemId.ADD_PROJECT]: ToolbarItemId.ADD_PROJECT,
    [ToolbarItemId.ADD_PROJECT_ROLE]: ToolbarItemId.ADD_PROJECT_ROLE,
    [ToolbarItemId.SEARCH]: ToolbarItemId.SEARCH,
    [ToolbarItemId.DELETE_ROLE]: {
      text: ToolbarItemId.DELETE_ROLE,
      tooltipText: ToolbarItemId.DELETE_ROLE,
      id: `${gridId}_DeleteRole`,
      disabled: getGridInstance()?.getSelectedRecords().length ? false : true,
    },
    [ToolbarItemId.EXCEL_EXPORT]: ToolbarItemId.EXCEL_EXPORT,
  };

  const toolbarItems = Object.entries(toolbarItemDefinitionsById)
    .filter(([key]) => !disabledToolbarItems.some((disabledKey) => disabledKey === key))
    .map(([_, value]) => value);

  const [upsertProjectRole] = useUpsertProjectRoleMutation();

  /* If person is TDY, their personPrDistrict isn't in selectedDistricts, 
   and they aren't shared with selectedDistricts they won't show in the personnelList */
  const validPersonnelList = personnelList?.filter((personnel) => personnel.id) ?? [];
  const assignablePersonnelById = validPersonnelList.reduce<{ [id: string]: PersonnelListResult }>(
    (accumulator, personnel) => {
      accumulator[personnel.id] = personnel;
      return accumulator;
    },
    {},
  );
  const personnelListOptions = validPersonnelList.map((personnel) => {
    const prJobTitle = ROLES_BY_NAME[personnel.prJobTitle ?? '']?.abbreviation ?? '';
    return { option: personnel.id, label: `${personnel.name?.lastCommaFirst} - ${prJobTitle}` };
  });
  personnelListOptions
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    })
    ?.unshift({ option: '', label: 'Unfilled' });

  const getPersonnelByIdInRow = (row: ProjectsGridRowData) => {
    // If a new person ID was selected from the dropdown, use that person,
    // falling back to whatever the row has in case of past data.
    const rowPersonId = row.role?.person?.id ?? '';
    const isRowPersonIdPresent = !!rowPersonId;
    const isRowPersonIdAssignable = isRowPersonIdPresent && rowPersonId in assignablePersonnelById;
    return isRowPersonIdAssignable ? assignablePersonnelById[rowPersonId] : row.role?.person;
  };

  const searchSettings = {
    fields: [
      'project.number',
      'project.name',
      'project.description',
      'project.districtsDisplay',
      'project.verticalMarketsDisplay',
      'role.person.name.lastCommaFirst',
      'role.roleFullDisplayName',
    ],
  };

  const canViewPersonnelDetails = (row: any) => {
    return (
      row?.name?.lastCommaFirst &&
      (isEnterpriseAdmin ||
        isDM ||
        isStockholder ||
        isCraft ||
        (isMarketing &&
          (currentUser?.homeDistrict === row.prDistrict ||
            row.sharedWithDistricts?.includes(currentUser?.homeDistrict?.toString() ?? ''))))
    );
  };

  const updatePersonnelsNextAssignmentStartDate = (updatedRoleData: any, previousRoleData: any) => {
    const updatedEndDate = DateTime.fromJSDate(updatedRoleData.endJsDate);
    const previousEndDate = DateTime.fromJSDate(previousRoleData.endJsDate);
    const nextAssignmentStartDate = DateTime.fromISO(updatedRoleData.nextAssignment.startDate);
    const nextAssignmentEndDate = DateTime.fromISO(updatedRoleData.nextAssignment.endDate);

    if (updatedEndDate > previousEndDate && updatedEndDate >= nextAssignmentStartDate) {
      const diff = updatedEndDate.diff(previousEndDate);
      const diffInDays = diff.as('days');
      const durationToAdd = Duration.fromObject({ days: diffInDays });

      const foundRole = dataSource?.find((row) => row.role?.id === updatedRoleData.nextAssignment.id)?.role;
      if (foundRole) {
        if (nextAssignmentStartDate.plus(durationToAdd) > nextAssignmentEndDate) {
          foundRole.startDate = nextAssignmentEndDate.toISODate();
          foundRole.startJsDate = nextAssignmentEndDate.toJSDate();
        } else {
          foundRole.startDate = nextAssignmentStartDate.plus(durationToAdd).toISODate();
          foundRole.startJsDate = nextAssignmentStartDate.plus(durationToAdd).toJSDate();
        }
        upsertProjectRole({
          variables: {
            input: {
              id: foundRole.id,
              startDate: foundRole.startDate,
              endDate: foundRole.endDate,
              roleName: foundRole.roleName,
              personId: foundRole.person?.id,
              projectId: foundRole.project.id,
            },
          },
        })
          .then(() => {
            displayToast("This person's next assignment's start date was adjusted", 'success');
          })
          .catch(() => {
            displayToast(
              "Error: Something went wrong while trying to update This person's next assignment's start date. Please try again. If the problem persists, please contact support.",
              'error',
            );
          });
      }
    }
  };

  const ActionMenu: CommandModel[] = [
    {
      buttonOption: {
        iconCss: 'e-icons e-edit',
        cssClass: 'e-flat e-commandButton',
      },
      title: 'Reassign Role',
    },
    {
      buttonOption: {
        iconCss: 'e-icons e-redo',
        cssClass: 'e-flat e-commandButton e-transferpersonnel',
      },
      title: 'Transfer Personnel',
    },
    {
      buttonOption: {
        iconCss: 'e-icons e-people',
        cssClass: 'e-flat e-commandButton e-viewpersonneldetailspage',
      },
      title: 'View Personnel Details Page',
    },
    {
      buttonOption: {
        iconCss: 'e-icons e-open-link',
        cssClass: 'e-flat e-commandButton',
      },
      title: 'View Project Details Page',
    },
  ];

  const rowDataBound: GridModel['rowDataBound'] = (args: any) => {
    if (args.row) {
      const personnel = args.data.role?.person;
      const personnelNextAssignment = args.data.role?.nextAssignment;

      if (!personnel) {
        const foundRowWithViewPersonnelDetailsIcon = args.row.querySelector('.e-viewpersonneldetailspage');
        const foundRowWithTransferPersonnelIcon = args.row.querySelector('.e-transferpersonnel');
        if (foundRowWithViewPersonnelDetailsIcon) {
          foundRowWithViewPersonnelDetailsIcon.style.display = 'none';
        }
        if (foundRowWithTransferPersonnelIcon) {
          foundRowWithTransferPersonnelIcon.style.display = 'none';
        }
      } else if (personnelNextAssignment !== null && personnelNextAssignment?.project.name) {
        const foundRowWithTransferPersonnelIcon = args.row.querySelector('.e-transferpersonnel');
        if (foundRowWithTransferPersonnelIcon) {
          foundRowWithTransferPersonnelIcon.style.display = 'none';
        }
      }
    }
  };

  const commandClick = (args: any) => {
    const gridInstance = getGridInstance();
    const row = args.rowData;
    if (gridInstance) {
      if (args.commandColumn.title === 'Reassign Role') {
        setIsReassignRoleDialogOpen(true);
        setReassignProjectAndRole(row);
        setStartingReassignmentProjectAndRole(JSON.parse(JSON.stringify(row)));
      } else if (args.commandColumn.title === 'View Personnel Details Page') {
        const person = getPersonnelByIdInRow(row);
        if (person && canViewPersonnelDetails(person)) {
          window.open(`/personnel/${person.id}`, '_blank');
        } else {
          displayToast('You do not have permission to view this personnel record', 'error');
        }
      } else if (args.commandColumn.title === 'View Project Details Page') {
        window.open(`/projects/${row.project.id}`, '_blank');
      } else if (args.commandColumn.title === 'Transfer Personnel') {
        setTransferPersonnelRole(row.role);
        setIsTransferPersonnelOpen(true);
      }
    }
  };
  const customFilter = (props: ProjectsGridRowData) => {
    return props.role?.person ? props.role?.person?.name?.lastCommaFirst : 'Unfilled';
  };
  const columnFilterSettings: Object = {
    type: 'CheckBox',
    itemTemplate: customFilter,
  };

  const tooltip = (args: any) => {
    const nextAssignmentExists =
      args?.data?.role?.nextAssignment !== null && args?.data?.role?.nextAssignment?.project.name;
    const nextAssignmentColumn = args?.column.field === 'role.nextAssignment.project.name';

    if (nextAssignmentColumn && nextAssignmentExists) {
      const nextAssignmentRoleName = args?.data?.role?.nextAssignment?.roleName;
      const nextAssignmentStartDate = args?.data?.role?.nextAssignment?.startDate;
      const nextAssignmentEndDate = args?.data?.role?.nextAssignment?.endDate;

      const tooltipContent = `<div>Role: ${nextAssignmentRoleName}</div> 
          <div>Start Date: ${nextAssignmentStartDate}</div>
         <div>End Date: ${nextAssignmentEndDate}</div>`;

      const tooltip: Tooltip = new Tooltip({
        content: tooltipContent,
      });
      tooltip.appendTo(args.cell);
    }
  };

  const beginEdit = (args: any) => {
    const tooltipInstance = args.row.querySelector('.e-tooltip')?.ej2_instances[0];
    if (tooltipInstance) {
      tooltipInstance.close();
    }
  };

  const columnDirectivePropsList: GridColumnModel[] = [
    {
      field: 'role.id',
      type: 'string',
      headerText: 'Project Role ID',
      allowEditing: false,
      visible: false,
      isPrimaryKey: true,
    },
    {
      field: 'project.id',
      type: 'string',
      headerText: 'Project ID',
      allowEditing: false,
      visible: false,
      isPrimaryKey: true,
    },
    {
      field: 'project.number',
      type: 'string',
      headerText: 'Job #',
      headerTemplate: (props: { headerText: string }) => (
        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
      ),
      allowEditing: false,
      width: 100,
    },
    {
      headerText: 'Action Menu',
      width: 150,
      headerTextAlign: 'Left',
      commands: ActionMenu,
      allowFiltering: false,
      allowEditing: false,
      allowSorting: false,
      allowSearching: false,
    },
    {
      field: 'project.name',
      type: 'string',
      headerText: 'Job Name',
      headerTemplate: (props: { headerText: string }) => (
        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
      ),
      allowEditing: false,
      width: 250,
    },
    {
      field: 'project.status',
      type: 'string',
      headerText: 'Status',
      allowEditing: false,
      visible: false,
    },
    {
      field: 'role.roleFullDisplayName',
      type: 'string',
      headerText: 'Role',
      allowEditing: true,
      editType: 'dropdownedit',
      edit: dropdownListTemplate(
        ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE(selectedEmployeeType === 'Craft').map((role) => ({
          option: role,
          label: role,
        })),
        'role...',
      ),
      width: 100,
    },
    {
      field: 'role.person.name.lastCommaFirst',
      type: 'string',
      headerText: 'Personnel',
      visible: false,
      showInColumnChooser: false,
    },
    {
      field: 'role.person.id',
      type: 'string',
      headerText: 'Personnel',
      headerTemplate: (props: { headerText: string }) => (
        <HelpTooltip tooltipText={EMPLOYEE_MASTER_SOURCE} titleText={props.headerText} />
      ),
      allowEditing: true,
      editType: 'dropdownedit',
      edit: dropdownListTemplate(personnelListOptions, 'personnel...'),
      template: (row: ProjectsGridRowData) => {
        const person = getPersonnelByIdInRow(row);
        return person?.name?.lastCommaFirst ? (
          <>
            {person?.notesFlag && <Flag sx={cellIconStyle} />}
            {person?.name?.lastCommaFirst}
          </>
        ) : (
          'Unfilled'
        );
      },
      width: 200,
      filter: columnFilterSettings,
    },
    {
      field: 'role.person.prJobTitleFullDisplayName',
      type: 'string',
      headerText: 'Payroll Title',
      headerTemplate: (props: { headerText: string }) => (
        <HelpTooltip tooltipText={EMPLOYEE_MASTER_SOURCE} titleText={props.headerText} />
      ),
      allowEditing: false,
      width: 100,
      visible: gridId !== PROJECT_GRID_UNFILLED_ROLES_ID,
    },
    {
      field: 'role.startJsDate',
      type: 'date',
      headerText: 'Role Start',
      headerTemplate: (props: { headerText: string }) => (
        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
      ),
      allowFiltering: false,
      editType: 'datepickeredit',
      format: DATE_FORMAT,
      allowEditing: true,
      width: 135,
    },
    {
      field: 'role.endJsDate',
      type: 'date',
      headerText: 'Role End',
      headerTemplate: (props: { headerText: string }) => (
        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
      ),
      allowFiltering: false,
      editType: 'datepickeredit',
      format: DATE_FORMAT,
      allowEditing: true,
      width: 135,
    },
    {
      field: 'role.nextAssignment.project.name',
      type: 'string',
      headerText: 'Next Assignment',
      allowEditing: false,
      width: 200,
    },
    {
      field: 'role.focusArea',
      type: 'string',
      headerText: 'Focus Area',
      headerTemplate: (props: { headerText: string }) => (
        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
      ),
      allowEditing: true,
      width: 100,
    },
    {
      field: 'role.notesFlag',
      type: 'boolean',
      headerText: 'Notes Flag',
      headerTemplate: (props: { headerText: string }) => (
        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
      ),
      template: (row: ProjectsGridRowData) => <>{row.role?.notesFlag && <Flag sx={cellIconStyle} />}</>,
      allowEditing: true,
      editType: 'booleanedit',
      width: 125,
    },
    {
      field: 'role.notes',
      type: 'string',
      headerText: 'Notes',
      headerTemplate: (props: { headerText: string }) => (
        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
      ),
      allowEditing: true,
      width: 100,
    },
    {
      field: 'role.tdy',
      type: 'string',
      headerText: 'TDY',
      allowEditing: false,
      width: 100,
    },
    {
      field: 'role.person.possiblePromotionTitle',
      type: 'string',
      headerText: 'Possible Promotion Title',
      headerTemplate: (props: { headerText: string }) => (
        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
      ),
      allowEditing: false,
      width: 200,
    },
    {
      field: 'role.person.possiblePromotionDate',
      type: 'string',
      headerText: 'Possible Promotion Date',
      headerTemplate: (props: { headerText: string }) => (
        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
      ),
      allowEditing: false,
      width: 200,
    },
    {
      field: 'role.person.anticipatedSeparationDate',
      type: 'string',
      headerText: 'Anticipated Separation Date',
      headerTemplate: (props: { headerText: string }) => (
        <HelpTooltip tooltipText={USER_INPUT_SOURCE} titleText={props.headerText} />
      ),
      allowEditing: false,
      width: 200,
    },
  ];

  const enabledColumnDirectivePropsList = columnDirectivePropsList.filter(
    (props) => !disabledFields.some((disabledField) => disabledField === props.field),
  );

  // foreignKeyValue appears to be used as the column's name in the column picker.
  columnDirectivePropsList.forEach((column: GridColumnModel) => {
    if (typeof column.headerText === 'string') {
      column.foreignKeyValue = column.headerText;
    }
  });

  const rowSelected: GridModel['rowSelected'] = (args: any) => {
    setSelectedProject({
      id: args?.data?.project?.id,
      name: args?.data?.project?.name,
    });
    setSelectedRowData(args?.data);
    const gridInstance = getGridInstance();
    if (gridInstance) {
      // Disable delete role button if project has no roles to delete
      args.data.role
        ? gridInstance.toolbarModule.enableItems([`${gridId}_DeleteRole`], true)
        : gridInstance.toolbarModule.enableItems([`${gridId}_DeleteRole`], false);
    }
  };

  // TODO: disable/enable the buttons depending on the presence of groups.
  const toolbarClick: Exclude<GridModel['toolbarClick'], undefined> = (args) => {
    const gridInstance = getGridInstance();
    if (gridInstance && args.item.id.startsWith(gridId)) {
      // Trim away the grid ID and '_'
      const action = args.item.id.substring(gridId.length + 1);
      if (action === ToolbarItemId.ACTION_CLEAR_ALL_FILTERS) {
        gridInstance.clearFiltering();
      } else if (action === ToolbarItemId.ADD_PROJECT) {
        setIsAddProjectDialogOpen(true);
      } else if (action === ToolbarItemId.ADD_PROJECT_ROLE) {
        setIsAddProjectRoleDialogOpen(true);
      } else if (action === 'excelexport') {
        const ps: ExcelExportProperties = {};
        const visibleColumns = gridInstance
          .getVisibleColumns()
          .filter((column) => column.foreignKeyValue !== ACTION_MENU && column.foreignKeyField !== 'role.person.id');

        if (gridInstance.getVisibleColumns().find((column) => column.foreignKeyField === 'role.person.id')) {
          const indexOfPerson = gridInstance.getColumnIndexByField('role.person.id');
          const personnelColumn = gridInstance.getColumnByField('role.person.name.lastCommaFirst');
          visibleColumns.splice(indexOfPerson, 0, personnelColumn);
        }
        const exportColumns = createExportColumns(visibleColumns);
        ps.columns = exportColumns;
        gridInstance.excelExport(ps);
      } else if (action === ToolbarItemId.ACTION_RESET_VIEW) {
        // TODO: we probably need to do this whenever the app updates,
        // since changes to the column definitions seem to scramble the view.
        gridInstance.enablePersistence = false;
        window.localStorage.setItem(`grid${gridId}`, '');
        gridInstance.destroy();
        window.location.reload();
      } else if (action === ToolbarItemId.DELETE_ROLE.replace(/\s/g, '')) {
        setDialogAction(ToolbarItemId.DELETE_ROLE);
        setIsDeleteProjectAndRoleDialogOpen(true);
      }
    }
  };

  const load: GridModel['load'] = () => {
    const gridInstance = getGridInstance();
    if (gridInstance) {
      // Enable single-click editing
      // TODO: this is not picking the right cell, just the row.
      // TODO: see documentation for additional tips on getting dropdowns to open on single click:
      // https://ej2.syncfusion.com/react/documentation/grid/how-to/enable-editing-in-single-click#open-dropdown-edit-popup-on-single-click
      // gridInstance.element.addEventListener('mouseup', function (e) {
      //   if ((e.target as any)?.classList?.contains('e-rowcell')) {
      //     if (gridInstance.isEdit) gridInstance.endEdit();
      //     let index = parseInt((e.target as any).getAttribute('Index'));
      //     gridInstance.selectRow(index);
      //     gridInstance.startEdit();
      //   }
      // });
    }
  };

  const getRoleUpsertInput = (role: ProjectRoleFromProjectListResult): ProjectRoleUpsertInput => ({
    id: role.id,
    roleName: ROLES_BY_FULL_DISPLAY_NAME[role.roleFullDisplayName ?? ''].roleName ?? role.roleName,
    startDate: role.startJsDate ? DateTime.fromJSDate(role.startJsDate).toFormat(GRAPHQL_DATE_FORMAT) : role.startDate,
    endDate: role.endJsDate ? DateTime.fromJSDate(role.endJsDate).toFormat(GRAPHQL_DATE_FORMAT) : role.endDate,
    // Syncfusion populates a skeletal person object if person is null and you open/close the dropdown with no selection
    // so we collapse to undefined if a value is not found
    personId: role.person?.id === '' ? null : role.person?.id ?? undefined,
    focusArea: role.focusArea === undefined ? null : role.focusArea === '' ? null : role.focusArea,
    notes: role.notes === undefined ? null : role.notes === '' ? null : role.notes,
    notesFlag: role.notesFlag,
  });

  const findPersonIDFromKey = (keys: string[]) => {
    keys.forEach((key) =>
      personnelList.forEach((person) => {
        if (person.name?.lastCommaFirst?.includes(key)) {
          keys.push(person.id);
        }
      }),
    );
  };

  const findPersonRoleData = (person: Person) => {
    return dataSource
      .filter((row) => row.role?.person?.id === person?.id)
      .sort((a, b) => a.role?.startJsDate.valueOf() - b.role?.startJsDate.valueOf());
  };

  const updatePersonNextAssignment = (projectsGridRowData: ProjectsGridRowData[]) => {
    if (dataSource && projectsGridRowData) {
      if (projectsGridRowData.length === 1) {
        const firstRoleIndex = 0;
        const foundRole = dataSource?.find((row) => row.role?.id === projectsGridRowData[firstRoleIndex].role?.id)
          ?.role;
        if (foundRole && foundRole.nextAssignment) {
          foundRole.nextAssignment = null;
        }
      }

      if (projectsGridRowData.length > 1) {
        projectsGridRowData.forEach((rowData, index) => {
          const nextRoleIndex = index + 1;
          // if there's a role, and the next role is filled, and it's not the last role in the array
          if (
            rowData.role &&
            rowData.role.person &&
            index < projectsGridRowData.length - 1 &&
            projectsGridRowData[nextRoleIndex].role
          ) {
            const foundRole = dataSource?.find((row) => row.role?.id === rowData.role?.id)?.role;
            const foundPerson = personnelList.find((person) => person.id === rowData.role?.person?.id);

            if (
              foundRole &&
              projectsGridRowData &&
              projectsGridRowData.length > 1 &&
              projectsGridRowData[nextRoleIndex].role
            ) {
              if (projectsGridRowData[nextRoleIndex].role?.person) {
                foundRole.nextAssignment = {
                  id: projectsGridRowData[nextRoleIndex].role?.id ?? '',
                  startDate: projectsGridRowData[nextRoleIndex].role?.startDate,
                  endDate: projectsGridRowData[nextRoleIndex].role?.endDate,
                  roleName: projectsGridRowData[nextRoleIndex].role?.roleName ?? '',
                  project: {
                    id: projectsGridRowData[nextRoleIndex].project.id,
                    name: projectsGridRowData[nextRoleIndex].project.name,
                  },
                };
              } else {
                foundRole.nextAssignment = null;
              }
            }
            rowData.role.person = foundPerson;
          }
        });
      }
    }
  };

  const actionBegin: GridModel['actionBegin'] = (args: any) => {
    if (args.requestType === 'searching' && args.searchString) {
      const keys = args.searchString.split(' ');
      findPersonIDFromKey(keys);
      let flag = true;
      let predicate: Predicate | null | undefined = null;
      const gridInstance = getGridInstance();
      if (gridInstance && keys.length > 1) {
        if (gridInstance?.searchSettings.key !== '') {
          values = args.searchString;
          keys.forEach((key: Predicate) => {
            gridInstance?.getColumns().forEach((col) => {
              if (flag) {
                predicate = new Predicate(col.field, 'contains', key, true);
                flag = false;
              } else {
                var predic = new Predicate(col.field, 'contains', key, true);
                predicate = predicate?.or(predic);
              }
            });
          });
          if (predicate) {
            gridInstance.query = new Query().where(predicate);
            gridInstance.searchSettings.key = '';
            valueAssign = true;
            removeQuery = true;
            gridInstance.refresh();
          }
        }
      }
    }
    if (args.requestType === 'filterSearchBegin' && args.columnName === 'role.person.id' && args.value) {
      const personNameColumn = 'role.person.name.lastCommaFirst';
      const personIdColumn = 'role.person.id';

      let data =
        args.filterModel.options.dataSource instanceof DataManager
          ? args.filterModel.options.dataSource
          : new DataManager(args.filterModel.options.dataSource);

      let predicate = new Predicate(personNameColumn, 'contains', args.value, true);

      if (!args.filterModel.options.isRemote) {
        const firstSearchResultIndex = 0;
        const personNameSearchResult = data.executeLocal(new Query().where(predicate));

        if (personNameSearchResult.length === 1) {
          args.value = personNameSearchResult[firstSearchResultIndex].role?.person?.id;
        } else if (personNameSearchResult.length > 1) {
          personNameSearchResult.forEach((data: ProjectsGridRowData, index: number) => {
            const personId = data.role?.person?.id as string;
            if (index === 0) {
              predicate = new Predicate(personIdColumn, 'contains', personId, true);
            } else {
              predicate = predicate.or(personIdColumn, 'contains', personId, true);
            }
          });
          args.query = args.query.where(predicate);
          args.value = '';
        }
      }
    }
  };

  L10n.load({
    'en-US': {
      grid: {
        Blanks: 'Unfilled',
      },
    },
  });

  const actionComplete: GridModel['actionComplete'] = (...args: any) => {
    const gridInstance = getGridInstance();
    args.forEach((actionPayload: any) => {
      if (actionPayload.action === 'edit' && actionPayload.requestType === 'save') {
        const previousRoleData = actionPayload.rowData.role;
        const updatedRoleData = actionPayload.data.role;

        /* When editing a TDY personnel not associated with selectedDistricts, their lastCommaFirst will change to their ID 
        even if person is unchanged. Need to check if lastCommaFirst changed and they are TDY */
        const unchangedPersonnel =
          previousRoleData.person?.name?.lastCommaFirst === updatedRoleData.person?.name?.lastCommaFirst;
        const tdyPersonnel = previousRoleData.tdy !== null;
        const tdyPersonnelNotInRegion = !unchangedPersonnel && tdyPersonnel;

        // Hacky sanitization of the dual-purpose person name field for editing
        if (unchangedPersonnel && tdyPersonnelNotInRegion) {
          // Person is unchanged, so copy the existing ID
          if (updatedRoleData.person?.name) {
            updatedRoleData.person.name.lastCommaFirst = previousRoleData.person.id;
          }
        }
        if (previousRoleData.person?.name) {
          previousRoleData.person.name.lastCommaFirst = previousRoleData.person.id;
        }

        const previousRoleUpsertInput = getRoleUpsertInput(previousRoleData);
        const updatedRoleUpsertInput = getRoleUpsertInput(updatedRoleData);
        if (!isDeepEqual(previousRoleUpsertInput, updatedRoleUpsertInput)) {
          // TODO: updating the assigned person only updates the ID in the row.
          // The field templates work around this, however sort/filter don't.
          // Consider whether we can/should preemptively replace the entire person object in the data source.
          upsertProjectRole({ variables: { input: updatedRoleUpsertInput } })
            .then(() => {
              displayToast('The role was updated successfully', 'success');
              const foundPerson = personnelList.find((person) => person.id === actionPayload.data.role.person?.id);
              const previousPersonOnRole = previousRoleData.person;

              const projectRegionsNotMatchingPersonPrDistrict = actionPayload.data.project?.districts?.filter(
                (district: string) => district !== foundPerson?.prDistrict,
              );

              const tdyToDisplay =
                projectRegionsNotMatchingPersonPrDistrict && projectRegionsNotMatchingPersonPrDistrict.length >= 1
                  ? `${foundPerson?.prDistrict} => ${projectRegionsNotMatchingPersonPrDistrict?.join(', ')}`
                  : null;

              if (foundPerson) {
                actionPayload.data.role.person = foundPerson;
                actionPayload.data.role.tdy = tdyToDisplay;
              } else {
                actionPayload.data.role.person = null;
                actionPayload.data.role.nextAssignment = null;
                actionPayload.data.role.tdy = null;
              }
              const previousPersonRoleData = findPersonRoleData(previousPersonOnRole);
              const newPersonRoleData = findPersonRoleData(foundPerson as Person);

              updatePersonNextAssignment(previousPersonRoleData);
              updatePersonNextAssignment(newPersonRoleData);

              if (updatedRoleData.nextAssignment !== null && updatedRoleData.nextAssignment.project.name) {
                updatePersonnelsNextAssignmentStartDate(updatedRoleData, previousRoleData);
              }
              gridInstance.refresh();
            })
            .catch(() => {
              displayToast(
                'Error: Something went wrong while trying to update a role. Please try again. If the problem persists, please contact support.',
                'error',
              );
            });
        }
      } else if (actionPayload.requestType === 'refresh' && valueAssign && gridInstance) {
        (document.getElementById(gridInstance.element.id + '_searchbar') as HTMLInputElement).value = values;
        valueAssign = false;
      } else if (
        gridInstance &&
        (document.getElementById(gridInstance.element.id + '_searchbar') as HTMLInputElement).value === '' &&
        actionPayload.requestType === 'refresh' &&
        removeQuery &&
        gridInstance
      ) {
        gridInstance.query = new Query();
        removeQuery = false;
        gridInstance.refresh();
      }
    });
  };

  const onClearAllDataFilters = () => {
    setDataFilterRole(null);
    setDataFilterStartDate(null);
    setDataFilterEndDate(null);
    setDataFilterProject([]);
    setDataFilterShouldShowEndedRoles(null);
    setDataFilterShouldShowTargetProjects(null);
    setDataFilterShouldShowArchivedProjects(null);
  };

  const getEmployeeType = (row: ProjectsGridRowData) => {
    const isEmployeeCraftOrSalary = ROLES.some(
      (role) => role.fullDisplayName === row.role?.roleFullDisplayName && role.type === 'CRAFT',
    )
      ? 'Craft'
      : 'Salary';
    return isEmployeeCraftOrSalary;
  };

  const filteredDataSource = dataSource
    .filter(
      (row) =>
        (dataFilterRole === null || dataFilterRole === row.role?.roleFullDisplayName) &&
        rowSpansRange(row, dataFilterStartDate, dataFilterEndDate) &&
        dataFilterProject &&
        dataFilterProject.length > 0 &&
        dataFilterProject.find((filteredProject: string) => {
          if (filteredProject === ANY_PROJECT) return true;
          return filteredProject === row.project.id;
        }) &&
        showEndedRoles(row, shouldShowEndedRoles) &&
        showTargetProjects(row, shouldShowTargetProjects) &&
        showArchivedProjects(row, shouldShowArchivedProjects) &&
        selectedEmployeeType === getEmployeeType(row),
    )
    .sort((a, b) => {
      const roleIndexA = ROLES.findIndex((role) => role.fullDisplayName === a.role?.roleFullDisplayName);
      const roleIndexB = ROLES.findIndex((role) => role.fullDisplayName === b.role?.roleFullDisplayName);

      return roleIndexA - roleIndexB;
    });

  const captionTemplate = (args: any) => {
    const firstIndexOfItems = 0;
    const row = args.items[firstIndexOfItems];
    const person = getPersonnelByIdInRow(row);
    if (args.field === 'role.person.id') {
      const personName = person ? person.name?.lastCommaFirst : 'Unfilled';
      return (
        <div>
          {args.headerText}: {personName}
        </div>
      );
    }
  };

  // Set Syncfusion DataUtil to use UTC time.
  // DataUtil.serverTimezoneOffset = 0;
  // const timezoneCorrectedDataSource = DataUtil.parse.parseJson?.(JSON.stringify(dataSource)) ?? dataSource;

  // TODO: Default keyboard support is clunky for editing. Consider overriding
  // "enter" and/or "space" to start editing the currently selected cell?
  // https://ej2.syncfusion.com/react/documentation/grid/how-to/perform-grid-actions-by-keyboard-short-cut-keys
  return (
    <Fragment>
      <Stack>
        <Accordion sx={accordionRootStyle}>
          <Stack direction="column" sx={accordionSummaryStackStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={expandIconStyles} />} sx={accordionSummaryStyle}>
              <Typography sx={searchFilterTextStyle}>Search Filter ({activeFiltersCount})</Typography>
            </AccordionSummary>
          </Stack>
          <AccordionDetails sx={accordionDetailsStyle}>
            <Grid container>
              <Stack direction="row" spacing={7}>
                <Stack direction="row" alignItems="center" spacing={1} sx={nestedStackStyle}>
                  <Typography>Show</Typography>
                  <Autocomplete<string>
                    id="RoleFilter"
                    size="small"
                    options={[ANY_ROLE, ...ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE(selectedEmployeeType === 'Craft')]}
                    value={dataFilterRole ?? ANY_ROLE}
                    onChange={(_, value) => setDataFilterRole(value === ANY_ROLE ? null : value)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={roleSelectStyle}
                    PopperComponent={styled(Popper)(({ theme }) => ({
                      [`& .${autocompleteClasses.paper}`]: {
                        width: '400px',
                        // Shadow 8 is the same one that the StyledDatePicker ends up using.
                        boxShadow: theme.shadows[8],
                      },
                    }))}
                  />
                  <Typography>roles overlapping</Typography>
                  <StyledDatePicker
                    value={dataFilterStartDate ? convertUTCDateToLocalDate(dataFilterStartDate) : null}
                    onChange={setDataFilterStartDate}
                    maxDate={dataFilterEndDate || undefined}
                    sx={dateSelectStyle}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                  <Typography>through</Typography>
                  <Stack direction="column" sx={nestedStackRootStyle}>
                    <StyledDatePicker
                      value={dataFilterEndDate ? convertUTCDateToLocalDate(dataFilterEndDate) : null}
                      onChange={setDataFilterEndDate}
                      minDate={
                        dataFilterStartDate && !shouldShowEndedRoles
                          ? DateTime.max(dataFilterStartDate, DateTime.now())
                          : dataFilterStartDate
                          ? dataFilterStartDate
                          : !shouldShowEndedRoles
                          ? DateTime.now()
                          : undefined
                      }
                      sx={dateSelectStyle}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={shouldShowEndedRoles}
                          onChange={(e) => {
                            setDataFilterShouldShowEndedRoles(e.target.checked);
                          }}
                          size="small"
                        />
                      }
                      label={<Typography sx={checkboxLabelStyles}>Show Ended Roles</Typography>}
                      labelPlacement="end"
                    />
                  </Stack>
                  <Typography>on</Typography>
                  <Stack direction="column" sx={nestedStackRootStyle}>
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      id="ProjectFilter"
                      limitTags={2}
                      size="small"
                      options={[ANY_PROJECT, ...projectList.map((project) => project.id)]}
                      getOptionDisabled={(projectId) =>
                        (!shouldShowTargetProjects && allProjectsById[projectId]?.status === 'Target') ||
                        (!shouldShowArchivedProjects && allProjectsById[projectId]?.status === 'Archived')
                      }
                      value={dataFilterProject && dataFilterProject.length > 0 ? dataFilterProject : []}
                      onChange={(_, value) => {
                        if (value[0] === ANY_PROJECT && value.length > 1) {
                          const newValue = value.filter((projectId) => projectId !== ANY_PROJECT);
                          setDataFilterProject(newValue);
                        } else if (value.find((projectId) => projectId === ANY_PROJECT)) {
                          setDataFilterProject([ANY_PROJECT]);
                        } else {
                          setDataFilterProject(value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={
                            dataFilterProject && dataFilterProject.length > 0 ? '' : 'Select Projects to Display'
                          }
                        />
                      )}
                      renderTags={(values, getTagProps, ownerState) =>
                        values
                          .map((value, index) =>
                            value === ANY_PROJECT || value in allProjectsById ? (
                              <Chip {...getTagProps({ index })} label={ownerState.getOptionLabel(value)} size="small" />
                            ) : (
                              false
                            ),
                          )
                          .filter((value) => !!value)
                      }
                      getOptionLabel={(projectId) => {
                        if (projectId === ANY_PROJECT) {
                          return ANY_PROJECT;
                        }
                        const project = allProjectsById[projectId];
                        if (!project) {
                          return 'project not found';
                        }
                        return project.number ? `${project.name} - ${project.number}` : `${project.name}`;
                      }}
                      sx={projectSelectStyle}
                      PopperComponent={styled(Popper)(({ theme }) => ({
                        [`& .${autocompleteClasses.paper}`]: {
                          width: '400px',
                          boxShadow: theme.shadows[8],
                        },
                      }))}
                    />
                    <Stack direction="row">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={shouldShowTargetProjects}
                            onChange={(e) => {
                              setDataFilterShouldShowTargetProjects(e.target.checked);
                            }}
                            size="small"
                          />
                        }
                        label={<Typography sx={checkboxLabelStyles}>Show Target Projects</Typography>}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={shouldShowArchivedProjects}
                            onChange={(e) => {
                              setDataFilterShouldShowArchivedProjects(e.target.checked);
                            }}
                            size="small"
                          />
                        }
                        label={<Typography sx={checkboxLabelStyles}>Show Archived Projects</Typography>}
                        labelPlacement="end"
                      />
                    </Stack>
                  </Stack>
                  <Button onClick={onClearAllDataFilters} variant="outlined" sx={clearFiltersButtonStyle}>
                    Clear Filters
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Box sx={gridContainerStyle}>
          {useMemo(
            () => (
              <>
                {!isAuthenticated && <LoadingSpinner />}
                {isAuthenticated && (
                  <>
                    <GridComponent
                      id={gridId}
                      ref={(grid) => {
                        if (grid) {
                          gridInstancesById[gridId] = grid;
                        }
                      }}
                      emptyRecordTemplate={
                        (dataFilterProject && dataFilterProject.length === 0) || dataFilterProject === null
                          ? "Please choose at least one project from the Search Filter dropdown above. You may choose a minimal selection of projects for faster loading or select 'All Projects' to display all projects."
                          : 'No records to display'
                      }
                      dataSource={filteredDataSource}
                      queryCellInfo={tooltip}
                      beginEdit={beginEdit}
                      allowExcelExport={true}
                      //dataBound={dataBound}
                      // TODO: looks like there's a bug when trying to use Shimmer. Consider reporting it.
                      // loadingIndicator={{ indicatorType: 'Shimmer' }}
                      searchSettings={searchSettings}
                      toolbar={toolbarItems}
                      toolbarClick={toolbarClick}
                      allowGrouping={false}
                      groupSettings={{ captionTemplate: captionTemplate }}
                      allowResizing={true}
                      showColumnChooser={true}
                      showColumnMenu={true}
                      contextMenuItems={['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending']}
                      allowSorting={true}
                      allowFiltering={true}
                      allowSelection={true}
                      filterSettings={{ type: 'Excel' }}
                      enableVirtualMaskRow={false}
                      // TODO: get editing actually working
                      editSettings={{
                        allowEditing: true,
                        allowDeleting: true,
                        mode: 'Normal',
                      }}
                      load={load}
                      commandClick={commandClick}
                      // TODO: Decide which is more important: virtualization or fill handle.
                      // Fill handle requires cell selection, which is incompatible with virtualization.
                      // Pagination may be the only alternative to virtualization,
                      // and that would likely present challenges when it comes to group/filter/search.
                      // Note that if we chose not to use virtualization, other options would become available.
                      // TODO: find out what kind of incompatibility we're talking about. It seems to kind of work,
                      // so is it that there are edge cases/bugs? I only found one reference in the docs, so is it
                      // fixed, but not reflected in all of the docs?
                      // enableAutoFill={true}
                      // selectionSettings={{ cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell' }}
                      // editSettings={{ allowEditing: true, mode: 'Batch' }}
                      // enableVirtualization={true}
                      // TODO: decide whether column virtualization is worth trying.
                      // It does seem like it may improve things if you don't have good column hygeine,
                      // but it seems more buggy, in particular with grouping/aggregates.
                      // enableColumnVirtualization={true}
                      enablePersistence={true}
                      // TODO: If we figure out a way to make this dynamic, go clean up the other
                      // hard coded heights in the gantt tab
                      height={`calc(100vh - ${
                        TOTAL_HEIGHT_OVERHEAD_FROM_APP_WRAPPING_PX +
                        TAB_PANEL_HEIGHT_PX +
                        FILTER_HEIGHT_PX +
                        // This is a bit of a guess as to what really counts against the height. Syncfusion seems a bit inconsistent here.
                        SYNCFUSION_TOOLBAR_HEIGHT_PX +
                        ROW_HEIGHT_PX
                      }px)`}
                      width={`calc(100vw - ${ROOT_CONTAINER_PADDING_PX * 2}px)`}
                      enableInfiniteScrolling={true}
                      infiniteScrollSettings={{ initialBlocks: 5 }}
                      rowSelected={rowSelected}
                      rowDataBound={rowDataBound}
                      // TODO: Using a calculated height seems to throw off the auto page size.
                      // See if we can calculate this fully in code to get a simple ###px value that is dynamic.
                      actionBegin={actionBegin}
                      actionComplete={actionComplete}
                    >
                      <ColumnsDirective>
                        {enabledColumnDirectivePropsList.map((columnDirectiveProps, index) => {
                          return <ColumnDirective key={index} {...columnDirectiveProps} />;
                        })}
                      </ColumnsDirective>
                      <Inject
                        services={[
                          Toolbar,
                          Resize,
                          ColumnChooser,
                          ColumnMenu,
                          ContextMenu,
                          ExcelExport,
                          Sort,
                          Filter,
                          Search,
                          InfiniteScroll,
                          Edit,
                          CommandColumn,
                        ]}
                      />
                    </GridComponent>
                  </>
                )}
              </>
            ),
            [filteredDataSource, personnelList, isAuthenticated],
          )}
        </Box>
      </Stack>
      <AddProjectDialog
        isOpen={isAddProjectDialogOpen}
        setIsOpen={setIsAddProjectDialogOpen}
        selectedEmployeeType={selectedEmployeeType}
        personList={personnelList as Person[]}
        onDataChanged={onDataChanged}
      />
      <AddProjectRoleDialog
        projectSelection={selectedProject}
        isOpen={isAddProjectRoleDialogOpen}
        setIsOpen={setIsAddProjectRoleDialogOpen}
        projectListResult={projectList as Project[]}
        personnelList={personnelList as Person[]}
        selectedEmployeeType={selectedEmployeeType}
        onDataChanged={onDataChanged}
      />
      <DeleteRoleDialog
        isOpen={isDeleteProjectAndRoleDialogOpen}
        setIsOpen={setIsDeleteProjectAndRoleDialogOpen}
        action={dialogAction}
        rowData={selectedRowData}
      />
      <ReassignRoleDialog
        isOpen={isReassignRoleDialogOpen}
        setIsOpen={setIsReassignRoleDialogOpen}
        reassignProjectAndRole={reassignProjectAndRole}
        setReassignProjectAndRole={setReassignProjectAndRole}
        startingReassignmentProjectAndRole={startingReassignmentProjectAndRole}
        dataSource={dataSource.filter((row) => selectedEmployeeType === getEmployeeType(row))}
        onDataChanged={onDataChanged}
        startingStepIndex={0}
        selectedEmployeeType={selectedEmployeeType}
        selectedDistricts={selectedDistricts}
      />
      <TransferPersonnelDialog
        isTransferPersonnelOpen={isTransferPersonnelOpen}
        setIsTransferPersonnelOpen={setIsTransferPersonnelOpen}
        projectListResult={projectList as Project[]}
        transferPersonnelRole={transferPersonnelRole as ProjectRole}
        selectedEmployeeType={selectedEmployeeType}
      />
    </Fragment>
  );
};

export default ProjectsGrid;
