import { ProjectsGridRowData } from 'components/ProjectsGrid/ProjectsGrid';
import ReassignRoleDialog from 'components/ReassignRole/ReassignRoleDialog';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import { ACTIVE_STATUS, ANTICIPATED_STATUS } from 'constants/projectStatus';
import { useAdAvatar } from 'hooks/useAdAvatar';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedDistrictsState, selectedEmployeeTypeState } from 'recoil/selectedDistrict/atom';
import { GetSinglePersonQuery, Person, useProjectListResultQuery } from 'types/generated/graphql';
import { Avatar, Grid, SxProps, Theme, Typography } from '@mui/material';

const avatarStyle: SxProps<Theme> = (theme: Theme) => ({
  position: 'relative',
  width: theme.spacing(17),
  height: theme.spacing(17),
  [theme.breakpoints.down('xs')]: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
});

const displayFlexStyles: SxProps<Theme> = {
  display: 'flex',
};

const headerNameStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'flex-end',
};

const headerTitlesAndButtonStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'flex-start',
};

const justifyContentCenterStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
};

const personnelNameHeadingTextStyle: SxProps<Theme> = {
  fontSize: '2.0rem',
};

const personnelTitleHeadingTextStyle: SxProps<Theme> = {
  fontSize: '1.25rem',
};

const styledButtonContainerStyles: SxProps<Theme> = {
  marginRight: '10px',
};

type LeftSectionHeaderProps = {
  name: string;
  title: string;
  setIsEditing: (x: boolean) => void;
  personnel?: GetSinglePersonQuery['person'];
  isOnlyCraft: boolean;
};

export const LeftSectionHeader: FC<LeftSectionHeaderProps> = ({
  name,
  title,
  setIsEditing,
  personnel,
  isOnlyCraft,
}) => {
  const [isReassignRoleDialogOpen, setIsReassignRoleDialogOpen] = useState(false);
  const [reassignProjectAndRole, setReassignProjectAndRole] = useState<ProjectsGridRowData>();
  const [startingReassignmentProjectAndRole, setStartingReassignmentProjectAndRole] = useState<ProjectsGridRowData>();
  const { url, initials } = useAdAvatar(personnel?.employeeNumber);
  const selectedDistricts = useRecoilValue(selectedDistrictsState);
  const employeeTypeDropdownValue = useRecoilValue(selectedEmployeeTypeState);
  const selectedEmployeeType = isOnlyCraft ? 'Craft' : employeeTypeDropdownValue;

  const projectListResultQueryVariables = {
    districts: selectedDistricts,
    statuses: [...ACTIVE_STATUS, ...ANTICIPATED_STATUS],
  };

  const { data: projectListData } = useProjectListResultQuery({
    variables: projectListResultQueryVariables,
    fetchPolicy: 'no-cache',
  });
  const projectList = projectListData?.projectListResult?.items ?? [];

  const dataSource: ProjectsGridRowData[] = projectList.flatMap((project) => {
    if (!project.roles.length) {
      return [{ project }];
    }
    return project.roles.map((role) => ({
      project,
      role,
    }));
  });

  return (
    <>
      <Grid item xs={12} sx={justifyContentCenterStyles}>
        <Grid container columnSpacing={1}>
          <Grid item xs={4} sx={displayFlexStyles}>
            <Avatar alt={'Personnel Profile Image'} sx={avatarStyle} src={url}>
              {initials}
            </Avatar>
          </Grid>
          <Grid item xs={8} sx={displayFlexStyles}>
            <Grid container>
              <Grid item xs={12} sx={headerNameStyles}>
                <Typography color="textPrimary" sx={personnelNameHeadingTextStyle}>
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={headerTitlesAndButtonStyles}>
                <Typography color="textPrimary" sx={personnelTitleHeadingTextStyle}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={headerTitlesAndButtonStyles}>
                <Grid sx={styledButtonContainerStyles}>
                  <StyledButtonSecondary
                    variant="outlined"
                    label={'Add Leave'}
                    onClick={() => {
                      const today = DateTime.now();
                      const personnelProjectGridRowData = dataSource.find(
                        (data) =>
                          data.role?.person?.id === personnel?.id &&
                          DateTime.fromISO(data.role?.startDate) < today &&
                          DateTime.fromISO(data.role?.endDate) > today,
                      );
                      setIsReassignRoleDialogOpen(true);
                      setReassignProjectAndRole(personnelProjectGridRowData);
                      setStartingReassignmentProjectAndRole(
                        personnelProjectGridRowData
                          ? JSON.parse(JSON.stringify(personnelProjectGridRowData))
                          : personnelProjectGridRowData,
                      );
                    }}
                  ></StyledButtonSecondary>
                </Grid>
                <StyledButtonPrimary label={'Edit Personnel'} onClick={() => setIsEditing(true)}></StyledButtonPrimary>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ReassignRoleDialog
        isOpen={isReassignRoleDialogOpen}
        setIsOpen={setIsReassignRoleDialogOpen}
        reassignProjectAndRole={reassignProjectAndRole}
        setReassignProjectAndRole={setReassignProjectAndRole}
        startingReassignmentProjectAndRole={startingReassignmentProjectAndRole}
        dataSource={dataSource}
        startingStepIndex={5}
        startingPersonnel={personnel as Person}
        selectedEmployeeType={selectedEmployeeType}
      />
    </>
  );
};
