import { useTheme } from 'providers/AppThemeProvider/AppThemeProvider';
import { Stack, Switch, SxProps, Theme, Typography } from '@mui/material';

const labelStyles: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.text.primary,
});

const ThemeToggle = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  const label = isDarkMode ? 'Dark mode' : 'Light mode';
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography sx={labelStyles}>{label}</Typography>
      <Switch checked={isDarkMode} onChange={toggleTheme} id="theme" />
    </Stack>
  );
};

export default ThemeToggle;
